import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import MonetizationOnSharp from '@material-ui/icons/MonetizationOnSharp';
import PeopleIcon from '@material-ui/icons/People';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.scss';


const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    }
}));

const sideBarIcons = {
    dashboard: DashboardIcon,
    ideate: EmojiObjectsSharp,
    campaigns: BarChartIcon,
    mentors: PeopleIcon,
    sponsors: MonetizationOnSharp,
    collaborators: GroupWorkIcon,
    profile: AccountCircleIcon
}

const withoutNavbarRoutes = ["/signUp","/signIn","/reset"];
function Sidebar({ sidebarOptions, isDrawerOpen }) {
    const { pathname } = useLocation();
    const [id, setId] = useState(null)
    useEffect(() => {
        setId(sessionStorage.getItem('id'))
    }, [])

    const classes = useStyles();
    if (withoutNavbarRoutes.some((item) => pathname.includes(item))) return null;
    return (<>
        {/* {id != null &&
            <> */}
        <div className="sidebar">
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
                }}
                open={isDrawerOpen}
            >
                <List>

                    {
                        sidebarOptions.map(sideBarOption => {
                            const Icon = sideBarIcons[sideBarOption.id];
                            return (
                                <div className={`sidebar__${sideBarOption.id}`}>
                                    <Link to={sideBarOption.path}>
                                        <ListItem><Tooltip title={sideBarOption.title}>
                                            <ListItemIcon>
                                                <Icon />
                                            </ListItemIcon></Tooltip>
                                            <Typography>{sideBarOption.title}</Typography>
                                        </ListItem>
                                    </Link>
                                </div>
                            )
                        })
                    }

                </List>
            </Drawer>
        </div >
        {/* </>} */}
    </>)
}

export default Sidebar;
