import React from 'react';import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Button, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg';
import  {EditorState, convertFromRaw, convertToRaw}  from 'draft-js';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popbutton:{
    fontSize: '20px',
    color:'#06070E',
    marginLeft:'10px',
    marginRight:'10px',
    backgroundColor: 'white'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3),
  },
}));

export default function StageTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <div>
      <Button variant="contained" onClick={handleOpen} class={classes.popbutton} >Stages in Campaign</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        style={{position:'absolute',
        top:'10%',
        left:'10%',
        overflow:'scroll',
        height:'100%',
        display:'block'}}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <div className={classes.paper}>
          <Grid container spacing={1} style={{ padding: '10px' }}>
                    <Grid item xs={12} justify="center" style={{overflowX: 'auto'}}>
                        <Table >
                            <TableHead>
                                <TableRow style={{ fontSize: '150%' }}>
                                    <TableCell>Stage Name</TableCell>
                                    <TableCell>Stage Summary</TableCell>
                                    <TableCell>Stage Criteria</TableCell>
                                    <TableCell>Stage Milestone</TableCell>
                                    <TableCell>Stage Start Date</TableCell>
                                    <TableCell>Stage End Date</TableCell>
                                </TableRow>
                                {props.stages.map((stage,index) => (
                                <TableRow key={index}>
                                    <TableCell>{stage.currentStage}</TableCell>
                                    <TableCell>{stage.stageSummary}</TableCell>
                                    <TableCell><Editor
                                                name="Criteria of the Stage"
                                                toolbar={{
                                                  options: []
                                                }}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                readOnly={true}
                                                editorState={stage.stageCriteria.length > 0 ? EditorState.createWithContent(convertFromRaw(JSON.parse(stage.stageCriteria))) : EditorState.createEmpty() }
                                                placeholder=" Enter a brief criteria about this stage"
                                              /> </TableCell>
                                    <TableCell>{stage.stageMilestone}</TableCell>
                                    <TableCell>{moment(stage.stageStart).utc().format('MM/DD/YYYY').toString()}</TableCell>
                                    <TableCell>{moment(stage.stageEnd).utc().format('MM/DD/YYYY').toString()}</TableCell>
                                    
                                </TableRow>
                                ))}
                                </TableHead>
                                </Table>
                                </Grid>
                                </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}