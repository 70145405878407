import LocalStorageService from './localStorageService';
import { AUTHORIZATION_HEADER, LOCAL_STORAGE_KEY_TOKEN, AUGMENTED_TOKEN_FLAG } from './constants/GeneralConstants';

const LocalStorage = LocalStorageService.getService();

const handleSuccess = (response) => {
    if(response.headers.augmented_token_flag == "true" && 
        response.headers.update_token == "true" && response.headers.augmented_token){
        LocalStorage[LOCAL_STORAGE_KEY_TOKEN] = response.headers.augmented_token;
        LocalStorage[AUGMENTED_TOKEN_FLAG] = "true";
    }
    return response;
}

const preProcessReqError = (error) => {
    Promise.reject(error)
}
  
  
//axios.interceptors.request.use(request => requestHandler(request));

const preProcessRequest = config => {
    const token = LocalStorage.getToken();
    if (token) {
        window.location.replace(`${(window).CONFIG.AUTH_SERVICE_URL}/auth/sapient/${(window).CONFIG.APP_ID}`);
        return false;
    }

    // Set Token in Authorization request header
    config.headers[AUTHORIZATION_HEADER] = token;
    config.headers[AUGMENTED_TOKEN_FLAG] = LocalStorage[AUGMENTED_TOKEN_FLAG] || false;
    // config.headers['Content-Type'] = 'application/json';
    if(config.url.indexOf('undefined') > -1 || config.url.indexOf('undefined') > -1 ){
        alert('Error: Invalid URL -> ' + config.url);       // TODO for initial testing only, remove when deployed to prod
    }

    return config;
    }

    const handleError = (error) => {
        switch (error.response && error.response.status) {
            case 401:
                window.location.replace(`${(window).CONFIG.AUTH_SERVICE_URL}/auth/sapient/${(window).CONFIG.APP_ID}`);
                LocalStorage[LOCAL_STORAGE_KEY_TOKEN] = null;
                LocalStorage[AUGMENTED_TOKEN_FLAG] = null;
                break;
            // case 412:
                // toastr.error(error.response.data.response);  //PreCondidition failed/Missing Data exception
                break;
            default:
                // redirectTo(document, '/500')
                break;
        }
        return Promise.reject(error);
    }

    export const redirectTo = (document, path) => {
        document.location = path;
    }

    export const RequestInterceptors = {
        preProcessRequest, preProcessReqError
    };
    
    export const ResponseInterceptors = {
        handleSuccess, handleError
    };
