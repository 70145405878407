import {
  SET_APP_INIT_DATA_SUCCESS,
  SET_APP_INIT_DATA_LOADING,
  SET_APP_INIT_DATA_FAILURE
} from './constant';

const initialState = {
  appInitData: { user: "ppodar" },
  isloading: false,
  isError: false,
  errorMessage:''
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    // eslint-disable-next-line no-lone-blocks
    case SET_APP_INIT_DATA_LOADING: {
      return Object.assign({}, state, {
        isloading: true,
        isError: false
      });
    };
    case SET_APP_INIT_DATA_SUCCESS:
      return Object.assign({}, state, {
        data: action.data,
        isloading: false,
        isError: false
      });
    case SET_APP_INIT_DATA_FAILURE: {
      return Object.assign({}, state, {
        isloading: false,
        isError: true,
        errorMessage: action.errMessage
      });
    }

    default: return state;
  }
};

export default commonReducer;