import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CardHeader, Avatar, Typography, IconButton, Card, CardMedia, List, ListItem, ListItemIcon, Checkbox, ListItemText, Button, Switch } from '@material-ui/core';
import avatarImg from '../../pictures/profilePicAlt.png'
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import TodayIcon from '@material-ui/icons/Today';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const tags = [ 
   'Artificial Intelligence','React JS','HTML','CSS','MY SQL','Mongo Db','Spring Boot','Maven','Python','C++','Ruby','Content Writing','Eclipse','Git','Machine Learning',
  'Deep Learning','Web Development','App Development','Game Developer','PHP','JavaScript','Blockchain','TensorFlow','Angular Js 4','Node js','Big Data Analytics',
  'Hadoop','SAS','MariaDb','AWS','Ajax','Bootstrap','Django','FireBase','Java','Redux'];

const StyledIconButton = withStyles(theme => ({
  root: {
    padding: 2
  }
}))(IconButton)


const StyledButton = withStyles(theme => ({
  root: {
    marginRight: 5,
    padding: 3
  }
}))(Button)

const useStyles = makeStyles(theme => ({
  container: {
    padding: 15,
    flexWrap: 'wrap'
  },
  cardMedia: {
    padding: 30,
    height: '100%',
    alignItems: "center",
    display: 'flex',
    flexWrap: 'wrap'
  },
  avatar: {
    height: 200,
    width: 200,
    color: 'white'
  },
  profileTypo: {
    paddingLeft: 20,
  },
  cardHeader: {
    color: '#27B288',
    paddingTop: 20
  },
  bold: {
    color: '#27B288'
  },
  interestList: {
    height: 220,
    overflowY: 'scroll'
  },
  updateIconButton: {
    backgroundColor: '#ff334c',
  },
  card: {
    maxHeight: 300
  }
}));

export default function UserProfile() {

  const classes = useStyles();
  let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
  const [user, setUser] = useState({
    userId:'',
    username: '',
    emailId: '',
    mobileNumber: '',
    group: '',
    pictureUrl:'',
    areaOfInterest: [],
    proficiency: [],
    role: [],
    timeCreated: "2019-10-30T10:08:00.564+0000",
    timeEdited: "2019-10-30T10:08:00.564+0000",
    lastActive: "2019-10-30T10:08:00.564+0000",
  });

  React.useEffect(() => {
    
    axios.get('/userGateway/get/user/' +userId)
      .then(response => {
        setUser(response.data)
      });
  }, []);

  const [checked, setChecked] = React.useState([]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };


  return (
    <Grid container spacing={2} className={classes.container}>

      <Grid item xs={12} md={4}>
        <Card className={classes.cardMedia}>

          <Grid container justify="center" alignItems="center">

            <Avatar title="Profile picture" src={avatarImg} className={classes.avatar} />

            <Typography>
              <CardHeader
                title={user.username}
                className={classes.cardHeader}
              />
              <Typography className={classes.profileTypo}>
                Group: {user.group}<br />
                Location: Gurgaon, India<br />
                <IconButton title="Open Mail" style={{ color: '#1E9FC2' }}>
                  <EmailIcon />
                </IconButton>
                <IconButton title="Make a voice Call" style={{ color: '#C83030' }}>
                  <CallIcon />
                </IconButton>
                <IconButton title="Schedule a meet" style={{ color: '#27B288' }}>
                  <TodayIcon />
                </IconButton>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Typography>
            </Typography>
          </Grid>
          <div style={{ width: '100%', marginTop: 30 }}>
            <Typography>
              <b className={classes.bold}>Interest Areas:</b> {user.areaOfInterest.toString()}<br />
              <b className={classes.bold}>Proficiencies:</b> {user.proficiency.toString()}<br />
              <b className={classes.bold}>Ideas Generated: </b> 6<br />
              <b className={classes.bold}>Ideas Mentored: </b> 4<br />
              <b className={classes.bold}>Ideas Sponsored: </b> 8<br />
              <b className={classes.bold}>Budget Spent: </b> $2000<br />
              <b className={classes.bold}>Remaining Budget: </b> $2800
            </Typography>
          </div>
        </Card>
      </Grid>

      <Grid item xs={12} md={8} style={{ display: 'flex', flexWrap: 'wrap' }}>

        <Card style={{ margin: '1.5%', width: '47%' }}>
          <CardHeader
            title="Sponsor Details"
            titleTypographyProps={{ variant: 'h6' }}
            style={{ paddingBottom: 0, color: '#123456' }}
            action={
              <Button variant="outlined">Update</Button>
            }
          />
          <CardMedia style={{ padding: 5, fontSize: '120%', paddingLeft: 20 }}>
            Budget Spent: ${user.BudgetSpent},
              Remaining: ${user.RemainingBudget}<br />
            Change Budget: <StyledIconButton><AddIcon /></StyledIconButton>/<StyledIconButton><RemoveIcon /></StyledIconButton>
            <input type="text" placeholder="Enter valid Amount" style={{ padding: 5, borderStyle: "block", borderRadius: 2 }} />
            <b>Status: </b>InActive <Switch title="Select Active if you wish to be contacted for Sponsorships" /> Active
          </CardMedia>
        </Card>

        <Card style={{ margin: '1.5%', width: '47%' }}>
          <CardHeader
            title="Mentor Details"
            titleTypographyProps={{ variant: 'h6' }}
            style={{ paddingBottom: 0, color: '#ff713b' }}
            action={
              <Button variant="outlined">Update</Button>
            }
          />
          <CardMedia style={{ padding: 5, fontSize: '120%', paddingLeft: 20 }}>
            Areas you want to Mentor in: {user.proficiency.toString()}<br />
            <StyledButton variant="outlined" style={{ color: "#ff713b" }}>Change</StyledButton>
            <b>Status: </b>InActive <Switch title="Select Active if you wish to be contacted for Mentorships" /> Active
          </CardMedia>
        </Card>

        <Card style={{ margin: '1.5%', width: '50%' }}>
          <CardHeader
            title="Collaborator Details"
            titleTypographyProps={{ variant: 'h6' }}
            style={{ paddingBottom: 0, color: '#7e18d8' }}
            action={
              <Button variant="outlined">Update</Button>
            }
          />
          <CardMedia style={{ padding: 5, fontSize: '120%', paddingLeft: 20 }}>
            Your collaboration preferences: {user.areaOfInterest.toString()}<br />
            <StyledButton variant="outlined" style={{ color: '#7e18d8' }}>Change</StyledButton>
            <b>Status: </b>InActive <Switch title="Select Active if you wish to be contacted for Collaborations" /> Active
          </CardMedia>
        </Card>


        <Card style={{ margin: '1.5%', width: '47%' }}>
          <CardHeader title="Change Interest Areas"
            action={
              <StyledButton variant="contained" title="Click to update Interest Areas">
                <DoneIcon />
              </StyledButton>
            } />
          <input type="text" style={{ width: '100%', padding: 10 }} placeholder="Search for tags here..." />
          <CardMedia className={classes.interestList}>
            <List>
              <Typography style={{ paddingLeft: 10 }}>Suggestions</Typography>
              {tags.map(value => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={user.areaOfInterest.includes(value)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value}`} />
                  </ListItem>
                );
              })}
            </List>
          </CardMedia>
        </Card>

        <Card style={{ margin: '1.5%', width: '47%' }}>
          <CardHeader title="Change your Proficiencies"
            action={
              <StyledButton variant="contained" title="Click to update Interest Areas">
                <DoneIcon />
              </StyledButton>
            } />
          <input type="text" style={{ width: '100%', padding: 10 }} placeholder="Search for tags here..." />
          <CardMedia className={classes.interestList}>
            <List>
              <Typography style={{ paddingLeft: 10 }}>Suggestions</Typography>
              {tags.map(value => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value} role={undefined} dense StyledButton onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={user.proficiency.includes(value)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value}`} />
                  </ListItem>
                );
              })}
            </List>
          </CardMedia>
        </Card>

      </Grid>
    </Grid>
  )

}