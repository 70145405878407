import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {JustTagDropdown} from '../../Dropdowns/Dropdowns';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import MonetizationOnSharp from '@material-ui/icons/MonetizationOnSharp';
import IconButton from '@material-ui/core/IconButton';
import * as actions from '../../../store/actions/index'
import { useSelector } from 'react-redux';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const useStyles=makeStyles(theme => ({

  popbutton:{
    fontSize: '80%',
    marginLeft:'2px',
    color:'#123456',
    fontWeight:'bold'
  },
  button:{
    color:"#123456",
    padding:6
  },
  view:{
    display:'none',
  }
}));

export default function SponsorAnIdea(props) {
  const classes=useStyles();
  let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
  const [open, setOpen] = React.useState(false);
  const [sponsorList,setSponsorList]=React.useState([]);
  const [registor, setRegistor] = React.useState(false);
  const [sponsor,setSponsor]=React.useState({
  accepted: false,
  active: true,
  budget: 0,
  ideaId: props.ideaId,
  information: [],
  timeLog: [
    {
      timeCreated: "2019-11-20T03:44:17.240Z",
      timeEdited: "2019-11-20T03:44:17.240Z"
    }
  ],
  timeOfAllocation: "2019-11-20T03:44:17.240Z"
});

  const [newSponsor,setNewSponsor]=React.useState({
  active: true,
  availableBudget:0,
  budgetSpent:0,
  ideas:[],
  interestAreas:[],
  score:0,
  userId:userId
 });

  React.useEffect(() => {
    // axios.get('ec2-35-170-200-40.compute-1.amazonaws.com:8080/sponsorGateway/get/Sponsors/SponsorLayout/'+userId)
    axios.get('/sponsorGateway/get/Sponsors/SponsorLayout/'+userId)
      .then(response => {
        setSponsorList(response.data)
      });
  }, []);

  const handleRegistorTags=(e,value)=>
  { 
    setNewSponsor({ ...newSponsor, ['interestAreas']: value });
  }

  const handleRegistorChange = name => event => {
    setNewSponsor({ ...newSponsor, [name]: event.target.value });
  };

  const handleRegistorClose=()=>{
    let value=[];
    setNewSponsor({ ...newSponsor, ['interestAreas']: value });
    setRegistor(false);
  }

  const handleRegistor=()=>{
    let axiosConfig = {
      headers: {
          //   'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
  };
    axios.post('/sponsorGateway/registerNew', newSponsor,axiosConfig)
      .then(response =>{
        setRegistor(false);
        setOpen(true);
        })
  }

  const handleChange = (name) => event => {                           
    setSponsor({ ...sponsor, [name]: event.target.value });
  };

  const handleClickOpen = () => {
    if(sponsorList.filter(r=>r.sponsor.userId===userId).length!==0)
    {
      setOpen(true);
    }
    else
    {
      setRegistor(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSponsor=()=>{
    axios.put('/sponsorGateway/addIdea/'+userId, sponsor)
      .then(response =>{
        handleClose();
        })
  }

  return (
    <div>
    <IconButton onClick={handleClickOpen} className={clsx( classes.button, (props.data && classes.view))} disabled={!props.open}>
         <Tooltip title="Sponsor this Idea"><MonetizationOnSharp /></Tooltip>
                            </IconButton>
      <Button variant="outlined"  onClick={handleClickOpen} className={clsx(classes.popbutton, !props.data && classes.view)} disabled={!props.open} >
        Sponsor this idea
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle id="form-dialog-title">Sponsor</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            type="email"
            label="Why do you want to Sponsor this idea"
            fullWidth
          />
          <TextField 
            margin="normal"
            fullWidth
            label="Budget Alloting"
            autoComplete="title"
            onChange={handleChange('budget')}
            InputProps={{
          inputComponent: NumberFormat,
        }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSponsor} color="primary">
            Sponsor
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={registor} onClose={handleRegistorClose} fullWidth>
        <DialogTitle id="form-dialog-title">Please registor as Sponsor first</DialogTitle>
        <DialogContent className={classes.root}>
          <TextField
            variant="outlined" 
            margin="normal"
            required
            fullWidth
            id="email"
            label="Available Budget"
            onChange={handleRegistorChange('availableBudget')}
            autoComplete="title"
            InputProps={{
          inputComponent: NumberFormat,
        }}
          />
          <JustTagDropdown data={'Select the areas you would like to Sponsor'} selected={newSponsor.interestAreas} handleDropdown={handleRegistorTags} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRegistorClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRegistor} color="primary">
            Sponsor
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}