import * as actionTypes from './actionTypes';
import axios from 'axios';

export const setIdeas = ( ideas ) => {
    return {
        type: actionTypes.SET_IDEAS,
        ideas: ideas
    };
};

export const fetchIdeaFailed = () => {
    return {
        type: actionTypes.FETCH_IDEAS_FAILED
    };
};

export const setUser = ( user ) => {
    return {
        type: actionTypes.SET_USER,
        user: user
    };
};
export const setToken = ( token ) => {
    return {
        type: actionTypes.SET_TOKEN,
        token: token
    };
};

export const initIdeas = () => {
    return dispatch => {
        // axios.get( 'http://ec2-107-21-76-90.compute-1.amazonaws.com:8081/idea' )
        axios.get( '/idea' )
            .then( response => {
                // console.log(response.data);
               dispatch(setIdeas(response.data));
            } );
    };
};