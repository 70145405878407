import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import IconButton from '@material-ui/core/IconButton';
import * as actions from '../../../store/actions/index'
import { useSelector } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const useStyles = makeStyles(theme => ({

  popbutton: {
    fontSize: '80%',
    color: '#7e18d8',
    marginLeft: '2px',
    fontWeight: 'bold'
  },
  button: {
    color: '#7e18d8',
    padding: 6
  },
  view: {
    display: 'none',
  },
}));


export default function CollaborateAnIdea(props) {
  let axiosConfig = {
    headers: {
      //   'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  };
  const classes = useStyles();
  let userId = '';
  useSelector(state => {
    userId = state.appReducer.user;
  })
  const [open, setOpen] = React.useState(false);
  const [collaboratorList, setCollaboratorList] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState({});
  const [collaborator, setCollaborator] = React.useState({
    active: true,
    ideas: [],
    expertise: [],
    score: 0,
    userId: userId
  });
  const [collaboratorIdea, setCollaboratorIdea] = React.useState({
    accepted: false,
    active: true,
    contribution: [''],
    deliverables: [''],
    ideaId: props.ideaId,
    progress: 0,
    timeLog: [
      {
        timeCreated: "2019-11-20T06:21:30.779Z",
        timeEdited: "2019-11-20T06:21:30.779Z"
      }
    ]
  });

  React.useEffect(() => {
    
    axios.all([
      // axios.get('ec2-35-170-200-40.compute-1.amazonaws.com:8080/collaboratorGateway/get/Collaborators/CollaboratorLayout/'+userId)
      axios.get('/collaboratorGateway/get/Collaborators/CollaboratorLayout/' + userId)
        .then(response => {
          setCollaboratorList(response.data)
        }),
      axios.get('/userGateway/get/user/' + userId)
        .then(response => {
          setUserDetails(response.data);
          setCollaborator({ ...collaborator, ['expertise']: response.data.proficiency });
        })
    ]);
  }, []);



  const handleTags = (e, value) => {
    setCollaboratorIdea({ ...collaboratorIdea, ['contribution']: value });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCollaborator = () => {
    let axiosConfig = {
      headers: {
        //   'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios.put('/collaboratorGateway/addIdea/' + userId, collaboratorIdea, axiosConfig)
      .then(response => {
        handleClose();
      })
  }
  const handleRegister = () => {
    let axiosConfig = {
      headers: {
        //   'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    if (collaboratorList.filter(r => r.collaborator.userId === userId).length === 0) {
      axios.post('/collaboratorGateway/registerNew', collaborator, axiosConfig)
        .then(response => {
          handleCollaborator();
        })
    }
    else {
      handleCollaborator();
    }
    var axios = require('axios');

    var config = {
      method: 'put',
      url: `/ideaGateway/addCollaborator/${props.ideaId},${userId}`,
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen} className={clsx(classes.button, (props.data && classes.view))} disabled={!props.open}>
        <Tooltip title="Collaborate with this Idea"><GroupWorkIcon /></Tooltip>
      </IconButton>
      <Button variant="outlined" className={clsx(classes.popbutton, !props.data && classes.view)} onClick={handleClickOpen} disabled={!props.open}>
        Collaborate on idea
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle id="form-dialog-title">Collaborate</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            type="email"
            fullWidth
            label="Why do you want to collaborate this idea"
          />
          <Autocomplete
            multiple
            options={props.tags}
            filterSelectedOptions
            getOptionLabel={option => option}
            style={{ padding: 0, margin: 0 }}
            onChange={handleTags}
            autoHighlight={true}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="Areas you would like to collaborate"
                style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
                margin="normal"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRegister} color="primary">
            Collaborate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}