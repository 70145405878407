import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, MenuItem, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import IdeaTable from '../../containers/Idea/IdeaTable';
import IdeaLayoutGridView from './IdeaLayoutGridView';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Feature from '../Feature';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                color: "black",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: 1
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    button: {
        height: 20,
        marginTop: 0,
        marginLeft: 5
    },
    textField: {
        width: '50%'
    },
    toggleGroup: {
        height: 40
    },
    toggleButton: {
        marginTop: 0,
        height: 36
    },
    searchContainer: {
        margin: 5,
        marginBottom: 5,
        padding: 0,
        height: 50,
        display: 'flex'
    },
    formControl: {
        width: 150,
        marginLeft: 5,
        height: 30
    },
    input: {
        height: 40
    }
}))

const industries = ['None', 'Consumer Products', 'Energy & Commodities', 'Financial Services', 'Telecommunications, Media & Technology', 'Public Sector', 'Retail', 'Telecommunications, Media & Technology', 'Transportation & Mobility', 'Travel & Hospitality', 'Internal', 'Others'];
const statusVal = ['None', 'Submitted', 'MVP in progress', 'MVP in review', 'MVP complete', 'Sponsored for solution development', 'Solution in progress', 'Solution in review', 'Complete']
function sortByLikes(a, b) {
    const diff = a.likes.length - b.likes.length
    return -1 * diff;
}

function SortByViews(a, b) {
    const diff = a.views.length - b.views.length
    return -1 * diff;
}

function sortByTimeLatest(a, b) {
    const diff = a.timeCreated.localeCompare(b.timeCreated.toString().toLowerCase())
    return -1 * diff;
}

function IdeaLayout() {
    const classes = useStyles();
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const [ideas, setIdeas] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [comment, setComments] = useState({
        user: "",
        comment: ""
    });
    const [gridView, setGridView] = useState("Grid");
    const [sortBy, setSortBy] = useState(1);
    const [Filter, setFilter] = useState(0);

    useEffect(() => {

        axios.get(('/ideaGateway/get/dashboardIdeas/' + userId))
            .then(response => {
                setIdeas(response.data);
                setFiltered(response.data)
            });
    }, []);
    const filterlist = (event) => {
        let searchText = event.target.value;
        let Ideas = ideas;
        Ideas = Ideas.filter(Idea => {
            const Tags = Idea.tags;
            return ((Idea.title.toLowerCase().search(searchText.toLowerCase()) !== -1 || Tags.filter(tag => { return tag.toLowerCase().match(searchText.toLowerCase()) }).length > 0 || Idea.status.toLowerCase().search(searchText.toLowerCase()) !== -1) & (Idea.status !== "Draft"));
        });
        setFiltered(Ideas)
    }

    const handleGridView = (event, newView) => {
        console.log(newView);
        setGridView(newView);
    }

    const handleComment = user => (e) => {
        setComments({ ...comment, ['user']: user });
        setComments({ ...comment, ['comment']: e.target.value });
    }

    const handlePostComment = (idx) => e => {
        let tempIdeas = ideas;
        tempIdeas[idx].comments.push(comment);
        setIdeas(tempIdeas);

        console.log(ideas);
    }
    const handleSortBy = (event, sortByValue) => {
        setSortBy(sortByValue.props.value);
        if (sortByValue.props.value === 4)
            setFiltered(filtered.sort(sortByLikes))
        else if (sortByValue.props.value === 3)
            setFiltered(filtered.sort(SortByViews))
        else if (sortByValue.props.value === 2)
            setFiltered(filtered.sort(sortByTimeLatest));
    }

    const handleFilter = (event, FilterValue) => {
        setFilter(FilterValue.props.value)
        if (FilterValue.props.value === 0)
            setFiltered(ideas)
        else if (FilterValue.props.value === 1) {
            let Ideas = ideas;
            Ideas = Ideas.filter(Idea => {
                return (Idea.sponsorOpen === true)
            })
            setFiltered(Ideas)
        }
        else if (FilterValue.props.value === 2) {
            let Ideas = ideas;
            Ideas = Ideas.filter(Idea => {
                return (Idea.mentorOpen === true)
            })
            setFiltered(Ideas)
        }
        else if (FilterValue.props.value === 3) {
            let Ideas = ideas;
            Ideas = Ideas.filter(Idea => {
                return (Idea.collaboratorOpen === true)
            })
            setFiltered(Ideas)
        }
    }

    const handleIndustryFilter = (event, Industry) => {
        if (Industry !== 'None') {
            let Ideas = ideas;
            Ideas = Ideas.filter(Idea => {
                return (Idea.originIndustry === Industry)
            })
            setFiltered(Ideas)
        }
        else {
            setFiltered(ideas);
        }
    }

    const handleStatusFilter = (event, Status) => {
        if (Status !== 'None') {
            let Ideas = ideas;
            Ideas = Ideas.filter(Idea => {
                return (Idea.status === Status)
            })
            setFiltered(Ideas)
        }
        else {
            setFiltered(ideas);
        }
    }

    return (
        <div style={{ padding: 10 }}>
            <Grid container>
                <Grid item className={classes.searchContainer} xs={12} md={9}>
                    <TextField InputProps={{ className: classes.input }} className={classes.textField} onChange={filterlist} variant="outlined" placeholder="Search using Idea Title or Tags or Status" />
                   <Feature name='show-feature'>
                        <Grid item style={{ marginLeft: 5 }}>
                            <TextField
                                variant="outlined"
                                value={Filter}
                                select
                                InputProps={{
                                    className: classes.input
                                }}
                                label="Search by"
                                onChange={handleFilter}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem value={1}>Sponsor Open</MenuItem>
                                <MenuItem value={2}>Mentor Open</MenuItem>
                                <MenuItem value={3}>Collaborator Open</MenuItem>
                            </TextField>
                        </Grid>
                        <Autocomplete
                            id="combo-box-demo"
                            options={industries}
                            size="small"
                            defaultValue="None"
                            onChange={handleIndustryFilter}
                            getOptionLabel={(option) => option}
                            style={{ width: 200, marginLeft: '.3rem' }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Search by Industry" />
                            )}
                        />
                        <Autocomplete
                            id="combo-box-demo"
                            options={statusVal}
                            size="small"
                            defaultValue="None"
                            onChange={handleStatusFilter}
                            getOptionLabel={(option) => option}
                            style={{ width: 200, marginLeft: '.3rem' }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Search by Status" />
                            )}
                        />
                        <Grid item style={{ marginLeft: 5 }}>
                            <TextField
                                variant="outlined"
                                value={sortBy}
                                select
                                InputProps={{
                                    className: classes.input
                                }}
                                label="Sort By"
                                onChange={handleSortBy}
                            >
                                <MenuItem value={1}>None</MenuItem>
                                <MenuItem value={2}>Latest</MenuItem>
                                <MenuItem value={3}>most Viewed</MenuItem>
                                <MenuItem value={4}>most Liked</MenuItem>
                            </TextField>
                        </Grid>
                      </Feature>
                        <Card style={{ marginLeft: 5, padding: 2, minWidth: 100, height: 40 }}>
                            <ToggleButtonGroup
                                value={gridView}
                                exclusive
                                onChange={handleGridView}
                                className={classes.toggleGroup}
                                aria-label="IdeaGridView">
                                <ToggleButton value="Grid" title="Grid View" className={classes.toggleButton}>
                                    <ViewModuleIcon />
                                </ToggleButton>
                                <ToggleButton value="List" title="List View" className={classes.toggleButton}>
                                    <ViewListIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Card>
                </Grid>
            </Grid>
            <Grid container spacing={1} justify="flex-start" style={{ width: '100%' }}>
                {
                    (gridView == "Grid") ? <IdeaLayoutGridView data={filtered} commentvalue={handleComment} postcomment={handlePostComment} /> : <IdeaTable data={filtered} commentvalue={handleComment} postcomment={handlePostComment} />
                }
            </Grid>

        </div>
    )
}

export default React.memo(IdeaLayout);
