import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles';
import CampaignImage from '../../pictures/Campaign.png';
import TablePagination from '@material-ui/core/TablePagination';
import { useSelector } from 'react-redux';
import CampaignLayoutRow from './CampaignLayoutRow.js';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Feature from '../Feature';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#00e6e6',
        color: '#46494C',
        fontSize: '0.9em',
        textAlign: 'center',
        padding: 10
    },
    body: {
        padding: 8,
        textAlign: 'center'
    },
    image: {
        height: '100px',
        width: '80%'
    }
}))(TableCell);

const StyledTableCell2 = withStyles(theme => ({
    head: {
        backgroundColor: 'white',
        color: '#46494C',
        fontSize: '0.9em',
        textAlign: 'center',
        padding: 10
    },
    body: {
        padding: 8,
        textAlign: 'center'
    },
    image: {
        height: '100px',
        width: '80%'
    }
}))(TableCell);

const StyledButton = withStyles(theme => ({
    label: {
        color: '#46494C',
        fontSize: '0.9em',
        textAlign: 'center',
    }
}))(Button);


const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#ededed'
        }
    }
}))(TableRow);

const BorderLinearProgress = withStyles({
    root: {
        height: 8,
        backgroundColor: lighten('#ff334c', 0.7),
        borderRadius: 10
    },
    bar: {
        borderRadius: 20,
        backgroundColor: lighten('#ff334c', 0.2)
    },
})(LinearProgress);

const CampaignStyles = makeStyles(theme => {
    return ({
        likecolor: {
            color: '#1b6deb',
        },
    });
});

const campaignStatus = ['Draft', 'Launched', 'Stage I', 'Stage II', 'Stage III', 'Stage IV', 'Stage V', 'Completed', 'Dropped'];

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key].length < b[sortConfig.key].length) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key].length > b[sortConfig.key].length) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'descending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'descending'
        ) {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

const campaignType = {
    "campaignId": "",
    "awards": [
        {
            "title": "",
            "value": ""
        }
    ],
    "campaignCoreTeamMembers": [],
    "comments": [
        {
            "user": "",
            "comments": ""
        }
    ],
    "deadline": "2019-12-10T08:46:33.638Z",
    "deleted": false,
    "description": "",
    "goals": [
        {
            "deleted": false,
            "description": "",
            "industry": "",
            "goalId": "",
            "ideas": [
                {
                    "collaboratorOpen": true,
                    "deadline": "2019-12-10T08:46:33.638Z",
                    "deleted": false,
                    "description": "",
                    "ideaId": "",
                    "likes": [],
                    "mentorOpen": true,
                    "sponsorOpen": true,
                    "status": "",
                    "tags": [],
                    "timeCreated": "2019-12-10T08:46:33.638Z",
                    "title": "",
                    "userId": "",
                    "username": "",
                    "views": []
                }
            ],
            "timeCreated": "2019-12-10T08:46:33.638Z",
            "timeEdited": "2019-12-10T08:46:33.638Z",
            "title": ""
        }
    ],
    "stages":
        [{
            "currentStage": "",
            "stageMilestone": "",
            "stageCriteria": "",
            "stageSummary": "",
            "stageStart": "",
            "stageEnd": ""
        }],
    "likes": [],
    "progress": 0,
    "rewards": [],
    "rules": [],
    "status": "",
    "timeCreated": "2019-12-10T08:46:33.638Z",
    "timeEdited": "2019-12-10T08:46:33.638Z",
    "startCampaignDate": "",
    "endCampaignDate": "",
    "title": "",
    "userId": "",
    "userName": "",
    "visibility": []
}

export default function CampaignLayout() {
    const classes = CampaignStyles();
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const [campaigns, setCampaigns] = useState(campaignType);
    const [filtered, setFiltered] = useState([]);
    const [like, setLike] = React.useState([]);
    const [comment, setComments] = useState({
        user: "",
        comment: ""
    });

    const filterArray = []
    useEffect(() => {

        axios.get('/campaignGateway/get/dashboardCampaigns')
            .then(response => {
                setCampaigns(response.data);
                setFiltered(response.data);
                setLike(response.data.likes)
                console.log("1234567", response.data)
            });

    }, []);

    const filterList = (event) => {
        let searchText = event.target.value;
        let items = campaigns;

        items = items.filter(item => {
            return (item.title.toLowerCase().toString().search(searchText.toLowerCase()) !== -1 || item.owner.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || item.status.toLowerCase().search(event.target.value.toLowerCase()) !== -1);
        });
        setFiltered(items);

    }


    const filterListName = (event) => {
        // let searchText=event.target.value;
        // let items = campaigns.map((item) => item);
        // console.log("555555555",items)
        // items = items.filter(item1 => {
        //     console.log("item",item1.userName)
        //     return (item1.title.toLowerCase().search(searchText) !== -1 || item1.userName.toLowerCase().search(event.target.value) !== -1);
        // });
        // setFiltered(items);
        let searchText = event.target.value;
        let items = campaigns;

        items = items.filter(item => {
            return (item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || item.userName.toLowerCase().search(event.target.value.toLowerCase()) !== -1);
        });
        setFiltered(items);
        console.log("helloooo", items);
    }

    const filterListIdeas = (event) => {
        let searchText = event.target.value;
        let items = campaigns;
        if (searchText) {
            items = items.filter(item => {
                return (item.ideas.length.toString().search(event.target.value.toLowerCase()) !== -1);
            });
        }
        setFiltered(items);
    }

    const handleComment = user => (e) => {
        setComments({ ...comment, ['user']: user });
        setComments({ ...comment, ['comment']: e.target.value });
    }

    const handlePostComment = (idx) => e => {
        let tempCampaigns = campaigns;
        tempCampaigns[idx].comments.push(comment);
        setCampaigns(tempCampaigns);
    }

    function filterListStatus(e, value) {
        let searchText = value;
        let items = campaigns;
        if (searchText != null) {
            items.map(item => {
                if (item.status.toLowerCase() == searchText.toLowerCase()) {
                    filterArray.push(item)

                }
            })
            setFiltered(filterArray);
        }
        else {
            setFiltered(items);
        }

    }

    const filterListDate = (event) => {
        let searchText = event.target.value;
        let items = campaigns;

        items = items.filter(item => {
            return (item.endDate.$date.$numberLong.toLowerCase().search(event.target.value.toLowerCase()) !== -1);
        });
        setFiltered(items);
    }

    function filterListResources(e, value) {
        let val = value;
        if (val === '# of Ideas')
            requestSort('ideas');

        if (val === '# of Collaborators')
            requestSort('collaborators');

        if (val === 'PS Coins')
            requestSort('pscoins');
    }



    const { items, requestSort, sortConfig } = useSortableData(filtered);


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let val = 1;

    return (
        <div>
            <Grid container xs={12} md={12} justify="center">
                <img src={CampaignImage} style={{ height: '100px', width: '80%' }} />
                <marquee> Campaigns help harness the true power of one and bring the community together. Single or multi-goal, single or multi-stage, all the campaigns can be launched here.<Link to="/createCampaign">Click here to launch your campaign</Link></marquee>
            </Grid>
            <Grid container spacing={1} style={{ padding: '10px' }}>
                <Grid item xs={12} justify="center" style={{ overflowX: 'auto' }}>
                    <Table >
                        <TableHead>
                            <TableRow style={{ fontSize: '150%' }}>
                                <StyledTableCell><StyledButton
                                    type="StyledButton"
                                    onClick={() => requestSort('title')}
                                >Campaign Name</StyledButton>
                                </StyledTableCell>
                                <StyledTableCell><StyledButton
                                    type="StyledButton"
                                >Goals</StyledButton></StyledTableCell>
                                <StyledTableCell><StyledButton
                                    type="StyledButton"
                                    onClick={() => requestSort('status')}
                                >Status</StyledButton>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <StyledButton
                                        type="StyledButton"
                                        onClick={() => requestSort('title')}
                                    >Stage Completion</StyledButton>
                                </StyledTableCell>
                                <StyledTableCell><StyledButton
                                    type="StyledButton"
                                >Resources</StyledButton>
                                </StyledTableCell>
                                <Feature name='show-feature'>
                                    <StyledTableCell><StyledButton
                                        type="StyledButton"
                                    >Actions</StyledButton></StyledTableCell>
                                </Feature>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell2>
                                    <TextField InputProps={{ style: { height: 38 } }} onChange={filterListName} variant="outlined" style={{ width: '70%' }} />
                                </StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>

                                <StyledTableCell2 >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={campaignStatus}
                                        onChange={filterListStatus}
                                        getOptionLabel={(option) => option}
                                        style={{ width: 150 }}
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" placeholder="Select Status Wise" style={{ marginLeft: "40px" }} />
                                        )}
                                    />
                                </StyledTableCell2>
                                <StyledTableCell2>
                                    <Feature name="show-feature">
                                        <TextField InputProps={{ style: { height: 38 } }} onChange={filterListDate} variant="outlined" style={{ width: '70%' }} />
                                    </Feature>
                                </StyledTableCell2>
                                <StyledTableCell2>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={['# of Ideas']}
                                        onChange={filterListResources}
                                        getOptionLabel={(option) => option}
                                        style={{ width: 150 }}
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" placeholder="Sort By" style={{ marginLeft: "40px" }} />
                                        )}
                                    />
                                </StyledTableCell2>
                                <StyledTableCell2></StyledTableCell2>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items
                            ).map(function (campaign) {
                                if (campaign.status !== 'Draft') {
                                    return <CampaignLayoutRow key={campaign._id} keyVal={val++} campaign={campaign} handleComment={handleComment} handlePostComment={handlePostComment} />
                                }
                            })}
                        </TableBody>
                    </Table>
                </Grid>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}

                />
            </Grid>
        </div>
    )
}