import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { setTheme } from './redux/actions';
import Brightness4OutlinedIcon from '@material-ui/icons/Brightness4Outlined';
import Brightness4RoundedIcon from '@material-ui/icons/Brightness4Rounded';
import './theme.scss';

export default function Theme() {
  const dispatch = useDispatch();

  const onSetTheme = useCallback(
    theme =>
      dispatch(setTheme(theme)),
    []
  );
  let CurrentTheme = '';
  useSelector(state => {
    CurrentTheme = state.themeReducer.theme.palette.type;
  })
  return (
    <div className="theme-types">
     {CurrentTheme === 'light' ?
        <Brightness4RoundedIcon onClick={() => onSetTheme('dark')} />
        :
        <Brightness4OutlinedIcon onClick={() => onSetTheme('light')} />
      }
    </div>
  );
}