import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MonetizationOnSharp from '@material-ui/icons/MonetizationOnSharp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';
import { Link } from 'react-router-dom';

const mobileMenuId = 'primary-search-account-menu-mobile';

function MobileMenu({isMobileMenuOpen, handleMobileMenuClose, mobileMoreAnchorEl}) {
    return (
        <div className="mobile-menu">
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >

                <Link to="/createCampaign" style={{ textDecorationLine: "none", color: '#373737' }}>
                    <MenuItem onClick={handleMobileMenuClose}>
                        <BarChartIcon />
                        <p> Start a Campaign</p>
                    </MenuItem>
                </Link>

                <Link to="/createIdea" style={{ textDecorationLine: "none", color: '#373737' }}>
                    <MenuItem onClick={handleMobileMenuClose}>
                        <EmojiObjectsSharp />
                        <p> Add a new Idea</p>
                    </MenuItem>
                </Link>

                <MenuItem>
                    <MonetizationOnSharp />
                    <p>Sponsor an Idea</p>
                </MenuItem>

                <MenuItem>
                    <PeopleIcon />
                    <p> Mentor an Idea</p>
                </MenuItem>

                <MenuItem>
                    <IconButton aria-label="show 11 new notifications" color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p> Notifications</p>
                </MenuItem>

                <Link to="/signIn" style={{ textDecorationLine: "none", color: '#373737' }}>
                    <MenuItem>
                        <IconButton aria-label="show 11 new notifications" color="inherit">
                            <ExitToAppIcon />
                        </IconButton>
                        <p>Logout</p>
                    </MenuItem></Link>

            </Menu>
        </div>
    )
}

export default MobileMenu
