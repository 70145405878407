import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CommentIcon from '@material-ui/icons/Comment';
import CollaborateAnIdea from './CollaborateAnIdea';
import SponsorAnIdea from './SponsorAnIdea';
import MentorAnIdea from './MentorAnIdea';
import Visibility from '@material-ui/icons/Visibility';
import ThumbUp from '@material-ui/icons/ThumbUpRounded';
import Card from '@material-ui/core/Card';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import axios from 'axios';
import { CardHeader, Avatar, Typography, Divider, Paper, Tooltip, MuiThemeProvider, createTheme, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Feature from '../../Feature';


const ideaType = {
  "ideaId": "",
  "title": "",
  "author": {
    "userId": "",
    "username": "",
    "group": "",
    "pictureUrl": "",
    "role": [
      ""
    ],
    "proficiency": [
      ""
    ],
    "areaOfInterest": [
      ""],
    "emailId": "",
    "mobileNumber": "",
    "createdBy": "",
    "timeCreated": "",
    "timeEdited": "",
    "lastActive": "",
    "active": true
  },
  "description": "",
  "problemStatement": "",
  "proposedSolution": "",
  "impact": "",
  "status": "",
  "campaignGoals": [
    ""
  ],
  "tags": [
    ""
  ],
  "sponsors": [],
  "mentors": [],
  "collaborators": [],
  "resources": [
    {
      "name": "",
      "link": "",
      "description": ""
    }
  ],
  "comments": [
    {
      "user": "",
      "comment": "",
    }
  ],
  "achievements": [
    ""
  ],
  "likes": [
    ""
  ],
  "views": [
    ""
  ],
  "timeCreated": "2019-11-29",
  "timeEdited": "",
  "estimatedDeadline": "2019-11-28",
  "originIndustries": "",
  "targetIndustries": [],
  "mentorOpen": true,
  "sponsorOpen": true,
  "collaboratorOpen": true,
  "isDeleted": false
}

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    },
    card: {
      margin: 0,
      padding: 0
    }
  }
});

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 70,
    width: 70,
    padding: 0
  },
  card: {
    margin: 0,
    padding: 0
  },
  ideaIcon: {
    height: '100%',
    width: '100%',
    padding: 0,
    color: 'grey',
    backgroundColor: 'white'
  },
  text: {
    padding: 10
  },
  button: {
    display: 'flex',
    flexDirection: 'row'
  },
  likecolor: {
    color: '#1b6deb',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '50%',
  },
  input: {
    margin: theme.spacing(1),
  },
  popbutton: {
    color: 'white',
    marginTop: '8px',
    marginRight: '20px',
    borderColor: 'white'

  },
  button2: {
    textAlign: 'center',
  },
  button: {
    textAlign: 'right',
  },
  title: {
    background: 'linear-gradient(230deg, #13b8a2, #00d4ff)',
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '1.7rem'
  },
  content: {
    flex: 'grow',
  },
  textfield: {
    width: '100%',
  },
  switch: {
    width: '5%',
    left: '25%',
  },
  item: {
    overflow: 'hidden',
    margin: 0,
    padding: 0
  },
  box1: {
    background: '#F5F5F5',
  },
  hei: {
    marginTop: '5%',
    top: '5%',
    marginLeft: '10%',
    maxHeight: '87%',
    maxWidth: '80%',
  },
  cardHead: {
    margin: '0',
    padding: '0',
    marginTop: '4px',
    marginBottom: '4px',
  },
  display: {
    display: 'none',
  },
  bulb: {
    color: 'gold',
    fontSize: '50px'
  },
  coin: {
    color: 'gold'
  },
  editIcon: {
    backgroundColor: 'white',
    color: '#127668',
    padding: 5,
    height: 40,
    width: 40
  }
}));



export default function ViewIdea({ match }) {
  const classes = useStyles();
  let userId = '';
  useSelector(state => {
    userId = state.appReducer.user;
  })
  const [idea, setIdea] = useState(ideaType);
  const [like, setLike] = useState([]);
  const [openComments, setOpenComments] = React.useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  useEffect(() => {


    axios.get('/ideaGateway/viewIdea/' + match.params.ideaid)
      .then(response => {
        setLike(response.data.likes)
        setIdea(response.data)
      });
  }, []);

  const handleLike = () => {
    let axiosConfig = {
      headers: {
        //   'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    if (!like.includes(userId)) {
      let temp = like.concat(userId);
      setLike(temp);
      var axios = require('axios');

      var config = {
        method: 'put',
        url: `/ideaGateway/addLike/${idea.ideaId},${userId}`,
        headers: {
          'Content-Type': 'text/plain',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
    }
    else {
      let temp = like.filter(user => user !== userId);
      setLike(temp);
      var axios = require('axios');

      var config = {
        method: 'put',
        url: `/ideaGateway/deleteLike/${idea.ideaId},${userId}`,
        headers: {
          'Content-Type': 'text/plain',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
    }
  }

  const handleClickOpenComments = () => {
    setOpenComments(true);
  };

  const handleCloseComments = () => {
    setOpenComments(false);
  };


  return (
    <div>
      <Grid item className={classes.title}>
        <DialogTitle id="form-dialog-title"><span style={{ fontSize: '50px' }}>{idea.title}<EmojiObjectsSharp className={classes.bulb} /></span></DialogTitle>
      </Grid>
      <Grid container md={11} style={{ marginLeft: 10, paddingBottom: 30 }}>
        <Grid item xs={12} md={12} className={classes.item} style={{ marginTop: '1rem' }}>
          <Card className={classes.card}>
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Editor
                editorState={idea.description.length > 0 ? EditorState.createWithContent(convertFromRaw(JSON.parse(idea.description))) : EditorState.createEmpty()}
                name="description"
                toolbar={{
                  options: []
                }}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                readOnly={true}
                placeholder="Hi I am a rich text editor"
              />
            </CardContent>
          </Card>
        </Grid>


        <Grid xs={12} md={12} style={{ overflow: 'visible', height: '100%' }} className={classes.item}>
          <Card className={classes.card} style={{ overflow: 'visible' }}>
            <CardContent style={{ paddingTop: '4px', paddingBottom: '4px' }}>
              <CardHeader className={classes.cardHead} title="Industries"></CardHeader>
              <Grid container>
                <Grid xs={12} md={6} className={classes.item}>
                  <Autocomplete
                    options={[]}
                    disableCloseOnSelect
                    value={idea.originIndustries}
                    style={{ width: '95%', marginTop: 10, marginBottom: 10 }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Originating Industry" placeholder="Originating Industry" />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={6} className={classes.item}>
                  <Autocomplete
                    multiple
                    options={[]}
                    value={idea.targetIndustries}
                    style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Target Industries" placeholder="Add More" />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} md={12} className={classes.item}>
          <Card className={classes.card} >
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
              <CardHeader className={classes.cardHead} title="About the Idea"></CardHeader>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputProps={{
                  readOnly: true
                }}
                value={idea.problemStatement}
                style={{ marginTop: '10px', marginBottom: '10px' }}
                label="Problem statement"
                name="Problem statement"
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{
                  readOnly: true
                }}
                required
                value={idea.proposedSolution}
                style={{ marginTop: '5px', marginBottom: '10px' }}
                label="Proposed Solution"
                name="Proposed Solution"
                multiline="true"
                rows='2'
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                inputProps={{
                  readOnly: true
                }}
                value={idea.impact}
                style={{ marginTop: '5px', marginBottom: '10px' }}
                label="Proposed Impact"
                name="Impact"
              />
            </CardContent>
          </Card>
        </Grid>
        <Feature name='show-feature'>
          <Grid item xs={12} md={12} className={classes.item}>
            <Card className={classes.card} >
              <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                <CardHeader className={classes.cardHead} title="Teams"></CardHeader>
                <CardContent style={{ padding: '0.5rem 1rem' }}>
                  <text>
                    <Grid container>
                      <Grid xs={12} md={2}>
                        <strong>Mentors :</strong>
                      </Grid>
                      <Grid className={classes.item} xs={12} md={2}>
                        <Switch
                          checked={idea.mentorOpen}
                          value="mentor"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          size="large"
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {idea.mentors.map(item => (
                          <Chip size="small"
                            label={item} style={{ margin: "10px" }}>
                            &times;
                          </Chip>
                        ))}
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        <MentorAnIdea data={true} open={idea.mentorOpen} />
                      </Grid>
                      <br />
                      <Grid xs={12} md={2}>
                        <strong>Sponsors:</strong>
                      </Grid>
                      <Grid className={classes.item} xs={12} md={2}>
                        <Switch
                          checked={idea.sponsorOpen}
                          value="sponsor"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          size="large"
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {idea.sponsors.map(item => (
                          <Chip size="small"
                            label={item} style={{ margin: "10px" }}>
                            &times;
                          </Chip>
                        ))}
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        <SponsorAnIdea data={true} open={idea.sponsorOpen} />
                      </Grid>
                      <br />
                      <Grid xs={12} md={2}>
                        <strong>Collaborators:</strong>
                      </Grid>
                      <Grid className={classes.item} xs={12} md={2}>
                        <Switch
                          checked={idea.collaboratorOpen}
                          value="collaborator"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          size="large"
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {idea.collaborators.map(item => (
                          <Chip size="small"
                            label={item} style={{ margin: "10px" }}>
                            &times;
                          </Chip>
                        ))}
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        <CollaborateAnIdea data={true} open={idea.collaboratorOpen} />
                      </Grid>
                      <br />
                    </Grid>
                  </text>
                </CardContent>
              </CardContent>
            </Card>
          </Grid>
        </Feature>

        <Grid xs={12} md={12} style={{ overflow: 'visible', height: '100%' }} className={classes.item}>
          <Card className={classes.card} style={{ overflow: 'visible' }}>
            <CardContent style={{ paddingTop: '4px', paddingBottom: '4px' }}>
              <Grid container>
                <Feature name='show-feature'>
                  <Grid xs={12} md={6} style={{ marginRight: 10, marginLeft: 10 }}>
                    <Autocomplete
                      multiple
                      options={[]}
                      id="tags-standard"
                      value={idea.tags}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
                          variant="standard"
                          label="Tags"
                        />
                      )}
                    />
                  </Grid>
                </Feature>
                <Grid xs={12} md={5} style={{ marginRight: 10, marginLeft: 10 }}>
                  <div style={{ marginLeft: 8 }}>
                    <text>
                      <p style={{ marginBottom: '2px' }}>
                        <strong>Status:</strong>
                      </p>
                    </text>
                    <TextField id="standard-basic" fullWidth value={idea.status} inputProps={{ readOnly: true }} />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={12} md={6} style={{ marginRight: 10, marginLeft: 10 }}>
                  <Autocomplete
                    multiple
                    options={[]}
                    id="tags-readOnly"
                    value={idea.campaignGoals}
                    readOnly={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
                        variant="standard"
                        label="Campaign Goals"
                      />
                    )}
                  />
                </Grid>
                <Feature name='show-feature'>
                  <Grid xs={12} md={5} style={{ marginRight: 10, marginLeft: 10 }}>
                    <div style={{ marginLeft: 8 }}>
                      <text>
                        <p style={{ marginBottom: '2px' }}>
                          <strong>Your PS coins:<SettingsInputSvideoIcon className={classes.coin}></SettingsInputSvideoIcon></strong>
                        </p>
                      </text>
                      <TextField id="standard-basic" fullWidth defaultValue={0} inputProps={{ readOnly: true }} />
                    </div>
                  </Grid>
                </Feature>
              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} className={classes.item}>
          <Card className={classes.card} >
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <CardHeader className={classes.cardHead} title="Resources" />
              {idea.resources.map((res, index) => (
                <Grid container id={index} >
                  <Grid xs={12} md={3} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      label="Name"
                      value={idea.resources[index].name}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid xs={12} md={5} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      label="Description"
                      value={idea.resources[index].description}
                      inputProps={{ readOnly: true }}
                    /></Grid>
                  <Grid xs={12} md={4} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Link"
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      value={idea.resources[index].link}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card className={classes.card} >
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <CardHeader className={classes.cardHead} title="Details" />
              <Grid container>
                <Grid item xs={12} md={4}>
                  <text>
                    <p><strong>Author of Idea: </strong>{idea.author.username}</p>
                      {idea.author.userId === (userId) ? <Link to={{ pathname: '/editIdea/' + idea.ideaId }}><IconButton className={classes.editIcon}><Edit /></IconButton></Link> : <span />}
                  </text>
                </Grid>
                <Grid item xs={12} md={4}>
                  <text>
                    <p><strong>Idea Created On: </strong>{idea.timeCreated}</p>
                  </text>
                </Grid>
                <Feature name='show-feature'>
                  <Grid item xs={12} md={3}>
                    <Tooltip title="Views"><IconButton disabled={true}><Visibility style={{ color: 'black' }} /></IconButton></Tooltip>{idea.views.length}
                    <Tooltip style={{ backgroundColor: 'white', borderStyle: 'solid' }} title="Like"><IconButton onClick={handleLike} className={clsx(like.includes(userId) && classes.likecolor)} style={{ marginLeft: 15 }} ><ThumbUp /></IconButton></Tooltip>{like.length}
                    <Tooltip title="Comment"><IconButton className={classes.iconButtons} style={{ padding: 6 }}><CommentIcon /></IconButton></Tooltip>{idea.comments.length}
                    <br />
                    <Button color="primary" onClick={handleClickOpenComments}>
                      View comments
                    </Button>
                    <Dialog open={openComments} fullWidth onClose={handleCloseComments} aria-labelledby="form-dialog-title">
                      <DialogContent>
                        <div style={{ padding: 14 }} className="App">
                          <h1>Comments</h1>
                          <Paper style={{ padding: "40px 20px" }}>
                            {idea.comments.length > 0 ? idea.comments.map((comment, index) => (
                              <Grid container wrap="nowrap" spacing={2}>
                                <Grid item>
                                  <Avatar alt="Shashwat" />
                                </Grid>
                                <Grid justifyContent="left" item xs zeroMinWidth>
                                  <h4 style={{ margin: 0, textAlign: "left" }}>{comment.user}</h4>
                                  <p style={{ textAlign: "left" }}>
                                    {comment.comment}
                                  </p>
                                  <Chip
                                    size="small"
                                    icon={<ThumbUpIcon />}
                                    clickable
                                    label="Like"
                                    color="primary"
                                    className={classes.chips}
                                  />
                                  <p style={{ textAlign: "left", color: "gray" }}>
                                    posted 1 minute ago
                                  </p>
                                </Grid>
                                <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
                              </Grid>
                            )) : <p>No Comments. Be the first one to post a comment</p>}

                          </Paper>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </Grid>
                </Feature>
              </Grid>
            </CardContent>
          </Card>
        </Grid>



      </Grid>
    </div>
  )
    ;
}
