import React, { useEffect, useState } from 'react';
import { Grid, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PollIcon from '@material-ui/icons/Poll';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GroupIcon from '@material-ui/icons/Group';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import LineChart from './LineChart';
import abbreviate from 'number-abbreviate';
import axios from 'axios';
import Feature from '../../Feature';

const statsType =
{
    "activeIdeas": 0,
    "closedIdeas": 0,
    "campaigns": 0,
    "totalSponsorship": 0,
    "activeCollaborators": 0,
    "activeMentors": 0
}

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
    },
    chart: {
        width: '100%'
    },
    stats: {
        height: '100%',
        textAlign: 'center'
    },
    stats1: {
        height: '100%',
        color: 'white',
    },
    statsHeading: {
        fontSize: '1.2rem',
        paddingTop: '20px'
    },
    ideasHeading: {
        fontSize: '1.2rem'
    },
    statsNumber: {
        fontSize: '1.875rem'
    },
    div: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '48%',
        marginTop: '1.5%',
        marginBottom: '1%',
        marginLeft: 10,
        marginRight: 10,
        borderRadius: '5px',
        color: 'white',
    },
    icon: {
        height: '50px',
        width: '50px',
        opacity: 0.5,
        marginLeft: '10px',
    },
    grid: {
        height: '50%'
    }
}));


export default function Dashboard() {

    const classes = useStyles();
    const [stats, setStats] = useState(statsType);
    useEffect(() => {

        axios.get("/ideaGateway/ideaDashboardStats").then
            (response => {
                setStats(response.data);
            })
    }, []);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
                <Card className={classes.card}>
                    <div className={classes.chart}>
                        <LineChart />
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card className={classes.stats}>
                    <Link to="/ideas" style={{ textDecorationLine: 'none' }}>
                        <div className={classes.div} style={{ background: 'linear-gradient(230deg, #13b8a2, #00d4ff)', cursor: "pointer" }}>
                            <Typography className={classes.ideasHeading}>Active Ideas</Typography>
                            <Grid container justify="center">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography className={classes.statsNumber}>{abbreviate(stats.activeIdeas, 1)}</Typography>
                                    <EmojiObjectsIcon className={classes.icon} />
                                </div>
                            </Grid>
                        </div>
                        <div className={classes.div} style={{ background: 'linear-gradient(230deg, #13b8a2, #00d4ff)', cursor: "pointer" }}>
                            <Typography className={classes.ideasHeading}>Finished Ideas</Typography>
                            <Grid container justify="center">
                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                    <Typography className={classes.statsNumber}>{abbreviate(stats.closedIdeas, 1)}</Typography>
                                    <CheckCircleIcon className={classes.icon} />
                                </div>
                            </Grid>
                        </div>
                    </Link>
                </Card>
            </Grid>
            <Feature name='show-feature'>
                <Grid item xs={6} md={3}>
                    <Card className={classes.stats} style={{ height: '100%' }}>
                        <Link to="/campaigns" style={{ textDecorationLine: 'none' }}>
                            <div className={classes.stats1} style={{ background: 'linear-gradient(230deg, #fc5286, #fbaaa2)', cursor: "pointer" }}>
                                <Typography className={classes.statsHeading}>Total Campaigns Started</Typography>
                                <Grid container justify="center">
                                    <div style={{ alignItems: 'center', display: 'flex' }}>
                                        <Typography className={classes.statsNumber}>{abbreviate(stats.campaigns, 1)}</Typography>
                                        <PollIcon className={classes.icon} />
                                    </div>
                                </Grid>
                            </div>
                        </Link>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card className={classes.stats} style={{ height: '100%' }}>
                        <Link to="/sponsors" style={{ textDecorationLine: 'none' }}>
                            <div className={classes.stats1} style={{ background: 'linear-gradient(230deg, #123456, #00d4ff)', cursor: "pointer" }}>
                                <Typography className={classes.statsHeading}>Total Sponsorship Raised</Typography>
                                <Grid container justify="center">
                                    <div style={{ alignItems: 'center', display: 'flex' }}>
                                        <Typography className={classes.statsNumber}>{abbreviate(stats.totalSponsorship)}</Typography>
                                        <MonetizationOnIcon className={classes.icon} />
                                    </div>
                                </Grid>
                            </div>
                        </Link>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card className={classes.stats} style={{ height: '100%' }}>
                        <Link to="/mentors" style={{ textDecorationLine: 'none' }}>
                            <div className={classes.stats1} style={{ background: 'linear-gradient(230deg, #ff713b, #eea160)', cursor: "pointer" }}>
                                <Typography className={classes.statsHeading}>Active Mentors</Typography>
                                <Grid container justify="center">
                                    <div style={{ alignItems: 'center', display: 'flex' }}>
                                        <Typography className={classes.statsNumber}>{abbreviate(stats.activeMentors, 1)}</Typography>
                                        <GroupIcon className={classes.icon} />
                                    </div>
                                </Grid>
                            </div>
                        </Link>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card className={classes.stats} style={{ height: '100%' }}>
                        <Link to="/collaborators" style={{ textDecorationLine: 'none' }}>
                            <div className={classes.stats1} style={{ background: 'linear-gradient(230deg, #7e18d8, #a99ad7)', cursor: "pointer" }}>
                                <Typography className={classes.statsHeading}>Active Collaborators</Typography>
                                <Grid container justify="center">
                                    <div style={{ alignItems: 'center', display: 'flex' }}>
                                        <Typography className={classes.statsNumber}>{abbreviate(stats.activeCollaborators, 1)}</Typography>
                                        <GroupWorkIcon className={classes.icon} />
                                    </div>
                                </Grid>
                            </div>
                        </Link>
                    </Card>
                </Grid>
            </Feature>
        </Grid>
    )
}