import { createTheme } from '@material-ui/core/styles';

export const lightTheme = createTheme({
  palette: {
    type: 'light',   // Name of the theme
    primary: {
      main: '#3F51B7',
    },
    secondary: {
      main: '#65C5C7',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

export default lightTheme;
