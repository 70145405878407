import React from 'react';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  display:{
    display:'none',
  },
  collaboratorButton:{
    background:'#7e18d8',
    fontSize: '80%',
    color:'#ffffff',
  },
  reject:{
    background:'red',
    fontSize: '80%',
    color:'#ffffff',
  },
}));


export default function CollaboratorNotification(props){
    const classes=useStyles();
    let collab=props.collaborator;

  const handleCollaboratorRequestAccept=()=>{
    let user=props.sponsor.sponsor.userId;
    let idea=props.ideaId;
    collab.collaborator.ideas[0].accepted=true;
    collab.collaborator.ideas[0].active=true;
    axios.put('/collaboratorGateway/ideas/accept/'+user+','+idea+','+true);
  }

  const handleCollaboratorRequestDecline=()=>{
    let user=props.sponsor.sponsor.userId;
    let idea=props.ideaId;
    collab.collaborator.ideas[0].accepted=false;
    collab.collaborator.ideas[0].active=false;
    axios.put('/collaboratorGateway/ideas/accept/'+user+','+idea+','+false);
  }

    return( 
                <div>
                        <div id={props.collaborator.collaborator.userId} style={{padding:2}}>
                        <Card style={{paddingTop:5}}>
                          {collab.collaborator.ideas[0].active===true?
                            collab.collaborator.ideas[0].accepted===true?
                            <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.collaborator.userName} is now a  collaborator for {props.title}</text>
                              </CardContent>
                              :
                              <div>
                                <CardContent style={{paddingTop:2,paddingBottom:2}}>
                                <text>{props.collaborator.userName} wants to collaborate {props.title} in {props.collaborator.collaborator.ideas[0].contribution.toString()} areas</text>
                                </CardContent>
                                <CardActions style={{paddingTop:0,paddingBottom:0}} >
                                <Button onClick={handleCollaboratorRequestDecline} className={classes.reject}>Decline</Button>
                                <Button onClick={handleCollaboratorRequestAccept} className={classes.collaboratorButton}>Accept</Button>
                                </CardActions>
                              </div>
                            :
                            <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.collaborator.userName} request to collaborate {props.title} rejected</text>
                              </CardContent>
                            }
                        </Card>
                        </div>
              </div>
    )
}
