import React, { useEffect, useState } from 'react'
import { Grid, Card, CardHeader, CardContent, List, ListItem, ListItemText,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import axios from "axios"
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: '2px',
        height: '100%'
    },
    button: {
        margin: 0,
        padding: '2px',
    },
    card: {
        height: '120px',
        textAlign: 'center'
    },
    header: {
        paddingLeft: '20px',
        fontFamily: 'Trebuchet MS',
        fontSize: '2.2em'
    },
    link: {
        textDecorationLine: 'none'
    }
}));

export default function CollaboratorCell() {
    const classes = useStyles();
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
    const [ideas, setIdeas] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    useEffect(() => {
        
        axios.all([
            axios.get('/collaboratorGateway/get/collaboratorIdeaRecommendations/' + userId)
                .then(response => {
                    setRecommendations(response.data.slice(0,3));
                }),
            axios.get('/collaboratorGateway/get/collaboratorIdeas/' + userId)
                .then(response => {
                    setIdeas(response.data.slice(0,3));
                })
        ])
    }, []);

    return (
        <Grid item xs={12} md={6} className={classes.grid}>
            <Card>
                <Link to="/myCollaborations" className={classes.link}>
                    <CardHeader
                        title={`Collaborated Ideas: ${ideas.length}`}
                        titleTypographyProps={{ variant: 'h5' }}
                        style={{ background: '#7e18d8', color: 'white' }} />
                </Link>
                <CardContent style={{ display: 'flex', flexWrap: 'wrap', height: '350px', paddingBottom: '0px' }}>
                    <div style={{ width: '50%', height: '150px',padding:10 }}>
                        <h4 style={{ margin: 0 }}>Your Most Recent Ideas</h4>
                        <List dense={true}>
                            {ideas.length > 0 ?
                                (
                                    ideas.map(Idea => (
                                        <Link to={{ pathname: '/viewIdea/' + Idea.ideaId }} style={{ textDecorationLine: "none", color: 'black' }}>
                                            <ListItem button key={Idea.ideaId} style={{ borderBottomStyle: "solid", borderColor: '#e0e0e0', borderWidth: 2 }}>
                                                <ListItemText primary={Idea.title} />
                                            </ListItem>
                                        </Link>
                                    ))
                                ) :
                                (
                                    <Typography style={{ marginTop: 10, color: 'grey', fontWeight: 'bold' }}>
                                        You have no active collaborations
                                    </Typography>
                                )
                            }
                        </List>
                    </div>
                    <div style={{ width: '50%', padding:10 }}>
                        <b>Change your interest areas</b>
                        <Typography style={{ color: 'grey', fontWeight: 'bold', margin:20 }}>
                            Feature to be rolled out soon...Stay tuned..!
                        </Typography>
                    </div>
                    <div style={{ width: '100%', marginBottom: '0px'  }}>
                        <CardHeader
                            title="Ideas you can Collaborate with:"
                            style={{ padding: '5px', margin: '5px', height: '35px' }}
                            titleTypographyProps={{ variant: 'h6' }} />
                        <List dense={true}>
                            {recommendations.map(Idea => (
                                <Link to={{ pathname: '/viewIdea/' + Idea.ideaId }} style={{ textDecorationLine: "none", color: 'black' }}>
                                    <ListItem button key={Idea.ideaId} style={{ borderBottomStyle: "solid", borderColor: '#e0e0e0', borderWidth: 2 }}>
                                        <ListItemText primary={Idea.title} />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    )
}
