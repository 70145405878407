import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AccountMenu from './account-menu/AccountMenu';
import MobileModeMenu from './mobile-mode-menu/MobileMenu';
import Notifications from './notifications/Notifications';
import Operations from './operations/Operations';
import Theme from '../../../Main/theme/theme';
import './Topbar.scss';
import { useLocation } from 'react-router';
import Feature from '../../Feature';

const styles = theme => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        minHeight: '48px !important'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.primary.main
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
});

const mobileMenuId = 'primary-search-account-menu-mobile';
const withoutNavbarRoutes = ["/signUp", "/signIn","/reset"];
function Topbar({ isDrawerOpen, handleDrawerAction, classes }) {
    const { pathname } = useLocation();
    const [id, setId] = useState(null)

    //const classes = useStyles();
    let appInitData = '';
    useSelector(state => {
        appInitData = state.commonReducer.appInitData;
    })

    const [mobileModeMenuAnchorEl, setMobileModeMenuAnchorEl] = React.useState(null);
    useEffect(() => {
        setId(sessionStorage.getItem('id'))
    }, [])

    if (withoutNavbarRoutes.some((item) => pathname.includes(item))) return null;
    return (
        // <div className="topbar">
        <div className="topbar">
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar)}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    {id != null &&
                        <>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerAction}
                                className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerAction}
                                className={clsx(classes.menuButton, !isDrawerOpen && classes.menuButtonHidden)}
                                open={isDrawerOpen}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </>}

                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Innovation Hub
                    </Typography>

                    <div className={"topbar__grow"} />
                    <div className={classes.sectionDesktop}>
                        <Theme />
                        <Feature name='show-feature'>
                            < Notifications mentorDetails={appInitData.mentorDetails || []} sponsorDetails={appInitData.sponsorDetails || []} collaboratorDetails={appInitData.collaboratorDetails || []} />
                        </Feature>
                        <Operations appInitData={appInitData} />
                        <AccountMenu appInitData={appInitData} />
                    </div>
                    {/* <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={(event) => setAccountMenuAnchorEl(event.currentTarget)}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar>
            <MobileModeMenu
                isMobileMenuOpen={Boolean(mobileModeMenuAnchorEl)}
                handleMobileMenuClose={() => setMobileModeMenuAnchorEl(null)}
                mobileMoreAnchorEl={mobileModeMenuAnchorEl}
            />

        </div>
        // </div>
    )
}

export default withStyles(styles)(Topbar);
