import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '80%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const idea = [
'Draft',
'Submitted',
'In progress',
'MVP in progress',
'MVP complete',
'Solution in progress',
'Solution complete',
'Closed',
];

const campaign=[
  {status:'Draft',info:'Campaign is being drafted and is not available for participation'},
  {status:'Launched',info:'Campaign is now live and is open for new idea submission'},
  {status:'STAGE I',info:'Campaign is now in STAGE I and new ideas are not accepted'},
  {status:'STAGE II',info:'Campaign is now in STAGE II and new ideas are not accepted'},
  {status:'STAGE III',info:'Campaign is now in STAGE III and new ideas are not accepted'},
  {status:'STAGE IV',info:'Campaign is now in STAGE IV and new ideas are not accepted'},
  {status:'STAGE V',info:'Campaign is now in STAGE V and new ideas are not accepted'},
  {status:'Completed',info:'Campaign is now complete and new ideas are not accepted'},
  {status:'Dropped',info:'Campaign has been shelved and new ideas are not accepted'}
];

const statusInfo=[
  {status:'Draft',info:'If draft the idea is not visible for anyone, Change to new to publish'},
  {status:'Submitted',info:'If Submitted it will be published in new ideas'},
  {status:'In progress',info:'If idea is in the stage of searching for various possible solutions'},
  {status:'MVP in progress',info:'MVP in progress'},
  {status:'MVP complete',info:'MVP complete'},
  {status:'Solution in progress',info:'Development of solution in progress'},
  {status:'Solution complete',info:'Solution is developed and ready'},
  {status:'Closed',info:'No longer working on this Idea'}
];

const campaignIdeaStatusInfo=[
  {status:'Draft',info:'Draft the idea is not visible for anyone, Change to submitted to publish'},
  {status:'Submitted',info:'If Submitted it will be published in new ideas'},
  {status:'STAGE I',info:'Idea is in stage I'},
  {status:'STAGE II',info:'Idea is in stage II'},
  {status:'STAGE III',info:'Idea is in stage III'},
  {status:'STAGE IV',info:'Idea is in stage IV'},
  {status:'STAGE V',info:'Idea is in stage V'},
  {status:'Winner',info:'Idea is the winner'},
];

const campaignIdea=[
  "Draft",
  "Submitted",
  "STAGE I",
  "STAGE II",
  "STAGE III",
  "STAGE IV",
  "STAGE V",
  "Winner",
];


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function IdeaStatus(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
      <text >
        <strong >Status* </strong>
      </text>
        <Select
          value={props.data}
          disabled={props.disabled}
          onChange={props.handleChange('status')}
          input={<Input id="select-multiple" />}
          required
          MenuProps={MenuProps}
        >
          {statusInfo.map((res,i) => (
            <MenuItem key={i} value={res.status}>
              {res.status}
              <Tooltip  title={res.info}>
                <IconButton disableRipple  style={{ padding: 0, right: '0px', top: '-2px' }}><InfoOutlined style={{height:'18px',width:'18px', padding:'0',margin:'0'}}/></IconButton>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export  function CampaignStatus(props) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
      <text ><strong > Status*</strong> </text>
        <Select
          value={props.data}
          required
          onChange={props.handleChange('status')}
          input={<Input id="select-multiple" />}
          MenuProps={MenuProps}
          disabled={props.disabled}
        >
          {campaign.map((res,i) => (
            <MenuItem key={i} value={res.status}>
              {res.status}
              <Tooltip  title={res.info}>
                <IconButton disableRipple  style={{ padding: 0, right: '0px', top: '-2px' }}><InfoOutlined style={{height:'18px',width:'18px', padding:'0',margin:'0'}}/></IconButton>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export function CampaignIdeaStatus(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <text>
      <strong >Status* </strong>
      </text>
        <Select
          value={props.data}
          required
          disabled={props.disabled}
          onChange={props.handleChange('status')}
          input={<Input id="select-multiple" />}
          MenuProps={MenuProps}
        >
          {campaignIdeaStatusInfo.map((res,i) => (
            <MenuItem key={i} value={res.status}>
              {res.status}
              <Tooltip  title={res.info}>
                <IconButton disableRipple  style={{ padding: 0, right: '0px', top: '-2px' }}><InfoOutlined style={{height:'18px',width:'18px', padding:'0',margin:'0'}}/></IconButton>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
