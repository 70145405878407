import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Grid, TableHead, TableRow, withStyles, TableCell, TableBody, IconButton, TextField} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SponsorAnIdea from '../../components/IdeaLayout/View/SponsorAnIdea';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#123456',
        color: theme.palette.common.white,
        fontSize:'17px',
        textAlign:'left'
    },
    body: {
        maxWidth:'250px',
        fontSize: 15,
        textAlign:'left',
        padding:5,
        borderWidth:2,
    },
}))(TableCell);
 
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
             backgroundColor: '#ededed',
             maxWidth:'250'
        }
    }
}))(TableRow);


const IdeaTableStyle = makeStyles(theme => {
    return ({
        table: {
            minWidth: '50%',
            maxWidth: '100%',
            borderStyle: 'block',
            borderWidth: '1px'
        },
        actions: {
            height: '18px',
            width: '18px',
        },
        info: {
            padding: '10px',
        },
        hei:{
          marginTop:'6%',
          top:'6%',
          marginLeft:'10%',
          maxHeight:'87%',
          maxWidth:'80%',
        },

        title:{
          background:'#123456',
          width:'100%',
          color:'#ffffff',
          textAlign:'center',
        },
        popbutton:{
            color: 'white',
            marginTop: '8px',
            marginRight: '20px',
            borderColor: 'white'

        },
        textField: {
            minWidth: '36%',
            maxWidth: '100%'
        },
    });
})

export default function SponsorIdea(){
    const classes=IdeaTableStyle();
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
    const [ideas, setIdeas] = useState([]);
    const [filtered, setFiltered] = useState([])

    useEffect(() => {
        
        axios.get('/ideaGateway/get/dashboardIdeas/'+userId)
            .then(response => {
                setIdeas(response.data);
                setFiltered(response.data)
            });
    }, []);

    const filterlist = (event) => {
        let searchText = event.target.value;
        let Ideas = ideas;
        Ideas = Ideas.filter(Idea => {
            const Tags = Idea.tags;
            return ((Idea.title.toLowerCase().search(searchText.toLowerCase()) !== -1 || Tags.filter(tag => { return tag.toLowerCase().match(searchText.toLowerCase()) }).length > 0 || Idea.status.toLowerCase().search(searchText.toLowerCase()) !== -1) & (Idea.status !== "Draft"));
        });
        setFiltered(Ideas)
    }
    
    return( 
      <div>
      

        <Grid container>
            <Grid xs={12} md={4}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/" style={{ textDecorationLine: "none", color: '#373737' }}>
                    Dashboard
                  </Link>
                  <Typography color="textPrimary">Sponsor an Idea</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={4} style={{ margin:10, width: '100%' }}>
                        <TextField InputProps={{style:{height:40}}} style={{width:'100%'}} onChange={filterlist} variant="outlined" placeholder="Search using Title or tags or status" />
            </Grid>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Author</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Tags</StyledTableCell>
                        <StyledTableCell>No of Active Sponsors</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {filtered.filter(idea => { return (idea.sponsorOpen === true && idea.status!=="Draft" && idea.deleted!== true)}).map(idea => (
                    <StyledTableRow key={idea.IdeaId}>
                        <StyledTableCell >{idea.title}</StyledTableCell>
                        <StyledTableCell>{idea.username}</StyledTableCell>
                        <StyledTableCell>
                                <text>{(idea.description.length<73)?(idea.description):(idea.description.slice(0,75)+"...")}</text>
                                {(idea.description.length>73)?(<Tooltip title={idea.description}><IconButton disableRipple  style={{ padding: 0, position: 'relative', right: '0px', top: '0px' }}><InfoIcon /></IconButton></Tooltip>):null}
                            </StyledTableCell>
                        <StyledTableCell>
                        <text>{(idea.tags.length<=3)?(idea.tags.toString()):(idea.tags.slice(0,3)+"...")}</text>
                        {(idea.tags.length>3)?(<Tooltip title={idea.tags.toString()}><IconButton disableRipple  style={{ padding: 0, position: 'relative', right: '0px', top: '0px' }}><InfoIcon /></IconButton></Tooltip>):null}
                        </StyledTableCell>
                        <StyledTableCell style={{paddingLeft:70}}>{idea.sponsors}</StyledTableCell>
                        <StyledTableCell>{idea.status}</StyledTableCell>
                        <StyledTableCell>
                            <div style={{display:'flex',flexDirection:'row'}}>
                            <Tooltip title="Open Idea"><Link to={{ pathname: '/viewIdea/' + idea.ideaId }}><IconButton style={{padding:6}}><OpenInNewIcon/></IconButton></Link></Tooltip>
                            <SponsorAnIdea data={false} open={idea.sponsorOpen} className={classes.button} ideaId={idea.ideaId} /></div>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </Grid>
      </div>
    )
}
