import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Divider, Avatar, Grid, Paper, Chip, Tooltip, IconButton } from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Comment(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openComments, setOpenComments] = React.useState(false);
  const[comments,setComments]=React.useState([]);

  /*useEffect(() => {
    axios.get('/campaignGateway/viewCampaign/' + match.params.ideaId)
      .then(response => {
        setComments(response.data.comments);
      });
  }, []);*/


  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenComments = () => {
    setOpenComments(true);
  };

  const handleCloseComments = () => {
    setOpenComments(false);
  };

  return (
    <div>
       {/* {props.comments.length}  */}
       <Tooltip title="Comment">
                     <IconButton style={{ padding: 5 }}>
                     <CommentIcon onClick={handleClickOpen}/>
                     </IconButton>
                   </Tooltip>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Join the Discussion</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Add your comment"
            type="comment"
            onChange={props.commentHandle(149)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.commentPosthandle(props.keyVal-1)} color="primary">
            Post the comment
          </Button>
          <Button color="primary" onClick={handleClickOpenComments}>
            View Comments
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openComments} fullWidth onClose={handleCloseComments} aria-labelledby="form-dialog-title">
        <DialogContent>
        <div style={{ padding: 14 }} className="App">
      <h1>Comments</h1>
      {/* <Paper style={{ padding: "40px 20px" }}>
        {props.comments.length > 0 ? props.comments.map((comment,index) => (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar alt="Remy Sharp"/>
          </Grid>
          <Grid justifyContent="left" item xs zeroMinWidth>
            <h4 style={{ margin: 0, textAlign: "left" }}>Shashwat Awasthi</h4>
            <p style={{ textAlign: "left" }}>
              {comment.comment}
            </p>
            <Chip
                    size="small"
                    icon={<ThumbUpIcon />}
                    clickable
                    label="Like"
                    color="primary"
                    className={classes.chips}
                />
            <p style={{ textAlign: "left", color: "gray" }}>
              posted 1 minute ago
            </p>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
        </Grid>
        )) : <p>No Comments. Be the first one to post a comment</p>}
        
        </Paper> */}
        </div>
        </DialogContent>
        </Dialog>
    </div>
  );
}