import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconButton, TableBody, Tooltip } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddIcon from '@material-ui/icons/Add';
import CommentIcon from '@material-ui/icons/Comment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { BrowserRouter as Route, Link } from 'react-router-dom'
import clsx from 'clsx';
import CampaignComment from './View/CampaignComment';
import { useSelector } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import Feature from '../Feature';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                color: "black",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: 1
            }
        }
    }
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#ff334c',
        color: 'white',
        fontSize: '0.9em',
        textAlign: 'center',
        padding: 10
    },
    body: {
        padding: 8,
        textAlign: 'center'
    }
}))(TableCell);


const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#ededed'
        }
    }
}))(TableRow);

const CampaignStyles = makeStyles(theme => {
    return ({
        likecolor: {
            color: '#1b6deb',
        },
    });
})
const campaignType = {
    "campaignId": "",
    "awards": [
        {
            "title": "",
            "value": ""
        }
    ],
    "campaignCoreTeamMembers": [],
    "comments": [
        {
            "user": "",
            "comments": ""
        }
    ],
    "deadline": "2019-12-10T08:46:33.638Z",
    "deleted": false,
    "description": "",
    "goals": [
        {
            "deleted": false,
            "description": "",
            "industry": "",
            "goalId": "",
            "ideas": [
                {
                    "collaboratorOpen": true,
                    "deadline": "2019-12-10T08:46:33.638Z",
                    "deleted": false,
                    "description": "",
                    "ideaId": "",
                    "likes": [],
                    "mentorOpen": true,
                    "sponsorOpen": true,
                    "status": "",
                    "tags": [],
                    "timeCreated": "2019-12-10T08:46:33.638Z",
                    "title": "",
                    "userId": "",
                    "username": "",
                    "views": []
                }
            ],
            "timeCreated": "2019-12-10T08:46:33.638Z",
            "timeEdited": "2019-12-10T08:46:33.638Z",
            "title": ""
        }
    ],
    "stages":
        [{
            "currentStage": "",
            "stageMilestone": "",
            "stageCriteria": "",
            "stageSummary": "",
            "stageStart": "",
            "stageEnd": ""
        }],
    "likes": [],
    "progress": 0,
    "rewards": [],
    "rules": [],
    "status": "",
    "timeCreated": "2019-12-10T08:46:33.638Z",
    "timeEdited": "2019-12-10T08:46:33.638Z",
    "startCampaignDate": "",
    "endCampaignDate": "",
    "title": "",
    "userId": "",
    "userName": "",
    "visibility": []
}
const stageType = {
    "currentStage": "",
    "stageMilestone": "",
    "stageCriteria": "",
    "stageSummary": "",
    "stageStart": "",
    "stageEnd": ""
}

export default function CampaignLayout(props) {
    const classes = CampaignStyles();
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const [campaign, setCampaign] = useState(props.campaign);
    const [goalTitle, setGoalTitle] = useState(campaignType);
    const [stage, setStage] = useState(stageType);
    const [date, setdate] = useState();
    const [ideaCollaborator, setIdeaCollaborator] = useState([]);
    console.log("*****campaign", campaign)
    const [like, setLike] = React.useState(campaign.likes);

    const handleLike = () => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        if (!like.includes(userId)) {
            let temp = like.concat(userId);
            setLike(temp);
            var axios = require('axios');

            var config = {
                method: 'put',
                url: `/campaignGateway/addLike/${campaign.campaignId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })

        }
        else {
            let temp = like.filter(user => user !== userId);
            setLike(temp);
            var axios = require('axios');

            var config = {
                method: 'put',
                url: `/campaignGateway/deleteLike/${campaign.campaignId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })

        }


    }
    useEffect(() => {
        axios.get('/campaignGateway/viewCampaign/' + props.campaign.campaignId)
            .then(response => {
                setGoalTitle(response.data)
            });
        setStage(campaign.stages)
        campaign.stages.map(stg => {
            if (stg.currentStage == campaign.status) {
                setdate(stg.stageEnd)
            }
        })
        campaign.ideas.map(item => {

            axios.get('/ideaGateway/viewIdea/' + item)
                .then(response => {
                    setIdeaCollaborator(response.data.ideaCollaborator)
                });
        })
    }, [props.campaign.campaignId])


    return (
        <StyledTableRow key={props.campaign._id}>
            <StyledTableCell><Link to={{ pathname: '/viewCampaign/' + props.campaign.campaignId }}>{props.campaign.title}</Link>
                <br />Owner: <strong>{campaign.userName}</strong></StyledTableCell>
            <StyledTableCell>{goalTitle.goals.map((goal, index) =>
                <p style={{ margin: 0 }}>Goal{index + 1}:{goal.title}</p>
            )}</StyledTableCell>
            <StyledTableCell>{props.campaign.status}</StyledTableCell>
            <StyledTableCell>{props.campaign.stages.map((element, index) => {

                if (element.currentStage == props.campaign.status) {
                    return <p style={{ margin: 0 }}> {moment(element.stageEnd).utc().format('MM/DD/YYYY').toString()}</p>
                }
                
            })}</StyledTableCell>


            <StyledTableCell>
                # of Ideas: <strong>{props.campaign.ideas.length}</strong><br />
                <Feature name='show-feature'>
                    # of Collaborators: <strong>{ideaCollaborator.length}</strong><br />
                    PS Coins:{props.campaign.pscoins}
                </Feature>
            </StyledTableCell>
            <Feature name='show-feature'>
                <StyledTableCell>
                    {like.length}<Tooltip title="Like"><IconButton style={{ padding: 6 }} onClick={handleLike} className={clsx(classes.iconButtons, (like.includes(userId) && classes.likecolor))} ><ThumbUpAltIcon /></IconButton></Tooltip>
                    <CampaignComment comments={campaign.comments} commentHandle={props.handleComment} commentPosthandle={props.handlePostComment} keyVal={props.keyVal} />
                </StyledTableCell>
            </Feature>
        </StyledTableRow>
    )
}