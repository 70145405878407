import React from 'react';
import {applyMiddleware, } from 'redux';

import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import reducer from './store/reducers/reducer';
import { Provider, useSelector} from 'react-redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import NetworkManager from './NetworkManager';
import { RequestInterceptors, ResponseInterceptors } from './InterceptorConfig';
import LocalStorage from './localStorageService';
import { LOCAL_STORAGE_KEY_TOKEN } from './constants/GeneralConstants';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import store from './app/store';

const theme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                color: "black",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: 1
            }
        }
    },
    typography: {
        fontFamily: [
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
});

// NetworkManager.defaultConfig.baseURL = (window).CONFIG.API_BASE_URL;
// NetworkManager.defaultConfig.headers.post['Content-Type'] = 'application/json';
// // NetworkManager.defaultConfig.withCredentials = true;

// NetworkManager.useRequestMiddleware(RequestInterceptors.preProcessReq, RequestInterceptors.preProcessReqError);
// NetworkManager.useResponseMiddleware(ResponseInterceptors.handleSuccess, ResponseInterceptors.handleError);

const token = (new URL(window.location)).searchParams.get('token') || LocalStorage[LOCAL_STORAGE_KEY_TOKEN];

LocalStorage[LOCAL_STORAGE_KEY_TOKEN] = token;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.get['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
axios.interceptors.request.use(function (config){
    const token=sessionStorage.getItem('token');
    if(token){
        config.headers.Authorization=`Bearer ${sessionStorage.getItem('token')}`;
    }
    return config;
});


axios.interceptors.request.use(RequestInterceptors.preProcessReq, RequestInterceptors.preProcessReqError);


axios.interceptors.response.use(ResponseInterceptors.handleSuccess, ResponseInterceptors.handleError);

// const theme1 = useSelector(state => state.themeReducer.theme);
// console.log("themeeeee", theme1)
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.register();

