import React from 'react';import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Button, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg';
import  {EditorState, convertFromRaw, convertToRaw}  from 'draft-js';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popbutton:{
    fontSize: '20px',
    color:'#F06543',
    marginLeft:'10px',
    marginRight:'10px',
    backgroundColor: 'white'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3),
  },
}));

export default function RulesTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <div>
      <Button variant="contained" onClick={handleOpen} class={classes.popbutton} >Rules of Campaign</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        style={{position:'absolute',
        top:'10%',
        left:'10%',
        overflow:'scroll',
        height:'100%',
        display:'block'}}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <div className={classes.paper}>
          <Grid container spacing={1} style={{ padding: '10px' }}>
                    <Grid item xs={12} justify="center" style={{overflowX: 'auto'}}>
                        <Table >
                            <TableHead>
                                <TableRow style={{ fontSize: '150%' }}>
                                    <TableCell>Rule No.</TableCell>
                                    <TableCell>Rule Description</TableCell>
                                </TableRow>
                                {props.rules.map((rule,index) => (
                                <TableRow key={index}>
                                   <TableCell>{index+1}</TableCell>
                                    <TableCell>{rule}</TableCell>
                                </TableRow>
                                ))}
                                </TableHead>
                                </Table>
                                </Grid>
                                </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}