import React, { useEffect } from 'react'; import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import moment from 'moment';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Button, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CardContent from '@material-ui/core/CardContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    background: '#00d4ff',
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
  },
}));

export default function ViewGoal(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editorStateGoal, setEditorStateGoal] = React.useState(() => EditorState.createEmpty())

  const handleOpen = () => {

    setOpen(true);
    props.setModalState(true)
  }

  useEffect(() => {

    if (props.modalState == false) {
      handleClose();
    }
  }, [props.modalState])

  const handleClose = () => {

    setOpen(false);

  };
  const handleGoalDescriptionText = (editorStateGoal) => {
    setEditorStateGoal(editorStateGoal)
    const contentState = editorStateGoal.getCurrentContent();
    const val = JSON.stringify(convertToRaw(contentState));
    props.setGoalData({ ...props.goalData, ['description']: val });
  }

  return (
    <div>
      <Tooltip title="View goal"><IconButton style={{ padding: 8 }}><OpenInNewIcon onClick={handleOpen} style={{ color: 'rebeccapurple' }} className={classes.actions} /></IconButton></Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        style={{
          position: 'absolute',
          top: '10%',
          left: '20%',
          right:'20%',
          height: '100%',
          display: 'block'
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid item className={classes.title}>
              <DialogTitle id="form-dialog-title"><span style={{ fontSize: '35px' }}>View Goal </span></DialogTitle>
            </Grid>
            <CardContent style={{ paddingTop: '5px', paddingBottom: '2px' }}>
              <Grid container md={12}>
                <Grid xs={12} md={6} style={{ padding: '0.3%' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label="Industry"
                    defaultValue={props.goal.industry}
                    multiline="false"
                    style={{ marginTop: '5px', marginBottom: '5px', width: "350px"  }}
                    onChange={props.handleGoalTitleText}
                  />
                </Grid>
                <Grid xs={12} md={6} style={{ padding: '0.3%' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    inputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    label="Title"
                    defaultValue={props.goal.title}
                    multiline="false"
                    style={{ marginTop: '5px', marginBottom: '5px' }}
                    onChange={props.handleGoalTitleText}
                  />
                </Grid>
                <Grid xs={12} md={11} style={{ padding: '0.3%' }}>
                  <Editor
                    name="description"
                    toolbar={{
                      options: []
                    }}
                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(props.goal.description)))}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={props.handleGoalDescriptionText}
                    placeholder=" Enter a brief description about your Goal*"
                  />
                </Grid>
              </Grid>
              {/* <Button style={{ marginTop: "10px", marginLeft: "285px", marginBottom: "10px" }} variant="contained" onClick={props.handleAddGoalNew('goalData')} >Add </Button> */}
              <Button style={{ marginTop: "10px", marginLeft: "325px", marginBottom: "10px" }} variant="contained" onClick={handleClose} >Close</Button>
            </CardContent>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}