import * as Redux from 'redux';
import ReduxThunk from 'redux-thunk';
import appReducer from '../store/reducers/reducer';
import themeReducer from '../Main/theme/redux/reducer';
import commonReducer from '../components/app-layout/redux/reducer';
import { routerReducer } from 'react-router-redux'

const reducer = Redux.combineReducers({
    // Combine reducers of all the screens here
    route: routerReducer,
    appReducer: appReducer,
    themeReducer: themeReducer,
    commonReducer: commonReducer
});

let middlewares = [ReduxThunk];

if (process.env.NODE_ENV === `development`) {
  const { createLogger  } = require(`redux-logger`);

  const logger = createLogger();
  middlewares.push(logger);
}

const store = Redux.createStore(reducer, Redux.applyMiddleware(...middlewares));

export default store;
