import React from 'react';
import { Grid } from '@material-ui/core';
import Idea from './Idea';

export default function IdeaLayoutGridView(props) {
    
    const filtered = props.data;
    let val=1;
    
    return (
        <Grid container spacing={1} id="IdeaViewGrid" justify="flex-start" style={{ width: '100%' }}>
            {filtered.filter(idea => { return (idea.status !== "Draft" && idea.deleted !== true )}).map((idea,index) => (
                <Idea key={index} data={idea} keyVal={val++} commentHandle={props.commentvalue} commentPosthandle={props.postcomment}/>
            ))}
        </Grid>
    )
}