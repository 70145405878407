import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {JustTagDropdown} from '../../Dropdowns/Dropdowns';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import PeopleIcon from '@material-ui/icons/People';
import IconButton from '@material-ui/core/IconButton';
import * as actions from '../../../store/actions/index'
import { useSelector } from 'react-redux';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const useStyles=makeStyles(theme => ({

  popbutton:{
    fontSize: '80%',
        color: '#ff713b',
        marginLeft:'2px',
        fontWeight:'bold',
  },
  button:{
    color:'#ff713b',
    padding:6
  },
  view:{
    display:'none',
  },
}));

export default function MentorAnIdea(props) {
  const classes=useStyles();
  let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
  const [open, setOpen] = React.useState(false);
  const [registor, setRegistor] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [mentorList,setMentorList]=React.useState([]);
  const [mentor,setMentor]=React.useState({
  accepted: true,
  active: true,
  contribution: [],
  ideaId:props.ideaId,
  information: [],
  timeLog: [
    {
      timeCreated: "2019-11-20T06:09:16.928Z",
      timeEdited: "2019-11-20T06:09:16.928Z"
    }
  ]
});

  const [newMentor,setNewMentor]=React.useState({
  active:true,
  ideas:[],
  proficiencies:[],
  score:0,
  userId:userId
 });

  const handleRegistorTags=(e,value)=>
  { 
    setNewMentor({ ...newMentor, ['proficiencies']: value });
  }

  React.useEffect(() => {
    
    axios.get('/mentorGateway/get/Mentors/MentorLayout/'+userId)
      .then(response => {
        setMentorList(response.data)
        console.log(response.data)
      });
  }, []);
  let idea='';
  const handleClickOpen = () => {
    if(mentorList.filter(r=>r.mentor.userId===userId).length!==0)
    {
      let ment=mentorList.filter(r=>r.mentor.userId===userId);
      if(ment[0].mentor.ideas.filter(w=>w.ideaId===props.ideaId).length!==0)
      {
        idea=ment[0].mentor.ideas.filter(w=>w.ideaId===props.ideaId);
        setVisible(true);
      }
      setOpen(true);
    }
    else
    {
      setRegistor(true);
    }
  };

  const handleRegistorClose=()=>{
    let value=[];
    setNewMentor({ ...newMentor, ['proficiencies']: value });
    setRegistor(false);
  }

  const handleRegistor=()=>{
    let axiosConfig = {
      headers: {
      //   'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    axios.post('/mentorGateway/registerNew', newMentor,axiosConfig)
      .then(response =>{
        setRegistor(false);
        setOpen(true);
        })
  }

  const handleTags=(e,value)=>{
    setMentor({ ...mentor, ['contribution']: value });
  }

  const handleClose = () => {
    setOpen(false);
  }; 

  const handleMentor=()=>{
    axios.put('/mentorGateway/addIdea/'+userId, mentor)
      .then(response =>{
        console.log(mentor);
        handleClose();
        })
  }

  return (
    <div>
      <IconButton className={clsx(classes.button,(props.data && classes.view))} onClick={handleClickOpen} disabled={! props.open}>
          <Tooltip title="Mentor this Idea"><PeopleIcon /></Tooltip>
                            </IconButton>
      <Button variant="outlined" className={clsx(classes.popbutton, !props.data && classes.view)}  onClick={handleClickOpen} disabled={! props.open}>
      Mentor this idea
      </Button>
      <Dialog open={open} onClose={handleClose} stle={{padding:0,marginBottom:0}} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle  stle={{padding:'0'}}>Mentor</DialogTitle>
        <DialogContent style={{marginTop:'0', paddingTop:0}}>
          <TextField
            margin="dense"
            id="name"
            label="Why do you want to mentor this idea"
            type="email"
            fullWidth
          />
          <Autocomplete
        multiple
        options={props.tags}
        filterSelectedOptions
        getOptionLabel={option => option}
        style={{padding:0,margin:0}}
        onChange={handleTags}
        autoHighlight={true}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Areas you would like to mentor"
            style={{margin:0,padding:0,paddingBottom:'1px'}}
            margin="normal"
            
          />
        )}
      />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMentor} color="primary">
            Mentor
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={registor} onClose={handleRegistorClose} stle={{padding:0,marginBottom:0}} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle  stle={{padding:'0'}}>Please Register as a Mentor first</DialogTitle>
        <DialogContent style={{marginTop:'0', paddingTop:0}}>
          <JustTagDropdown data={'Select the areas you would like to Mentor'} selected={newMentor.proficiencies} handleDropdown={handleRegistorTags} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRegistorClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRegistor} color="primary">
            Register
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}