import React from 'react';
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab } from '@material-ui/core'
import Overview from './Overview/Overview'
import Dashboard from './Dashboard/Dashboard';


function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}



export default function UserDashboard() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: '#f0f0f0', color: 'black' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs"
                >
                    <Tab label="Dashboard" {...a11yProps(0)}></Tab>
                    <Tab label="My Overview" {...a11yProps(1)}></Tab>
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} style={{ padding: '10px' }}>
                <Dashboard />
            </TabPanel>

            <TabPanel value={value} index={1} style={{ padding: '10px' }}>
                <Overview />
            </TabPanel>
        </div>
    )
}

