import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert';



const SignOut = () => {
    const history = useHistory();
    useEffect(() => {
        sessionStorage.clear('token')
        sessionStorage.clear('id')
        history.push('/signIn');
    },[])

    return (
        <></>
    )
}

export default SignOut