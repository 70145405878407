import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from '@material-ui/core/Switch';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});


const tags = [
  'Artificial Intelligence', 'React JS', 'HTML', 'CSS', 'MY SQL', 'Mongo Db', 'Spring Boot', 'Maven', 'Python', 'C++', 'Ruby', 'Content Writing', 'Eclipse', 'Git', 'Machine Learning',
  'Deep Learning', 'Web Development', 'App Development', 'Game Developer', 'PHP', 'JavaScript', 'Blockchain', 'TensorFlow', 'Angular Js 4', 'Node js', 'Big Data Analytics',
  'Hadoop', 'SAS', 'MariaDb', 'AWS', 'Ajax', 'Bootstrap', 'Django', 'FireBase', 'Java', 'Redux'];

const users = ['Prasant Poddar', 'Achin Chawla', 'Lakshay Joshi', 'Naveen Chandola', 'Simgamsetty Srikar', 'Sreekara Reddy', 'Puneet Mishra'];

const goalsType = [{
  "title": ""
}]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: 80,
    minWidth: 290,
  },
  divider: {
    height: theme.spacing(1),
  },
}));

export function CampaignIdeaDropdown(props) {
  const classes = useStyles();

  const [goals, setGoals] = useState(goalsType)

  useEffect(() => {
    

    axios.get('/goals')
      .then(response => {
        setGoals(response.data)
      });
  }, []);

  const temp = []
  goals.map((item) => {
    temp.push(item.title)
  })


  return (
    <div className={classes.root}>
      <div className={classes.divider} />
      <text><strong> {props.data} </strong><Tooltip title={props.tooltip}><IconButton disableRipple style={{ padding: 0, right: '0px', top: '-2px' }}><InfoOutlined style={{ height: '18px', width: '18px', padding: '0', margin: '0' }} /></IconButton></Tooltip></text>
      <Autocomplete
        multiple
        id="tags-readOnly"
        options={[]}
        style={{ padding: 0, marginTop: -22 }}
        readOnly
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            style={{ opacity: 0.5, pointerEvents: "none" }}
            margin="normal"
            InputProps={{ ...params.InputProps, readOnly: true, }}
          />
        )}
      />

    </div>
  );

}

export function TagDropdown(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div className={classes.divider} />
      <text><strong> {props.data} </strong><Tooltip title={props.tooltip}><IconButton disableRipple style={{ padding: 0, right: '0px', top: '-2px' }}><InfoOutlined style={{ height: '18px', width: '18px', padding: '0', margin: '0' }} /></IconButton></Tooltip></text>
      <Autocomplete
        multiple
        options={tags}
        filterSelectedOptions
        style={{ padding: 0, margin: 0 }}
        value={props.selected}
        onChange={props.handle}
        autoHighlight={true}
        freeSolo
        disabled={props.disabled}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
            margin="normal"
            error={props.mssg && (props.selected.length === 0)}
          />
        )}
      />
    </div>
  );
}


export function UserDropdown(props) {
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.divider} />
      <text>{props.data}</text>
      <Tooltip title="Enable to invite">
        <Switch
          checked={props.open}
          onChange={props.handleSwitchChange(props.data)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        /></Tooltip>

      <Autocomplete
        multiple
        options={props.options}
        getOptionLabel={option => option.userName}
        filterSelectedOptions
        style={{ padding: 0, margin: 0 }}
        value={props.selected}
        onChange={props.handleDropdown}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
            margin="normal"
          />
        )}
      />
    </div>
  );
}

export function JustUserDropdown(props) {
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.divider} />
      <text>{props.data}</text>
      <Autocomplete
        multiple
        options={users}
        getOptionLabel={option => option}
        filterSelectedOptions
        style={{ padding: 0, margin: 0 }}
        value={props.selected}
        onChange={props.handleDropdown}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
            margin="normal"
          />
        )}
      />
    </div>
  );
}

export function JustTagDropdown(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.divider} />
      <text>{props.data} </text>
      <Autocomplete
        multiple
        options={tags}
        getOptionLabel={option => option}
        filterSelectedOptions
        style={{ padding: 0, margin: 0 }}
        value={props.selected}
        onChange={props.handleDropdown}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
            margin="normal"
          />
        )}
      />
    </div>
  );
}

export function originIndustry(props) {

  return (
    <div></div>
  );

}

export function CampaignDropdown(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>

      <div className={classes.divider} />
      <text><strong> {props.data} </strong><Tooltip title={props.tooltip}><IconButton disableRipple style={{ padding: 0, right: '0px', top: '-2px' }}><InfoOutlined style={{ height: '18px', width: '18px', padding: '0', margin: '0' }} /></IconButton></Tooltip></text>

      <Autocomplete
        multiple
        id="tags-readOnly"
        options={props.goal.map((option) => option.title)}
        value={props.selected}
        onChange={props.handle}
        style={{ padding: 0, marginTop:-7 }}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            style={{ margin: 0, padding: 0, paddingBottom: '1px' }}
            margin="normal"
            error={props.mssg && (props.selected.length === 0)}
          />
        )}
      />
    </div>
  );

}
