import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Links from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import * as actions from '../../store/actions/index'
import reducer from '../../store/reducers/reducer'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert';
import AuthContext from '../../store/auth-context';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © Designed & Developed by Publicis Sapient '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles(theme => ({

  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));



export default function ForgotPassword(props) {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    userId: '',
    otp: '',
    password: '',
  });
  const [mssg, setMssg] = useState(false)
  const [messageData,setMessageData] = useState("Please fill out the fields with * mark")

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
    if (mssg === true) {
      setMssg(false);
    }
  };

  const resetPassword = () => {
    if (state.password.length !== 0 && state.userId.length !== 0) {
      var axios = require('axios');
      var data = JSON.stringify({
        "userId": state.userId,
        "password": state.password,
        "otp": state.otp
      });

      var config = {
        method: 'post',
        url: '/userGateway/forgot/user',
        headers: { 'Content-Type': 'application/json' },
        data: data,

      };

      axios(config)
        .then(function (response) {
          history.push("/signIn")
        })
        .catch(function (error) {
          setMessageData(error.response.data)
          setMssg(true)
        });
    }
    else {
      setMessageData("Please fill out the fields with * mark")
      setMssg(true);
    }
  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <br></br>
        <Grid xs={12} className={clsx(!mssg && classes.display)}>
          <text style={{ color: 'black' }}><strong>Kindly contact the admin for Secret Key</strong></text>
        </Grid>
        {mssg == true &&
          <Grid xs={12} className={clsx(!mssg && classes.display)}>
            <text style={{ color: 'red' }}><strong>{messageData}</strong></text>
          </Grid>}
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={mssg && (state.userId.length === 0)}
            id="userId"
            label="User ID"
            name="uerid"
            autoComplete="userid"
            autoFocus
            onChange={handleChange('userId')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={mssg}
            id="otp"
            label="Secret Key"
            name="otp"
            autoComplete="otp"
            autoFocus
            onChange={handleChange('otp')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            error={mssg}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange('password')}
          />
          <Link style={{ textDecorationLine: "none", color: '#373737' }}>
            <Button
              type="submit"
              fullWidth
              required
              error={mssg && (state.password.length === 0)}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!((state.userId != '') && (state.password != '')  && (state.otp != ''))}
              onClick={resetPassword}
            >
              Reset Password
            </Button></Link>
        </form>
        <Grid item>
              <Link  to="/signIn" style={{marginLeft:"180px"}}>
                Already have an account? Sign in
              </Link>
        </Grid>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}