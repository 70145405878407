import React from 'react';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles(theme => ({
  display:{
    display:'none',
  },
  mentorButton:{
    background:'#ff713b',
    fontSize: '80%',
    color:'#ffffff',
  },
  reject:{
    background:'red',
    fontSize: '80%',
    color:'#ffffff',
  },
}));

export default function MentorNotification(props){
  const classes=useStyles();
  let men=props.mentor;
  
  const handleMentorRequestAccept=()=>{
    let user=props.mentor.mentor.userId;
    let idea=props.ideaId;
    men.mentor.ideas[0].accepted=true;
    men.mentor.ideas[0].active=true;
    console.log(men.mentor.ideas[0].accepted)   
    axios.put('/mentorGateway/ideas/accept/'+user+','+idea+','+true);
  }

  const handleMentorRequestDecline=()=>{
    let user=props.mentor.mentor.userId;
    let idea=props.ideaId;
    men.mentor.ideas[0].accepted=false;
    men.mentor.ideas[0].active=false;
    axios.put('/mentorGateway/ideas/accept/'+user+','+idea+','+false);
  }

    return( 
                <div>
                        <div id={props.mentor.mentor.userId} style={{padding:2}}>
                        <Card style={{paddingTop:5}}>
                          {men.mentor.ideas[0].active===true?
                            men.mentor.ideas[0].accepted===true?
                            <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.mentor.userName} is now a  mentor for {props.title}</text>
                              </CardContent>
                              :
                              <div>
                              <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.mentor.userName} wants to mentor {props.title} in {props.mentor.mentor.ideas[0].contribution.toString()} areas</text>
                              </CardContent>
                              <CardActions style={{paddingTop:0,paddingBottom:0}} >
                              <Button onClick={handleMentorRequestDecline} className={classes.reject}>Decline</Button>
                              <Button onClick={handleMentorRequestAccept} className={classes.mentorButton}>Accept</Button>
                              </CardActions>
                              </div>
                            :
                            <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.mentor.userName} request to mentor {props.title} rejected</text>
                              </CardContent>
                            }
                        </Card>
                        </div>
              </div>
    )
}
