import React, { useState} from 'react';
import clsx from 'clsx';
import {TableRow, withStyles, TableCell, IconButton, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios'
import InfoIcon from '@material-ui/icons/Info';
import MonetizationOnSharp from '@material-ui/icons/MonetizationOnSharp';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { BrowserRouter as Route, Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Feature from '../../components/Feature';
import { useHistory } from 'react-router-dom'

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#13b8a2',
        color: theme.palette.common.white,
        fontSize:'17px',
        textAlign:'left'
    },
    body: {
        maxWidth:'100%',
        fontSize: 15,
        textAlign:'left',
        padding:5,
        paddingLeft:7,
        borderWidth:2
    }
}))(TableCell);
 
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
             backgroundColor: '#ededed',
        }
    }
}))(TableRow);

const MyIdeasTableStyle = makeStyles(theme => ({
    table:{
        width:'100%',
        borderStyle:'block',
        borderWidth:'1px'
    },
    actions:{
        height:'18px',
        width:'18px',
    },
    sponsorInvite:{
        color:'#123456'
    },
    mentorInvite:{
        color:'#ff713b'
    },
    collaboratorInvite:{
        color:'#7e18d8'
    },
    closed:{
        color:'#a0a0a0'
    }
}))


export default function MyIdeasTableRow(props){
    const history = useHistory();
    const classes=MyIdeasTableStyle();
    const [row, setRow] = useState(props.idea);
    const [remove, setRemove] = React.useState(false);

    const handleRemove = () => {
        setRemove(true);
    };

    const handleRemoveClose = () => {
     setRemove(false);
    };

    const handleDelete =()=>{
        var axios = require('axios');
        var config = {
            method: 'put',
            url: '/ideaGateway/deleteIdea/'+row.ideaId,
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
          };

          axios(config)
          .then(function (response) {
            handleRemoveClose();
            history.push('/myIdeas');
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
        }
    

    const handleSponsorToggle=()=>{
        let temp=!row.sponsorOpen;
        setRow({ ...row, ['sponsorOpen']: temp });
        console.log(props)
        axios.put('/ideaGateway/editAssociate/'+row.ideaId+'/sponsor/'+temp);
    }

    const handleMentorToggle=()=>{
        let temp=!row.mentorOpen;
        setRow({ ...row, ['mentorOpen']: temp });
        axios.put('/ideaGateway/editAssociate/'+row.ideaId+'/mentor/'+temp);
    }

    const handleCollaboratorToggle=()=>{
        let temp=!row.collaboratorOpen;
        setRow({ ...row, ['collaboratorOpen']: temp });
        axios.put('/ideaGateway/editAssociate/'+row.ideaId+'/collaborator/'+temp);
    }

    return( 
        <StyledTableRow key={row.ideaId}>
            <StyledTableCell component="th" scope="row">{row.title}</StyledTableCell>
            <StyledTableCell component="th" scope="row">{row.status}</StyledTableCell>
            <StyledTableCell component="th" scope="row" style={{maxWidth:300}}>
                <text>{row.targetIndustries}</text>
            </StyledTableCell> 
            <Feature name='show-feature'>
            <StyledTableCell component="th" scope="row">{row.tags}</StyledTableCell>
            <StyledTableCell component="th" scope="row" style={{ minWidth: 150 }}>
                <Tooltip  title={row.sponsorOpen?"Click to disable Sponsors invites":"Click to enable Sponsor invites"}><IconButton onClick={handleSponsorToggle} style={{padding:6}}  className={clsx(classes.sponsorInvite,(!row.sponsorOpen&&classes.closed))}><MonetizationOnSharp/></IconButton></Tooltip>
                <Tooltip title={row.mentorOpen?"Click to disable Mentor invites":"Click to enable Mentor invites"}><IconButton onClick={handleMentorToggle} style={{padding:6}}  className={clsx(classes.mentorInvite,(!row.mentorOpen&&classes.closed))}><PeopleIcon/></IconButton></Tooltip>
                <Tooltip title={row.collaboratorOpen?"Click to disable Collaborator invites":"Click to enable Collaborator invites"}><IconButton onClick={handleCollaboratorToggle} style={{padding:6}}  className={clsx(classes.collaboratorInvite,(!row.collaboratorOpen&&classes.closed))}><GroupWorkIcon/></IconButton></Tooltip>
            </StyledTableCell>
            </Feature>
            <StyledTableCell component="th" style={{minWidth:150}}>
                <Tooltip title="View idea"><Link to={{ pathname: '/viewIdea/' + row.ideaId }}><IconButton style={{padding:8}}><OpenInNewIcon style={{color: 'rebeccapurple'}} className={classes.actions}/></IconButton></Link></Tooltip>
                <Tooltip title="Edit"><Link to={{ pathname: '/editIdea/' + row.ideaId }}><IconButton  style={{padding:8}}><EditIcon style={{color: '#673ab7'}} className={classes.actions}/></IconButton></Link></Tooltip>
                <Tooltip title="Delete"><IconButton style={{padding:8}} onClick={handleRemove}><DeleteIcon style={{color:"mediumpurple"}} className={classes.actions}/></IconButton></Tooltip>
                <Dialog open={remove} onClose={handleRemoveClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Idea</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  Are you sure you want to delete Idea?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRemoveClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleDelete} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>    
            </StyledTableCell>
        </StyledTableRow>
    )
}