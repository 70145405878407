import React from 'react'
import axios from 'axios';
import IdeaCell from './IdeaCell'
import SponsorCell from './SponsorCell'
import MentorCell from './MentorCell'
import CollaboratorCell from './CollaboratorCell'
import CampaignCell from './CampaignCell'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux';
import Feature from '../../Feature';

export default function Overview() {

    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const [roles, setRoles] = React.useState([])
    React.useEffect(() => {

        // if(userId == null){
        //     userId = 'ppoddar';
        // }
        axios.get('/userGateway/get/user/' + userId)
            .then(response => {
                setRoles(response.data.role);
            })
    }, []);
    return (
        <Grid container spacing={2} justify="flex-start">
            <IdeaCell />
            <Feature name='show-feature'>
                <CampaignCell />
                {roles.includes("Sponsor") === true ? <SponsorCell /> : <span />}
                {roles.includes("Mentor") === true ? <MentorCell /> : <span />}
                {roles.includes("Collaborator") === true ? <CollaboratorCell /> : <span />}
            </Feature>
        </Grid>
    )
}