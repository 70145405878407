import React, { useRef, useState } from 'react';
import { Grid, Fab } from '@material-ui/core';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { withStyles } from "@material-ui/core/styles";

import './fileUpload.scss';
import RenderImage from './RenderImage';

const styles = theme => ({
  button: {
    color: theme.palette.primary.main,
    margin: 10,
    width: 30,
    height: 30,
    top: 3
  },
});

function FileUpload({classes}) {

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploaded, setIsUpload] = useState(false);
  const [url, setURL] = useState('');

  const handleUploadClick = event => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
   reader.onloadend = function (e) {
      setSelectedFile([reader.result]);
    }.bind(this);
    setSelectedFile(event.target.files[0]);
    setIsUpload(true)
  };
  return (
    <div className="file-upload">
      {!isUploaded ? <Grid container justify="center" alignItems="center">
        <input
          accept="image/*"
          className="image-input"
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
          <Fab component="span" className={classes.button}>
            <AddPhotoAlternateIcon />
          </Fab>
        </label>
      </Grid> :
        <RenderImage src={selectedFile} />
      }
      </div>
    )
}

export default withStyles(styles)(FileUpload) ;