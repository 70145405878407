import lightTheme from './lighBaseTheme';
import darkTheme from './darkBaseTheme';

export const themeArr = [
  {
    id: "light",
    theme: lightTheme
  },
  {
    id: 'dark',
    theme: darkTheme
  }
];

export default themeArr;
