import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { Route } from 'react-router-dom';
import campaign from '../components/CampaignLayout/CampaignInfo';
import CampaignLayout from '../components/CampaignLayout/CampaignLayout';
import CreateCampaign from '../components/CampaignLayout/Create/CreateCampaign';
import EditCampaign from "../components/CampaignLayout/Edit/EditCampaign";
import ViewCampaign from "../components/CampaignLayout/View/ViewCampaign";
import CollaboratorLayout from '../components/CollaboratorLayout/CollaboratorLayout';
import CreateIdea from '../components/IdeaLayout/Create/CreateIdea';
import EditIdea from "../components/IdeaLayout/Edit/EditIdea";
import IdeaLayout from '../components/IdeaLayout/IdeaLayout';
import ViewIdea from "../components/IdeaLayout/View/ViewIdea";
import MentorLayout from '../components/MentorLayout/MentorLayout';
import MentorIdea from '../components/MentorPopUp/MentorIdea';
import SponsorLayout from '../components/SponsorLayout/SponsorLayout';
import SponsorIdea from '../components/SponsorPopUp/SponsorIdea';
import UserDashboard from '../components/UserDashboard/UserDashboard';
import UserProfile from "../components/UserProfile/UserProfile";
import MyCampaignsTable from '../containers/Campaigner/MyCampaigns';
import MyCollaborationsTable from '../containers/Collaborator/MyCollaborationsTable';
import MyIdeasTable from '../containers/Ideator/MyIdeasTable';
import MyMentorshipsTable from '../containers/Mentor/MyMentorshipsTable';
import MySponsorshipsTable from '../containers/Sponsor/MySponsorshipsTable';
import MainWrapper from './MainWrapper';
import SignUp from '../components/UserRegistration/UserRegistration';
import SignIn from '../components/SignIn/SignIn';
import SignOut from '../components/SignOut';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';



const UserRoutes = () => (
    <MainWrapper>
        <main>
            <ReactRouterDom.Switch>
                <Route render={() => <UserDashboard />} exact path="/" />
                <Route render={() => <IdeaLayout />} path="/ideas" />
                <Route render={() => <SponsorLayout />} path="/sponsors" />
                <Route render={() => <CollaboratorLayout />} path="/collaborators" />
                <Route render={() => <MentorLayout />} path="/mentors" />
                <Route render={() => <CampaignLayout content={campaign} />} path="/campaigns" />
                <Route render={() => <MySponsorshipsTable />} path="/my_sponsorships" />
                <Route render={() => <MyIdeasTable />} path="/myIdeas" />
                <Route render={() => <MyCollaborationsTable />} path="/myCollaborations" />
                <Route render={() => <MyMentorshipsTable />} path="/myMentorships" />
                <Route render={() => <MyCampaignsTable />} path="/myCampaigns" />
                <Route path="/viewCampaign/:campaignId" component={ViewCampaign} />
                <Route path="/viewIdea/:ideaid" component={ViewIdea} />
                <Route render={() => <UserProfile />} path={"/userProfile"} />
                <Route render={(props) => <EditIdea {...props} />} path="/editIdea/:ideaId" />
                <Route render={(props) => <EditCampaign {...props} />} path="/editCampaign/:campaignId"/>
                <Route render={(props) => <CreateIdea {...props} />} path="/createIdea/:goalId" />
                <Route render={(props) => <CreateIdea {...props} />} path="/createIdea" />
                <Route render={(props) => <CreateCampaign {...props} />} path="/createCampaign" />
                <Route render={() => <MentorIdea />} path="/mentorAnIdea" />
                <Route render={() => <SponsorIdea />} path="/sponsorAnIdea" />
                <Route render={()=> <SignUp/>} path="/signup" />
                <Route render={()=> <SignIn />} path="/signIn"/>
                <Route render={()=> <SignOut/>} path="/signout" />
                <Route render={()=> <ForgotPassword/>} path="/reset" />
            </ReactRouterDom.Switch>
        </main>
    </MainWrapper>
);

export default UserRoutes;
