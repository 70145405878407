import React, { useState, useEffect } from 'react';
import { Table, Grid, TableHead, TableRow, withStyles, TableCell, TableBody} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios'
import MyIdeasTableRow from './MyIdeasTableRow';
import { useSelector } from 'react-redux';
import Feature from '../../components/Feature';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#7e18d8',
        color: theme.palette.common.white,
        fontSize:'17px',
        textAlign:'left'
    },
    body: {
        maxWidth:'100%',
        fontSize: 15,
        textAlign:'left',
        padding:5,
        paddingLeft:7,
        borderWidth:2
    }
}))(TableCell);

const MyIdeasTableStyle = makeStyles(theme => ({
    table:{
        width:'90%',
        borderWidth:'1px',
        margin: '1rem auto'
    },
    actions:{
        height:'18px',
        width:'18px',
    },
    sponsorInvite:{
        color:'#123456'
    },
    mentorInvite:{
        color:'#ff713b'
    },
    collaboratorInvite:{
        color:'#7e18d8'
    },
    closed:{
        color:'#a0a0a0'
    }
}))


export default function MyIdeasTable(){
    const classes=MyIdeasTableStyle();
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
    const [myIdeas, setMyIdeas] = useState([]);
    console.log("ideatable",userId)

    useEffect(() => {
        
        // if(userId == null){
        //     userId = 'ppoddar';
        // }
        // axios.get('http://ec2-107-21-76-90.compute-1.amazonaws.com:8080/idea/search/ByAuthor/'+userId)
        axios.get('/ideaGateway/getMyIdeasByUserId/'+userId)
        .then(response => {
            setMyIdeas(response.data);
        });
    }, []);

    return(
        <div>
        <Grid item style={{overflowX: 'auto'}}>
            <Table className={classes.table}>
                <TableHead >
                    <TableRow >
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Target Industries</StyledTableCell>
                        <Feature name='show-feature'>
                            <StyledTableCell>Tags</StyledTableCell>
                            <StyledTableCell>Options</StyledTableCell>
                        </Feature>
                        <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {myIdeas.filter(idea => { return (idea.deleted !== true )}).map(row => (
                    <MyIdeasTableRow idea={row} />
                ))}
                </TableBody>
            </Table>
        </Grid>
    </div>
    )
}