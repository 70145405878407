import React, { Component } from 'react'
import { Grid, TextField } from '@material-ui/core'
import Sponsor from './Sponsor';
import axios from 'axios';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

export default function SponsorLayout(){

    const [initial, setInitial] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })

    const filterList = (event) => {
        let sponsors = initial;
        let searchText = event.target.value;

        sponsors = sponsors.filter(sponsor => {
            const Tags = sponsor.tags;
            return (sponsor.userName.toLowerCase().search(searchText.toLowerCase()) !== -1 || Tags.filter(tag => {return tag.toLowerCase().match(searchText.toLowerCase())}).length > 0)
        });
        setFiltered(sponsors);
    }

    React.useEffect(() => {
        
    axios.get('/sponsorGateway/get/Sponsors/SponsorLayout/'+userId)
      .then(response => {
        setInitial(response.data)
        setFiltered(response.data)
      });
  }, []);

        return (
            <div style={{ padding: '10px' }}>
                <Grid container justify="center">
                    <Grid item xs={12} md={4} style={{ margin: 10, width: '100%' }}>
                        <TextField InputProps={{style:{height:40}}} style={{width:'100%'}} onChange={filterList} variant="outlined" placeholder="Search using Sponsor Name or Interest Areas" />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    {filtered.filter(idea => { return (idea.sponsor.userId === userId  )}).map(sponsor => (
                        <Sponsor key={sponsor.sponsor.userId} data={sponsor} />
                    ))}
                </Grid>
            </div>
        );
}