import {createTheme} from '@material-ui/core/styles';

export const darkTheme = createTheme({
  palette: {
    type: 'dark',   // Name of the theme
    primary: {
      main: '#65CBDF',
    },
    secondary: {
      main: '#65C5C7',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default darkTheme;