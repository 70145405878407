import React, { useEffect, useState } from 'react';
import AppLayout from '../components/app-layout/AppLayout';
import * as actions from '../../src/store/actions/index';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom'

// Use this class to get the appInitData. 
// Also based on the user valid or not show user the Registration/Login page else show the children
const MainWrapper = ({ children }) => {
    const userDispatch = useDispatch();
    const history = useHistory();
    let currentUser = sessionStorage.getItem('id');
    const onSetUser = user => userDispatch(actions.setUser(user));
    onSetUser(currentUser);
    if (sessionStorage.getItem('token') == " " || sessionStorage.getItem('token') == null) {
        history.push('/signIn')
    }
    return (
        <div>
            <AppLayout
                children={children}
            />
        </div>
    )
}

export default MainWrapper;