import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    ideas:[],
    user: '',
    token:null
}

const setIdeas = (state = initialState, action) => {
    // console.log(action);
    return {...state, ideas: {
        ...action.ideas
    }}
};

const fetchIdeasFailed = (state, action) => {
    return updateObject( state, { error: true } );
};

const setUser = (state = initialState, action) => {
    // console.log(action);
    return {...state, user: action.user}
};
const setToken = (state = initialState, action) => {
    // console.log(action);
    return {...state, user: action.token}
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_IDEAS: return setIdeas(state, action);    
        case actionTypes.FETCH_IDEAS_FAILED: return fetchIdeasFailed(state, action);
        case actionTypes.SET_USER: return setUser(state, action);
        case actionTypes.SET_TOKEN: return setToken(state, action);
        default: return state;
    }
};

export default reducer;