import React, { useState, useEffect } from 'react'
import { Grid, Card, CardHeader, CardContent, List, ListItem, ListItemText,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Doughnut } from 'react-chartjs-2';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import axios from "axios";
import { useSelector } from 'react-redux';

const options = {
    responsive: false,
    maintainAspectRatio: false,
    layout: {
        padding: '0px',
        margin: '0px',
        textAlign: 'left'
    },
    legend: {
        labels: {
            boxWidth: 20
        }
    }
}



const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: '2px',
        height: '100%'
    },
    button: {
        margin: 0,
        padding: '2px',
    },
    card: {
        height: '120px',
        textAlign: 'center'
    },
    header: {
        paddingLeft: '20px',
        fontFamily: 'Trebuchet MS',
        fontSize: '2.2em'
    },
    link: {
        textDecorationLine: 'none'
    }
}));

export default function SponsorCell() {
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
    const classes = useStyles();
    const [ideas, setIdeas] = useState([]);
    const [recommendtions, setRecommendations] = useState([]);
    const [budgetSpent, setBudgetSpent] = useState(0);
    const [budgetRemaining, setBudgetRemaining] = useState(0);

    useEffect(() => {
       
        axios.all([
            axios.get('/sponsorGateway/get/Sponsors/' + userId)
                .then(response => {
                    setBudgetSpent(response.data.sponsor.budgetSpent)
                    setBudgetRemaining(response.data.sponsor.availableBudget)
                }),
            axios.get('/sponsorGateway/get/sponsorIdeaRecommendations/' + userId)
                .then(response => {
                    setRecommendations(response.data.slice(0,3));
                }),
            axios.get('/sponsorGateway/get/sponsorIdeas/' + userId)
                .then(response => {
                    setIdeas(response.data.slice(0,3));
                })
        ])
    }, []);

    const data = {
        datasets: [{
            data: [budgetSpent, budgetRemaining],
            backgroundColor: ['#ff713b', '#123456']
        }],

        labels: [
            'Budget Spent',
            'Remaining'
        ],

    }

    return (
        <Grid item xs={12} md={6} className={classes.grid}>
            <Card style={{ height: '100%' }}>
                <Link to="/my_sponsorships" className={classes.link}>
                    <CardHeader
                        title={`Sponsored Ideas: ${ideas.length}`}
                        titleTypographyProps={{ variant: 'h5' }}
                        style={{ background: '#123456', color: 'white' }} />
                </Link>
                <CardContent style={{ height: '100%', paddingBottom: '0px' }}>
                    <div style={{ padding: 5, margin: 5, display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ width: '50%' }}>
                            <h4 style={{ margin: 0 }}>Your Most Recent Ideas</h4>
                            <List dense={true}>
                                {ideas.length > 0 ?
                                    (
                                        ideas.map(Idea => (
                                            <Link to={{ pathname: '/viewIdea/' + Idea.ideaId }} style={{ textDecorationLine: "none", color: 'black' }}>
                                                <ListItem button key={Idea.ideaId} style={{ borderBottomStyle: "solid", borderColor: '#e0e0e0', borderWidth: 2 }}>
                                                    <ListItemText primary={Idea.title} />
                                                </ListItem>
                                            </Link>
                                        ))
                                    ) :
                                    (
                                        <Typography style={{ marginTop: 10, color: 'grey', fontWeight: 'bold' }}>
                                            You haven't sponsored and ideas yet
                                        </Typography>
                                    )
                                }
                            </List>
                        </div>
                        <div style={{ width: '50%' }}>
                            <Doughnut data={data} options={options} />
                        </div>
                    </div>

                    <div style={{ width: '100%', marginBottom: '0px' }}>
                        <CardHeader
                            title="Ideas you might be interested to sponsor:"
                            style={{ padding: '5px', margin: '5px', height: '35px' }}
                            titleTypographyProps={{ variant: 'h6' }} />
                        <List dense={true}>
                            {recommendtions.map(Idea => (
                                <Link to={{ pathname: '/viewIdea/' + Idea.ideaId }} style={{ textDecorationLine: "none", color: 'black' }}>
                                    <ListItem button key={Idea.ideaId} style={{ borderBottomStyle: "solid", borderColor: '#e0e0e0', borderWidth: 2 }}>
                                        <ListItemText primary={Idea.title} />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    )
}