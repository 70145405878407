import {
  SET_APP_INIT_DATA_SUCCESS,
  SET_APP_INIT_DATA_LOADING,
  SET_APP_INIT_DATA_FAILURE
} from './constant';

export const loadAppInitData = (data) => {
  return {
    type: SET_APP_INIT_DATA_LOADING,
    data: data
  }
}

export const setAppInitData = (data) => {
  return {
    type: SET_APP_INIT_DATA_SUCCESS,
    data: data
  }
}

export const failAppInitData = (data) => {
  return {
    type: SET_APP_INIT_DATA_FAILURE,
    errMessage: data
  }
}