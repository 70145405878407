import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './App.css';
import UserRoutes from './UserRoutes';
import { BrowserRouter } from 'react-router-dom';

const initFeatures = () => {

  localStorage.setItem('flags', JSON.stringify([
    { name: 'show-feature', description: 'MVP', active: false },
  ]));

};

// TODO: Use this class to show loader/splash screen until the bundle is loaded. Write loader code.
class App extends React.Component {
  constructor(props) {
    initFeatures();
    super(props);
    this.state = {
      loading: true,
      loaded: false
    }

  }

  render() {
    return (
      <div>
        <div>
          <BrowserRouter>
            <UserRoutes />
          </BrowserRouter>
        </div>
      </div>
    )
  }
}

export default App;
