import React, { useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { CampaignStatus } from '../../Dropdowns/Status';
import Radio from '@material-ui/core/Radio';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioGroup from '@material-ui/core/RadioGroup';
import PeopleIcon from '@material-ui/icons/People';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddStages from './AddStages';
import { JustUserDropdown } from '../../Dropdowns/Dropdowns';
import { useSelector } from 'react-redux';
import { ContactsOutlined } from '@material-ui/icons';
import swal from 'sweetalert';
import Feature from '../../Feature';
import { withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip } from '@material-ui/core';
import moment from 'moment';
import AddGoals from './AddGoals';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ViewGoal from './ViewGoal';
import { fontAwesomeIcon } from 'fontawesome';
import ViewStage from './ViewStage';

const useStyles = makeStyles(theme => ({
  rounded: {
    borderRadius: 0
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '50%',
  },
  popbutton: {
    color: 'white',
    marginTop: '8px',
    marginRight: '20px',
    borderColor: 'white'

  },
  button: {
    textAlign: 'right',
  },
  title: {
    background: '#00d4ff',
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
  },
  content: {
    width: '85%',
    left: '10%',
  },
  textfield: {
    width: '100%',
  },
  hei: {
    marginTop: '5%',
    top: '5%',
    marginLeft: '10%',
    maxHeight: '87%',
    maxWidth: '80%',
  },
  display: {
    display: 'none',
  },
  button2: {
    textAlign: 'center',
  },
  cardHead: {
    padding: '0',
    margin: '0',
    marginTop: '2px',
  },
  people: {
    color: 'gold',
    fontSize: '50px'
  },
  barChart: {
    color: '#00d4ff'
  }
}));
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#13b8a2',
    color: theme.palette.common.white,
    fontSize: '17px',
    textAlign: 'left'
  },
  body: {
    maxWidth: '100%',
    fontSize: 15,
    textAlign: 'left',
    padding: 5,
    paddingLeft: 7,
    borderWidth: 2
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ededed',
    }
  }
}))(TableRow);

const industries = ['Consumer Products', 'Energy & Commodities', 'Financial Services', 'Health', 'Public Sector', 'Retail', 'Telecommunications, Media & Technology', 'Transportation & Mobility', 'Travel & Hospitality', 'Internal', 'Others'];
const stageValues = ['STAGE I', 'STAGE II', 'STAGE III', 'STAGE IV', 'STAGE V'];
export default function CreateCampaign(props) {
  const classes = useStyles();
  let userId = '';
  useSelector(state => {
    userId = state.appReducer.user;
  })
  const [campaignStartDate, setCampaignStartDate] = React.useState(null);
  const [campaignEndDate, setCampaignEndDate] = React.useState(null);
  const [stageStartDate, setStageStartDate] = React.useState(null);
  const [stageEndDate, setStageEndDate] = React.useState(null);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty())
  const [editorStateGoal, setEditorStateGoal] = React.useState(() => EditorState.createEmpty())
  const [editorStateGoal0, setEditorStateGoal0] = React.useState(() => EditorState.createEmpty())
  const [emailVal, setEmailVal] = React.useState("");
  const [error, setError] = React.useState('');
  const [editorStateStageCriteria, setEditorStateStageCriteria] = React.useState(() => EditorState.createEmpty())
  const [radio, setRadio] = React.useState('female');
  const [mssg, setMssg] = React.useState(false);
  const [mssg1, setMssg1] = React.useState(false);
  const [flag, setFlag] = React.useState(true);
  const [modalState, setModalState] = React.useState(true);
  const [modalState1, setModalState1] = React.useState(true);
  const [stages, setStage] = React.useState({
    currentStage: "",
    stageMilestone: "",
    stageCriteria: "",
    stageSummary: "",
    stageStart: "",
    stageEnd: "",
  });
  const [goalData, setGoalData] = React.useState({
    deleted: false,
    description: "",
    industry: "",
    goalId: "",
    ideas: [],
    timeCreated: "2019-12-10T05:50:39.845Z",
    timeEdited: "2019-12-10T05:50:39.845Z",
    title: ""
  });
  const requiredError = campaignStartDate === '' || campaignStartDate === undefined
  const helperText = requiredError ? 'Input is required' : undefined
  const [campaign, setCampaign] = React.useState(
    {
      deleted: false,
      description: "",
      estimatedDeadline: "2019-12-10T05:50:39.845Z",
      goalData: [

      ],
      awards: [
        {
          title: "",
          value: ""
        }
      ],
      stages: [
      ],
      goals: [],
      likes: [],
      owner: userId,
      progress: 0,
      rewards: [],
      rules: [""],
      status: "Draft",
      timeCreated: "2019-12-10T05:50:39.845Z",
      timeEdited: "2019-12-10T05:50:39.845Z",
      title: "",
      startCampaignDate: "",
      endCampaignDate: "",
      visibility: [],
      campaignCoreTeamMembers: []
    });

  const handleChange = name => event => {
    setCampaign({ ...campaign, [name]: event.target.value });
  };
  const handleAddStage = (name) => e => {
    if (stages.stageCriteria != "" && stages.currentStage != "" && stages.stageStart != "" && stages.stageEnd != "") {
      let campaigns = campaign;
      campaigns.stages.push(stages);
      setCampaign(campaigns);
      setStage({ ...stages, ['currentStage']: "" });
      setStage({ ...stages, ['stageMilestone']: "" });
      setStage({ ...stages, ['stageCriteria']: "" });
      setStage({ ...stages, ['stageSummary']: "" });
      setStage({ ...stages, ['stageStart']: "" });
      setStage({ ...stages, ['stageEnd']: "" });
      setEditorStateStageCriteria(EditorState.createEmpty());
      setModalState1(false)
    }
    else {
      setMssg1(true)
    }
  }

  function handleCurrentStage(e, value) {
    setStage({ ...stages, ['currentStage']: value });
  }

  const handleStageMilestone = e => {
    setStage({ ...stages, ['stageMilestone']: e.target.value });
  }

  const handleStageCriteria = (editorStateStageCriteria) => {
    setEditorStateStageCriteria(editorStateStageCriteria)
    const contentState = editorStateStageCriteria.getCurrentContent();
    const val = JSON.stringify(convertToRaw(contentState));
    setStage({ ...stages, ['stageCriteria']: val });

  }

  const handleStageSummary = e => {
    setStage({ ...stages, ['stageSummary']: e.target.value });
  }

  const handleStageDelete = (i, name) => e => {
    e.preventDefault();
    let stages = campaign.stages.filter(r => campaign.stages.indexOf(r) !== i)
    setCampaign({ ...campaign, [name]: stages });
  }

  const handleAddGoalNew = (name) => e => {
    e.preventDefault()
    if (goalData.description != "" && goalData.industry != "" && goalData.title != "") {
      let campaigns = campaign;
      campaigns.goalData.push(goalData);
      setCampaign(campaigns);
      setGoalData({ ...goalData, ['deleted']: false });
      setGoalData({ ...goalData, ['description']: "" });
      setGoalData({ ...goalData, ['industry']: "" });
      setGoalData({ ...goalData, ['goalId']: "" });
      setGoalData({ ...goalData, ['ideas']: "" });
      setGoalData({ ...goalData, ['timeCreated']: "2019-12-10T05:50:39.845Z" });
      setGoalData({ ...goalData, ['timeEdited']: "2019-12-10T05:50:39.845Z" });
      setGoalData({ ...goalData, ['title']: "" });
      setEditorStateGoal(EditorState.createEmpty());
      setModalState(false)

    }

  }
  const handleGoalDelete = (i, name) => e => {
    e.preventDefault()
    let goal = campaign.goalData.filter(r => campaign.goalData.indexOf(r) !== i)
    setCampaign({ ...campaign, [name]: goal });
  }

  const handleAddGoal = (name) => e => {
    let temp = {
      deleted: false,
      description: '',
      goalId: '',
      ideas: [],
      timeCreated: "2019-12-10T05:50:39.845Z",
      timeEdited: "2019-12-10T05:50:39.845Z",
      title: ''
    }
    e.preventDefault()
    let goal = campaign.goalData.concat(temp)
    setCampaign({ ...campaign, [name]: goal });
  }

  const handleGoalDescriptionText = (editorStateGoal) => {
    setEditorStateGoal(editorStateGoal)
    const contentState = editorStateGoal.getCurrentContent();
    const val = JSON.stringify(convertToRaw(contentState));
    setGoalData({ ...goalData, ['description']: val });
  }

  const handleGoalTitleText = (e) => {
    setGoalData({ ...goalData, ['title']: e.target.value });
  }

  const handleGoalIndustries = (e, value) => {
    setGoalData({ ...goalData, ['industry']: value });
  }

  const handleAddAward = (name) => e => {
    let temp = {
      title: '',
      value: ''
    }
    e.preventDefault()
    let award = campaign.awards.concat(temp)
    setCampaign({ ...campaign, [name]: award });
  }

  const handleAwardDelete = (i, name) => e => {
    e.preventDefault()
    let award = campaign.awards.filter(r => campaign.awards.indexOf(r) !== i)
    setCampaign({ ...campaign, [name]: award });
  }

  const handleAwardTitleText = (i, name) => e => {
    let award = campaign.awards
    award[i].title = e.target.value
    setCampaign({ ...campaign, [name]: award });
  }

  const handleAwardValueText = (i, name) => e => {
    let award = campaign.awards
    award[i].value = e.target.value
    setCampaign({ ...campaign, [name]: award });
  }

  const handleRuleDelete = (i, name) => e => {
    e.preventDefault()
    let rule = campaign.rules.filter(r => campaign.rules.indexOf(r) !== i)
    setCampaign({ ...campaign, [name]: rule });
  }

  const handleAddRule = (name) => e => {
    let temp = '';
    e.preventDefault()
    let rule = campaign.rules.concat(temp)
    setCampaign({ ...campaign, [name]: rule });
  }

  const handleRulesText = (i, name) => e => {
    let rule = campaign.rules
    rule[i] = e.target.value
    setCampaign({ ...campaign, [name]: rule });
  }





  const handleDateChange = name => date => {
    if (name === 'stageStart') {
      setStageStartDate(date);
      setStage({ ...stages, ['stageStart']: date });
      setMssg1(false)

    }
    if (name === 'stageEnd') {
      setStageEndDate(date);
      setStage({ ...stages, ['stageEnd']: date });
      setMssg1(false)

    }
    if (name === 'campaignStart') {
      setCampaignStartDate(date);
      setCampaign({ ...campaign, ['startCampaignDate']: date });
      setMssg(false)

    }
    if (name === 'campaignEnd') {
      setCampaignEndDate(date);
      setCampaign({ ...campaign, ['endCampaignDate']: date });
      setMssg(false)

    }


  }
  const cancelButtonHandler = () => {
    props.history.push('/');
  };

  function handleMailKey(e) {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      if (isValid(emailVal)) {
        let campaigns = campaign;
        campaigns.campaignCoreTeamMembers.push(emailVal);

        setCampaign(campaigns);
        setEmailVal("");

      }
    }
  };

  function handleMail(e) {
    setEmailVal(e.target.value);
    setError('');
  }

  function isValid(email) {

    if (!isEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return false;
    }
    return true;
  }
  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  function handleMailDelete(item) {

    setCampaign({ ...campaign, ['campaignCoreTeamMembers']: campaign.campaignCoreTeamMembers.filter(i => i !== item) });
  }

  const [expanded, setExpanded] = React.useState(false);

  const createCampaign = () => {
    if (campaign.startCampaignDate != "" && campaign.endCampaignDate != "" && campaign.title.length !== 0 && campaign.description.length !== 0) {
      // setCampaign({...campaign,['stages']:stages})
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      };
      // handleDateChange('noChange')
      const newCampaign = { ...campaign }
      axios.post('/campaignGateway/create', newCampaign, axiosConfig)
        .then(response => {
          props.history.push('/');
        })
    }
    else {
      setMssg(true)
    }

  }
  const onEditorChange = (editorState) => {

    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const val = JSON.stringify(convertToRaw(contentState));
    setCampaign({ ...campaign, ['description']: val });
  }

  const handleChangeRadio = event => {
    setRadio(event.target.value);
    if (event.target.value == 'Public') {
      setExpanded(false);
      let temp = [];
      setCampaign({ ...campaign, ['visibility']: temp });
    }
    else {
      setExpanded(true);
    }
  };
  const onfocusfn=()=>{
    setFlag(false)
  }
  const onFocusOutfn=()=>{
    setFlag(true)
  }



  return (
    <div>
      <Grid container className={classes.box1} style={{ paddingBottom: "15px" }} >
        <Grid item className={classes.title}>
          <DialogTitle id="form-dialog-title"><span style={{ fontSize: '50px' }}>Create Campaign <PeopleIcon className={classes.people} /></span></DialogTitle>
        </Grid>

        <Grid xs={12} className={clsx(!mssg && classes.display)}>
          <text style={{ color: 'red' }}><strong>Please fill out the fields with * mark</strong></text>
        </Grid>

        <Grid item xs={12} md={12}  >
          <Card style={{ marginTop: '1rem' }}>
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Title"
                name="title"
                autoComplete="title"
                onChange={handleChange('title')}
              />
              {flag==true &&
                <>
              <div style={{marginTop: "-90px"}} onFocus={onfocusfn}>
                    <Editor
                      name="description"
                      editorState={editorState}
                      toolbarOnFocus
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorChange}
                      placeholder=" Enter a brief description about your Campaign*"
                    />
                  </div></>}
              {flag==false &&
              <div style={{marginTop: "0px"}} onBlur={onFocusOutfn} >
                  <Editor
                    name="description"
                    editorState={editorState}
                    toolbarOnFocus
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorChange}
                    placeholder=" Enter a brief description about your Campaign*"
                  />
                </div>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent style={{ paddingTop: '2px', paddingBottom: '10px' }}>
              <CardHeader className={classes.cardHead} title="Goals*" action={<AddGoals campaign={campaign} handleGoalIndustries={handleGoalIndustries} handleGoalTitleText={handleGoalTitleText} handleGoalDescriptionText={handleGoalDescriptionText}
                editorStateGoal={editorStateGoal} industries={industries} handleAddGoalNew={handleAddGoalNew} goalData={goalData} setGoalData={setGoalData} modalState={modalState} setModalState={setModalState} />} />
              <Grid container spacing={1} style={{ padding: '10px' }}>
                <Grid item xs={12} justify="center" style={{ overflowX: 'auto' }}>
                  {campaign.goalData.length > 0 &&
                    <Table >
                      <TableHead>
                        <StyledTableRow style={{ fontSize: '150%' }}>
                          <StyledTableCell>Title</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>Industry</StyledTableCell>
                          <StyledTableCell>Actions</StyledTableCell>
                        </StyledTableRow>
                        {campaign.goalData.map((goal, index) => (
                          <StyledTableRow key={index}>
                            <TableCell>{goal.title}</TableCell>
                            <TableCell> </TableCell>
                            <TableCell>{goal.industry}</TableCell>
                            <TableCell>
                              <ViewGoal campaign={campaign} handleGoalIndustries={handleGoalIndustries} handleGoalTitleText={handleGoalTitleText} handleGoalDescriptionText={handleGoalDescriptionText}
                                editorStateGoal={editorStateGoal} industries={industries} handleAddGoalNew={handleAddGoalNew} goalData={goalData} setGoalData={setGoalData} modalState={modalState} setModalState={setModalState}
                                goal={goal} />
                              <Tooltip title="Delete" style={{ marginTop: "-65px", marginLeft: "30px" }}><IconButton><DeleteIcon style={{ color: "mediumpurple" }} className={classes.actions} onClick={handleGoalDelete(index, 'goalData')} /></IconButton></Tooltip>
                            </TableCell></StyledTableRow>
                        ))}
                      </TableHead>
                    </Table>}
                  {campaign.goalData.length == 0 &&
                    <div>
                      <h6>No goals added. Add goals by pressing the Add button</h6><i className="fa-circle-plus"></i>
                    </div>
                  }
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card>
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <CardHeader className={classes.cardHead} title="Awards*" action={<IconButton variant="contained" title="Add new Resource" style={{ padding: '8px', marginTop: 4, backgroundColor: '#e0e0e0' }} onClick={handleAddAward('awards')} >
                <AddIcon style={{ color: 'black' }} />
              </IconButton>} />
              {campaign.awards.map((res, index) => (
                <Grid container id={index} md={12}>
                  <Grid xs={12} md={6} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Award Title"
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      defaultValue={res.title}
                      onChange={handleAwardTitleText(index, 'awards')}
                    />
                  </Grid>
                  <Grid xs={12} md={5} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Enter award"
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      defaultValue={res.value}
                      onChange={handleAwardValueText(index, 'awards')}
                    />
                  </Grid>
                  <Grid xs={1} md={1} className='item' style={{ padding: '0.3%' }}>
                    <Button variant="contained" title="Delete Resource" style={{ marginTop: '12px' }} onClick={handleAwardDelete(index, 'awards')} ><DeleteIcon /></Button></Grid></Grid>
              ))}

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card>
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <CardHeader className={classes.cardHead} title="Rules*" action={<IconButton variant="contained" title="Add new Rule" style={{ padding: '8px', marginTop: 4, backgroundColor: '#e0e0e0' }} onClick={handleAddRule('rules')} >
                <AddIcon style={{ color: 'black' }} />
              </IconButton>} />
              {campaign.rules.map((res, index) => (
                <Grid container id={index} >
                  <Grid xs={12} md={11} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Rule"
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      defaultValue={campaign.rules[index]}
                      onChange={handleRulesText(index, 'rules')}
                    />
                  </Grid>
                  <Grid xs={1} md={1} className='item' style={{ padding: '0.3%' }}>
                    <Button variant="contained" title="Delete Rule" style={{ marginTop: '12px' }} onClick={handleRuleDelete(index, 'rules')} ><DeleteIcon /></Button></Grid></Grid>
              ))}

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card>
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <CardHeader className={classes.cardHead} title="Stages*" action={<AddStages campaign={campaign} stageValues={stageValues} handleCurrentStage={handleCurrentStage}
                mssg={mssg} stages={stages} handleStageMilestone={handleStageMilestone} editorStateStageCriteria={editorStateStageCriteria}
                mssg1={mssg1} handleStageCriteria={handleStageCriteria} handleStageSummary={handleStageSummary} stageStartDate={stageStartDate} handleDateChange={handleDateChange} stageEndDate={stageEndDate}
                handleStageDelete={handleStageDelete} handleAddStage={handleAddStage} setModalState1={setModalState1} modalState1={modalState1} />} />
              <Grid container spacing={1} style={{ padding: '10px' }}>
                <Grid item xs={12} justify="center" style={{ overflowX: 'auto' }}>
                  {campaign.stages.length > 0 &&
                    <Table >
                      <TableHead>
                        <StyledTableRow style={{ fontSize: '150%' }}>
                          <StyledTableCell>Stage Name</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>Stage Milestone</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>Actions</StyledTableCell>

                        </StyledTableRow>
                        {campaign.stages.map((stage, index) => (
                          <StyledTableRow key={index}>
                            <TableCell>{stage.currentStage}</TableCell>
                            <TableCell></TableCell>
                            <TableCell>{stage.stageMilestone}</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <ViewStage campaign={campaign} stageValues={stageValues} handleCurrentStage={handleCurrentStage}
                                mssg={mssg} stages={stages} handleStageMilestone={handleStageMilestone} editorStateStageCriteria={editorStateStageCriteria}
                                mssg1={mssg1} handleStageCriteria={handleStageCriteria} handleStageSummary={handleStageSummary} stageStartDate={stageStartDate} handleDateChange={handleDateChange} stageEndDate={stageEndDate}
                                handleStageDelete={handleStageDelete} handleAddStage={handleAddStage} stage={stage} />
                              <Tooltip title="Delete" style={{ marginTop: "-65px", marginLeft: "30px" }}><IconButton><DeleteIcon style={{ color: "mediumpurple" }} onClick={handleStageDelete(index, 'stages')} className={classes.actions} /></IconButton></Tooltip>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableHead>
                    </Table>}
                  {campaign.stages.length == 0 &&
                    <div>
                      <h6>No stage added. Add stages by pressing the Add button</h6>
                    </div>
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}  >
          <Card>
            <CardHeader className={classes.cardHead} title="Timelines and Status" style={{ margin: 20 }} />
            <CardContent style={{ paddingTop: '2px', paddingBottom: '15px' }}>
              <text>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <strong>Campaign Start date*:</strong>
                    <div style={{ marginTop: "-1px", display: "inline-grid" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format="MM/dd/yyyy"
                          error={mssg && (campaign.startCampaignDate.length === 0)}
                          value={campaignStartDate}
                          helperText={helperText}
                          onChange={handleDateChange('campaignStart')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <strong>Campaign End date*:</strong>
                    <div style={{ marginTop: "-1px", display: "inline-grid" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format="MM/dd/yyyy"
                          value={campaignEndDate}
                          error={mssg && (campaign.endCampaignDate.length === 0)}
                          onChange={handleDateChange('campaignEnd')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <CampaignStatus data={campaign.status} handleChange={handleChange} />
                  </Grid>
                  <Feature name='show-feature'>
                    <Grid item xs={12} md={6}>
                      <div>
                        <text>
                          <p style={{ marginBottom: '2px' }}>
                            <strong>Your PS coins:</strong>
                          </p>
                        </text>
                        <TextField id="standard-basic" defaultValue={0} inputProps={{ readOnly: true }} />
                      </div>
                    </Grid>
                  </Feature>
                </Grid>
              </text>
            </CardContent>
          </Card>
        </Grid>
        <Feature name='show-feature'>
          <Grid xs={10}  >
            <Card>
              <CardHeader className={classes.cardHead} title="Visibility" style={{ margin: 20 }} />
              <CardContent>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  <RadioGroup aria-label="gender" name="gender1" value={radio} onChange={handleChangeRadio}>

                    <ExpansionPanelSummary
                      aria-controls="panel1bh-content" style={{ width: '90%', maxHeight: '30%' }}
                      id="panel1bh-header" >
                      <FormControlLabel value="Public" control={<Radio />} label="Entire Community" />
                    </ExpansionPanelSummary>

                    <ExpansionPanel expanded={expanded === true} style={{ width: '100%' }} >
                      <ExpansionPanelSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header" >
                        <FormControlLabel value="Restricted" control={<Radio />} label="Specific People(or)Group" />
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails>

                        <JustUserDropdown data={'Select Users'} />
                      </ExpansionPanelDetails>

                    </ExpansionPanel>
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        </Feature>


        <Grid item xs={12} md={10} className={classes.item}>
          <Card className={classes.card} >
            <Feature name='show-feature'>
              <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                <CardHeader className={classes.cardHead} title="Core team"></CardHeader>
                <CardContent style={{ padding: '0.5rem 1rem' }}>


                  {error && <Alert variant="filled" severity="error">{error}</Alert>}
                  <text>
                    <Grid container>
                      <Grid className={classes.item} xs={12} md={6}>
                        <TextField variant="outlined"
                          margin="normal"
                          size="small"
                          required
                          value={emailVal}
                          style={{ marginTop: '5px', marginBottom: '10px', width: '100%' }}
                          label="Add E-Mail Address"
                          name="Mentor"
                          onKeyDown={handleMailKey}
                          onChange={handleMail}
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {campaign.campaignCoreTeamMembers.map(item => (
                          <Chip size="small"
                            label={item} style={{ margin: "10px" }} onDelete={e => handleMailDelete(item)}>
                            &times;
                          </Chip>
                        ))}
                      </Grid>
                    </Grid>
                  </text>
                </CardContent>
              </CardContent>
            </Feature>
          </Card>
        </Grid>


        <Grid item md={5} style={{ textAlign: 'right', padding: '1%' }} >
          <Button variant="contained" onClick={cancelButtonHandler} >
            Cancel
          </Button>
        </Grid>

        <Grid item md={5} style={{ textAlign: 'left', padding: '1%' }}>
          <Button variant="contained" onClick={createCampaign} >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div >
  );
}