import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse, AxiosError } from 'axios';

export default class NetworkManager {
  service;

  constructor(config) {
    this.service = axios.create(config);
  }

  useRequestMiddleware = axios.interceptors.request.use.bind(axios.interceptors.request);

  useResponseMiddleware = axios.interceptors.response.use.bind(axios.interceptors.response);

  get defaultConfig() {
    return axios.defaults;
  }

  request(config) {
    return axios.request(config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  get(path, config) {
    return axios.get(path, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  put = (path, data, config) => {
    return axios.put(path, data, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  post = (path, data, config) => {
    return axios.post(path, data, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  delete = (path, config) => {
    return axios.delete(path, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  request = (config) => {
    return this.service.request(config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  get = (path, config) => {
    return this.service.get(path, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  put = (path, data, config) => {
    return this.service.put(path, data, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  post = (path, data, config) => {
    return this.service.post(path, data, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }

  delete = (path, config) => {
    return this.service.delete(path, config).then(
      (response) => Promise.resolve(response),
      (error) => Promise.reject(error)
    );
  }
}