import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';
import axios from 'axios';
import { setAppInitData, failAppInitData } from './redux/action';
import './AppLayout.scss';
import { useLocation } from 'react-router';

const sidebarOptions = [
    { id: "dashboard", title: "Dashboard", path: "/" },
    { id: "ideate", title: "Ideate", path: "/ideas" },
    { id: "campaigns", title: "Campaigns", path: "/campaigns" },
    // { id: "mentors", title: "Mentors", path: "/mentors" },
    // { id: "sponsors", title: "Sponsors", path: "/sponsors" },
    // { id: "collaborators", title: "Collaborators", path: "/collaborators" },
    // { id: "profile", title: "My Profile", path: "/userProfile" }
];
const withoutNavbarRoutes = ["/signUp","/signIn","/reset"];
export default function AppLayout({ children }) {
    const { pathname } = useLocation();
    console.log("mainnnnn")
    let userId1 = '';
    useSelector(state => {
        userId1 = state.appReducer.user;
    })
    let currentUser = sessionStorage.getItem('id')
    console.log("applayout",userId1)

    const [isDrawerOpen, handleDrawerAction] = React.useState(false);
    //const [appInitData, setAppInitData] = React.useState({});

    const themeObject = useSelector(state => state.themeReducer)

    const dispatch = useDispatch();

    const onSetAppInitData = useCallback(data => dispatch(setAppInitData(data)), {});
    const onFailAppInitData = useCallback(err => dispatch(failAppInitData(err)), {});

    // TODO - Move this side-effect to redux
    useEffect(() => {
       
        axios.all([
            axios.get('/mentorGateway/get/MentorNotificationDetails/' + currentUser),
            axios.get('/sponsorGateway/get/SponsorNotificationDetails/' + currentUser),
            axios.get('/collaboratorGateway/get/CollaboratorNotificationDetails/' + currentUser),
            axios.get('/mentorGateway/get/Mentors/MentorLayout/' + currentUser),
            axios.get('/sponsorGateway/get/Sponsors/SponsorLayout/' + currentUser)
        ])
            .then(axios.spread((mentorDetails, sponsorDetails, collaboratorDetails, mentorsList, sponsorsList) => {
                console.log("hiiiii", mentorDetails)
                onSetAppInitData({ mentorDetails, sponsorDetails, collaboratorDetails, mentorsList, sponsorsList });
            })).catch((err) => {
                onFailAppInitData(err)
            })
    }, [])


    if (withoutNavbarRoutes.some((item) => pathname.includes(item))){
        return(
            <div className="appLayout">
                <MuiThemeProvider theme={themeObject.theme}>
                    <CssBaseline />
                    <div className="appLayout__header">
                        <Topbar
                            // appInitData={appInitData}
                            handleDrawerAction={() => handleDrawerAction(!isDrawerOpen)}
                            isDrawerOpen={isDrawerOpen}
                        />
                    </div>
                    <div className="appLayout__container">
                        <Sidebar
                            isDrawerOpen={isDrawerOpen}
                            sidebarOptions={sidebarOptions}
                        />
                        <main className="appLayout__children" style={{width:"102vw"}} >
                            {children}
                        </main>
                    </div>
                </MuiThemeProvider >
            </div>
        
     );}
    return (
        <div className="appLayout">
            <MuiThemeProvider theme={themeObject.theme}>
                <CssBaseline />
                <div className="appLayout__header">
                    <Topbar
                        // appInitData={appInitData}
                        handleDrawerAction={() => handleDrawerAction(!isDrawerOpen)}
                        isDrawerOpen={isDrawerOpen}
                    />
                </div>
                <div className="appLayout__container">
                    <Sidebar
                        isDrawerOpen={isDrawerOpen}
                        sidebarOptions={sidebarOptions}
                    />
                    <main className="appLayout__children">
                        {children}
                    </main>
                </div>
            </MuiThemeProvider >
        </div>
    );
}