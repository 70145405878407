import React, { useState, useEffect } from 'react';
import { Table, Grid, TableHead, TableRow, withStyles, TableCell, TableBody, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinearProgress from '@material-ui/core/LinearProgress';
import Axios from 'axios';


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#123456',
        color: theme.palette.common.white,
        fontSize: '17px',
        textAlign: 'left'
    },
    body: {
        maxWidth: '100%',
        fontSize: 15,
        textAlign: 'left',
        padding: 3,
        paddingLeft: 15,
        borderWidth: 2
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#ededed',
        }
    }
}))(TableRow);


const MyMentorshipsTableStyle = makeStyles(theme => ({
    table: {
        minWidth: '50%',
        maxWidth: '100%',
        borderStyle: 'block',
        borderWidth: '1px'
    },
    actions: {
        height: '18px',
        width: '18px',
    },
    linkStyling:{
        textDecorationLine: "none",
        color:'black',
    }
}))

export default function MyMentorshipsTable() {
    const classes = MyMentorshipsTableStyle();
    const [ideas, setIdeas] = useState([]);
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })

    useEffect(() => {
        Axios.get('/mentorGateway/get/MyMentorships/' + userId)
            .then(response => {
                setIdeas(response.data);
            })
    })

    return (
        <div>
        <Breadcrumbs aria-label="breadcrumb">
            <Link to="/" className={classes.linkStyling}>
                Dashboard
            </Link>
            <Typography color="textPrimary">My Mentorships Table</Typography>
        </Breadcrumbs>
        <Grid item xs={12}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Author</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Contributions</StyledTableCell>
                        <StyledTableCell>Deadline</StyledTableCell>
                        <StyledTableCell style={{minWidth:150}}>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ideas.map(idea => (
                        <StyledTableRow key={idea.ideaId}>
                            <StyledTableCell component="th" scope="row">{idea.title}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{idea.author.username}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{idea.status}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{idea.contribution.toString()}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{Date(idea.deadline).slice(4,15)}</StyledTableCell>
                            <StyledTableCell component="th" style={{minWidth:150}}>
                                <IconButton title="View idea"><OpenInNewIcon className={classes.actions} /></IconButton>
                                <IconButton title="Modify" style={{ color: "#2e56c8" }}><EditIcon className={classes.actions} /></IconButton>
                                <IconButton title="Delete"><DeleteIcon style={{ color: "red" }} className={classes.actions} /></IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
        </div>
    )
}