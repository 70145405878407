import React, { Component } from 'react'
import { Grid, TextField } from '@material-ui/core'
import Mentor from './Mentor';
import axios from 'axios';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

export default function MentorLayout() {

    const [initial, setInitial] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })

    const filterList = (event) => {
        let mentors = initial;
        let searchText = event.target.value;

        mentors = mentors.filter(mentor => {
            const Tags = mentor.tags;
            return (mentor.userName.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || Tags.filter(tag => { return tag.toLowerCase().match(searchText.toLowerCase()) }).length > 0);
        });
        setFiltered(mentors);
    }

    React.useEffect(() => {

        axios.get('/mentorGateway/get/Mentors/MentorLayout/' + userId)
            .then(response => {
                setInitial(response.data)
                setFiltered(response.data)
            });
    }, []);

    return (
        <div style={{ padding: '10px' }}>
            <Grid container justify="center">
                <Grid item xs={12} md={4} style={{ margin: 10, width: '100%' }}>
                    <TextField InputProps={{ style: { height: 40 } }} style={{ width: '100%' }} onChange={filterList} variant="outlined" placeholder="Search using Mentor Name or Interest areas" />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                {filtered
                    .filter(idea => idea.mentor.userId === userId)
                    .map(mentor => <Mentor key={mentor.mentor.userId} data={mentor} />
                    )}
            </Grid>
        </div>
    );
}
