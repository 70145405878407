import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Card, CardHeader, CardContent, withStyles, LinearProgress, Table, TableHead, TableRow, TableCell, IconButton, TableBody } from '@material-ui/core';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import { lighten } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

function generate(element) {
    return [0, 1, 2, 3].map(value =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: '0px',
        height: '100%'
    },
    card: {
        textAlign: 'left',
        height: '100%',
        display: 'flex',
    },
    link: {
        textDecorationLine: 'none'
    },
    campaignStat: {
        width: '23%',
        height: '100%',
        margin: '2%',
        background: 'linear-gradient(230deg, #519242, #c1e7b9 )',
        borderRadius: '5px',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '1rem'
    },
    div: {
        height: '20%',
        display: 'flex',
        marginBottom: '1rem'
    }
}))


const StyledTableCell = withStyles(theme => ({
    head:{
        fontSize:'1em',
        color:'black',
        fontWeight:'bold',
        textAlign: 'center'
    },
    body: {
        padding: 3,
        textAlign:'center'
    }
}))(TableCell);


const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#ededed'
        },
        textAlign: 'center',
        margin:0
    }
}))(TableRow);

const BorderLinearProgress = withStyles({
    root: {
        height: 7,
        backgroundColor: lighten('#519242', 0.5),
        borderRadius: 10
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#519242',
    },
})(LinearProgress);



export default function CampaignCell() {
    const classes = useStyles();

    return (
        <Grid item xs={12} md={12}>
            <Paper className={classes.card}>
                <Card style={{ height: '100%', width: '100%' }}>
                    <Link to="/myCampaigns" className={classes.link}>
                        <CardHeader
                            title="Campaigns: 7"
                            style={{ background: 'linear-gradient(230deg, #c1e7b9, #519242 )', color: 'white' }}
                            titleTypographyProps={{ variant: 'h5' }} />
                    </Link>
                    <div className={classes.div}>
                        <div className={classes.campaignStat}>
                            134<br />Ideas
                        </div><div className={classes.campaignStat}>
                            24<br />Goals
                        </div><div className={classes.campaignStat}>
                            3<br />Finished
                        </div>
                        <div className={classes.campaignStat}>
                            2<br />Deployed
                        </div>
                    </div>
                    <CardContent style={{ paddingBottom: 0 }}>
                        <div style={{ width: '100%', marginBottom: 0, overflowX: 'scroll' }}>
                            <h4 style={{ marginTop: '0px', marginBottom: '0px' }}>Your Most Active Campaigns</h4>
                            <Table style={{ marginBottom: 0, paddingBottom: 0 }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Campaign</StyledTableCell>
                                        <StyledTableCell>Ideas</StyledTableCell>
                                        <StyledTableCell>Progress</StyledTableCell>
                                        <StyledTableCell>Deadline</StyledTableCell>
                                        <StyledTableCell>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {generate(
                                        <StyledTableRow>
                                            <StyledTableCell>Campaign</StyledTableCell>
                                            <StyledTableCell>45</StyledTableCell>
                                            <StyledTableCell>
                                                <BorderLinearProgress variant="determinate" value={60} />
                                            </StyledTableCell>
                                            <StyledTableCell>Dec 31</StyledTableCell>
                                            <StyledTableCell><Link to="/viewCampaign">
                                                <IconButton style={{padding:5}}>
                                                    <OpenInNewIcon style={{ color: 'darkolivegreen'}}/>
                                                </IconButton></Link>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    )
}