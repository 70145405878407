import React from 'react';
import { Table, Grid, TableHead, TableRow, withStyles, TableCell, TableBody, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BrowserRouter as Route, Link } from 'react-router-dom';


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#519242',
        color: theme.palette.common.white,
        fontSize:'17px',
        textAlign:'center'
    },
    body: {
        maxWidth:'100%',
        fontSize: 15,
        textAlign:'center',
        padding:5,
        borderWidth:2
    }
}))(TableCell);
 
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
             backgroundColor: '#ededed',
        }
    }
}))(TableRow);

function createData(IdeaId, Title, progress, ideasGenerated, collaborators, ideasCompleted) {
    return {IdeaId, Title, progress, ideasGenerated, collaborators, ideasCompleted};
}

const rows = [createData('Campaign1', 'Innovation Hub', 60, 40, 125, 15),
    createData('Campaign2', 'Innovation Hub', 60, 40, 125, 15),
    createData('Campaign3', 'Innovation Hub', 60, 40, 125, 16),
    createData('Campaign4', 'Innovation Hub', 60, 40, 125, 15)
];

const useStyles = makeStyles(theme => ({
    table:{
        width:'90%',
        borderWidth:'1px',
        margin: '1rem auto'
    },
    actions:{
        height:'18px',
        width:'18px',
        color: 'darkolivegreen'
    }
}))
const BorderLinearProgress = withStyles({
    root: {
        height: 5,
        backgroundColor: lighten('#519242', 0.5),
        borderRadius: 10
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#519242',
    },
})(LinearProgress);

export default function MyCampaignsTable(){
    const classes=useStyles();
    return(
        <Grid item style={{overflowX: 'auto'}}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Campaign Id</StyledTableCell>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Progress</StyledTableCell>
                        <StyledTableCell>Ideas Generated</StyledTableCell>
                        <StyledTableCell>Collaborators</StyledTableCell>
                        <StyledTableCell>Ideas Completed</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {rows.map(row => (
                    <StyledTableRow key={row.IdeaId}>
                        <StyledTableCell component="th" scope="row">{row.IdeaId}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{row.Title}</StyledTableCell>
                        <StyledTableCell component="th" scope="row"><BorderLinearProgress variant="determinate" value={row.progress}/></StyledTableCell>
                        <StyledTableCell component="th" scope="row">{row.ideasGenerated}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{row.collaborators}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">{row.ideasCompleted}</StyledTableCell>
                        <StyledTableCell component="th" style={{minWidth:150, paddingRight:0, marginRight:0}}>
                            <Link to="/viewCampaign"><IconButton title="View idea"><OpenInNewIcon className={classes.actions}/></IconButton></Link>
                            <Link to="/editCampaign"><IconButton title="Edit"><EditIcon style={{color:"forestgreen"}} className={classes.actions}/></IconButton></Link>
                            <IconButton title="Delete"><DeleteIcon style={{color:"darkslategrey"}} className={classes.actions}/></IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table> 
        </Grid>
    )
}