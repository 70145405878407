import React from 'react';
import axios from 'axios';
import { TableRow, withStyles, TableCell, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';
import CommentIcon from '@material-ui/icons/Comment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import SponsorAnIdea from '../../components/IdeaLayout/View/SponsorAnIdea';
import MentorAnIdea from '../../components/IdeaLayout/View/MentorAnIdea';
import CollaborateAnIdea from '../../components/IdeaLayout/View/CollaborateAnIdea';
import clsx from 'clsx';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import Feature from '../../components/Feature';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                color: "black",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: 1
            }
        }
    }
});

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#13b8a2',
        color: theme.palette.common.white,
        fontSize: '19px',
        textAlign: 'left'
    },
    body: {
        maxWidth: '250px',
        fontSize: 15,
        textAlign: 'left',
        padding: 5,
        borderWidth: 2,
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#ededed',
            maxWidth: '250'
        }
    }
}))(TableRow);

const IdeaTableStyle = makeStyles(theme => {
    return ({
        table: {
            minWidth: '50%',
            maxWidth: '100%',
            borderStyle: 'block',
            borderWidth: '1px'
        },
        actions: {
            height: '18px',
            width: '18px',
        },
        info: {
            padding: '10px',
        },
        likecolor: {
            color: '#1b6deb',
        },
    });
})

export default function IdeaTableRow(props) {
    const classes = IdeaTableStyle();
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const [idea, setIdea] = React.useState(props.idea);
    const [like, setLike] = React.useState(idea.likes);
    
    var desc=''
    if(idea.description != ''){ 
     var temp = JSON.parse(idea.description)
     temp.blocks.map((item)=>{
     desc = item.text
     })
    }
    const handleLike = () => {
        let axiosConfig = {
            headers: {
                //   'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        if (!like.includes(userId)) {
            let temp = like.concat(userId);
            setLike(temp);
            var axios = require('axios');

            var config = {
                method: 'put',
                url: `/ideaGateway/addLike/${idea.ideaId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
        }
        else {
            let temp = like.filter(user => user !== userId);
            setLike(temp);
            var axios = require('axios');

            var config = {
                method: 'put',
                url: `/ideaGateway/deleteLike/${idea.ideaId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
        }
    }

    return (
        <StyledTableRow key={idea._id}>
        <StyledTableCell>{idea.title}</StyledTableCell>
        <StyledTableCell>{idea.username}</StyledTableCell>
        <StyledTableCell>{(desc.length<75)? <text>{desc}</text> :<text>{desc.slice(0,75)+"..."}</text>}
       {(desc.length>75)?(<Tooltip title={desc}><IconButton disableRipple title={desc} style={{ padding: 0, position: 'relative', right: '0px', top: '0px' }}><InfoIcon /></IconButton></Tooltip>):null}
        </StyledTableCell> 
        <Feature name='show-feature'><StyledTableCell style={{ minWidth: 130 }}>{idea.tags.toString()}</StyledTableCell></Feature>
        <StyledTableCell>{idea.status}</StyledTableCell>
        <Feature name='show-feature'>
        <StyledTableCell style={{ minWidth: 120 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <SponsorAnIdea data={false} open={idea.sponsorOpen} ideaId={idea.ideaId} />
                    <MentorAnIdea data={false} open={idea.mentorOpen} tags={idea.tags} ideaId={idea.ideaId} />
                    <CollaborateAnIdea data={false} open={idea.collaboratorOpen} tags={idea.tags} ideaId={idea.ideaId} />
                </div>
            </StyledTableCell>
            </Feature>
            <StyledTableCell style={{ minWidth: 190 }}>
            <Feature name='show-feature'>
                {idea.views.length}<Tooltip title="Views"><IconButton disabled style={{ padding: 6 }}><VisibilityIcon style={{ color: "grey" }} /></IconButton></Tooltip>
                <Tooltip title="Comment"><IconButton style={{ padding: 6 }}><CommentIcon /></IconButton></Tooltip>
                {like.length}<Tooltip title="Like"><IconButton onClick={handleLike} style={{ padding: 6 }} className={clsx(classes.iconButtons, (like.includes(userId) && classes.likecolor))} ><ThumbUpAltIcon /></IconButton></Tooltip>
                </Feature>
                <Tooltip title="Open Idea"><Link to={{ pathname: '/viewIdea/' + idea.ideaId }}>
                    <IconButton style={{ padding: 6 }}><OpenInNewIcon /></IconButton>
                </Link></Tooltip>
            </StyledTableCell>
        </StyledTableRow>
    )
}