import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import React from 'react';
import NumberFormat from 'react-number-format';
import { JustTagDropdown } from '../../../Dropdowns/Dropdowns';


const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'visible',
    }
}));

// TODO - Make MentorRegistation and Sponsor Registration as a single component serving both functionalities
export default function SponsorRegistration({handleClose, userId, open}) {
    
    console.log({userId, handleClose, open});
    const classes = useStyles();
    const [newSponsor, setNewSponsor] = React.useState({
        active: true,
        availableBudget: 0,
        budgetSpent: 0,
        ideas: [],
        interestAreas: [],
        score: 0,
        userId: userId
    });

    function handleTags(e, value) {
        setNewSponsor({ ...newSponsor, ['interestAreas']: value });
    }

    const handleChange = name => event => {
        setNewSponsor({ ...newSponsor, [name]: event.target.value });
    };

    const handleRegistor = () => {
        let axiosConfig = {
            headers: {
                //   'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        axios.post('/sponsorGateway/registerNew', newSponsor,axiosConfig)
            .then(response => {
                handleClose();
            })
    }

    return (
        <div>
            <Dialog open={open} classes={{ paperScrollPaper: classes.root }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Sponsor</DialogTitle>
                <DialogContent className={classes.root}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Available Budget"
                        onChange={handleChange('availableBudget')}
                        autoComplete="title"
                        InputProps={{
                            inputComponent: NumberFormat,
                        }}
                    />
                    <JustTagDropdown data={'Select the areas you would like to Sponsor'} selected={newSponsor.interestAreas} handleDropdown={handleTags} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRegistor} color="primary">
                        Register
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}