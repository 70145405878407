import React from 'react';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles(theme => ({
  display:{
    display:'none',
  },
  sponsorButton:{
    background:'#123456',
    fontSize: '80%',
    color:'#ffffff',
  },
  reject:{
    background:'red',
    fontSize: '80%',
    color:'#ffffff',
  },
}));


export default function SponsorNotification(props){
    const classes=useStyles();
    let spons=props.sponsor;

  const handleSponsorRequestAccept=()=>{
    let user=props.sponsor.sponsor.userId;
    let idea=props.ideaId;
    spons.sponsor.ideas[0].accepted=true;
    spons.sponsor.ideas[0].active=true;
    axios.put('/sponsorGateway/ideas/accept/'+user+','+idea+','+true);
  }

  const handleSponsorRequestDecline=()=>{
    let user=props.sponsor.sponsor.userId;
    let idea=props.ideaId;
    spons.sponsor.ideas[0].accepted=false;
    spons.sponsor.ideas[0].active=false;
    axios.put('/sponsorGateway/ideas/accept/'+user+','+idea+','+false);
  }

    return( 
                <div>
                        <div id={props.sponsor.sponsor.userId} style={{padding:2}}>
                        <Card style={{paddingTop:5}}>
                          {spons.sponsor.ideas[0].active===true?
                            spons.sponsor.ideas[0].accepted===true?
                            <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.sponsor.userName} is now a  sponsor for {props.title}</text>
                              </CardContent>
                              :
                              <div>
                                <CardContent style={{paddingTop:2,paddingBottom:2}}>
                                <text>{props.sponsor.userName} wants to sponsor {props.title} an amount of ${props.sponsor.sponsor.ideas[0].budget}</text>
                                </CardContent>
                                <CardActions style={{paddingTop:0,paddingBottom:0}} >
                                <Button onClick={handleSponsorRequestDecline} className={classes.reject}>Decline</Button>
                                <Button onClick={handleSponsorRequestAccept} className={classes.sponsorButton}>Accept</Button>
                                </CardActions>
                              </div>
                            :
                            <CardContent style={{paddingTop:2,paddingBottom:2}}>
                              <text>{props.sponsor.userName} request to sponsor {props.title} rejected</text>
                              </CardContent>
                            }
                        </Card>
                        </div>
              </div>
    )
}
