import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React from 'react';
import { JustTagDropdown } from '../../../Dropdowns/Dropdowns';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'visible',
    },
}));

// TODO - Make MentorRegistation and Sponsor Registration as a single component serving both functionalities
export default function MentorRegistration({userId, handleClose, open}) {
    console.log({userId, handleClose, open});
    const classes = useStyles();
 
    const [newMentor, setNewMentor] = React.useState({
        active: true,
        ideas: [],
        proficiencies: [],
        score: 0,
        userId: userId
    });

    function handleTags(e, value) {
        setNewMentor({ ...newMentor, ['proficiencies']: value });
    }

    const handleRegister = () => {
        let axiosConfig = {
            headers: {
            //   'Content-Type': 'application/json',
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
          };
        axios.post('/mentorGateway/registerNew', newMentor,axiosConfig)
            .then(response => {
                handleClose();
            })

    }

    return (
        <div>
            <Dialog open={open} classes={{ paperScrollPaper: classes.root }} fullWidth={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Mentor</DialogTitle>
                <DialogContent className={classes.root}>
                    <JustTagDropdown data={'Select the areas you would like to Mentor'} selected={newMentor.proficiencies} handleDropdown={handleTags} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRegister} color="primary">
                        Register
                     </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
} 