import React, { useEffect } from 'react'
import clsx from 'clsx';
import axios from 'axios';
import { BrowserRouter as Route, Link } from 'react-router-dom'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { Card, CardHeader, makeStyles, IconButton, Typography, CardContent, CardActions, Grid } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CommentIcon from '@material-ui/icons/Comment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Comment from './View/Comment';
import MentorAnIdea from './View/MentorAnIdea';
import CollaborateAnIdea from './View/CollaborateAnIdea';
import SponsorAnIdea from './View/SponsorAnIdea';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import MonetizationOnSharp from '@material-ui/icons/MonetizationOnSharp';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import Feature from '../Feature';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                color: "black",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: 1
            }
        }
    }
});

const IdeaStyles = makeStyles(theme => ({
    ideaOutline: {
        padding: '15px',
        overflow: 'hidden'
    },
    ideaHeader: {
        background: "#6ce8d8",
        paddingTop: 10,
        paddingBottom: 5,
        overflow: 'hidden',
        marginBottom: 0
    },
    cardContent: {
        paddingBottom: 0,
        paddingTop: 0
    },
    icons: {
        height: '18px',
        width: '18px',
        marginBottom: '-5px'
    },
    actions: {
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        fontWeight: 'bold',
        margin: '2px'
    },
    info: {
        height: '40px',
        overflow: 'hidden',
        margin: 5
    },
    link: {
        textDecorationLine: 'none',
        color: 'black'
    },
    iconButtons: {
        margin: 3,
        padding: 10
    },
    typography: {
        padding: 5
    },
    likecolor: {
        color: '#1b6deb',
    },
    chips: {
        marginRight: 2,
        marginLeft: 2
    },
    ideaTitle: {
        marginTop: 20,
        marginLeft: 17,
        marginRight: 30,
        color: "#46494C",
        borderRadius: "10px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));


export default function Idea(props) {

    const classes = IdeaStyles();
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const idea = props.data;
    const [like, setLike] = React.useState(idea.likes);
    const [views, setViews] = React.useState(idea.views);
    const [comments, setComments] = React.useState(idea.comments);
    const [userData, setUserData] = React.useState([]);
    const handleLike = () => {
        let axiosConfig = {
            headers: {
                //   'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };
        if (!like.includes(userId)) {
            let temp = like.concat(userId);
            setLike(temp);
            var axios = require('axios');

            var config = {
                method: 'put',
                url: `/ideaGateway/addLike/${idea.ideaId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
        }
        else {
            let temp = like.filter(user => user !== userId);
            setLike(temp);
            var axios = require('axios');

            var config = {
                method: 'put',
                url: `/ideaGateway/deleteLike/${idea.ideaId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
        }
    }
    function handleView() {
        if (!views.includes(userId)) {
            let temp = views.concat(userId);
            setViews(temp);
            console.log(idea.ideaId)
            var config = {
                method: 'put',
                url: `/ideaGateway/addView/${idea.ideaId},${userId}`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
        }
    }
    useEffect(() => {
        axios.get('userGateway/get/user/' + sessionStorage.getItem('id'))
            .then(response => {
                setUserData(response.data.role)
            });
    }, [])
    let a = 0;
    return (
        <Grid item key={idea.title} xs={12} md={6} className={classes.ideaOutline}>
            <Card style={{ minWidth: 300 }}>
                <div className={classes.ideaTitle} style={props.keyVal % 2 ? { background: "#00e6e6" } : { background: "#55ff00" }}>
                    <div style={{ marginLeft: "10px" }}>
                        <Feature name='show-feature'>
                            <p style={{ marginBottom: "5px" }}><strong>1000 coins:</strong>{idea.status}</p>
                        </Feature>
                        <h6>{idea.title}</h6>
                        <p><strong>Ideator: {idea.username}</strong></p>
                    </div>
                </div>
                <CardContent className={classes.cardContent}>
                    {/* <Typography variant="body2" className={classes.typography}>
                        {idea.mailCollaborators.length > 0 ? <p style={{ marginBottom: '0.5rem' }}><strong style={{ color: '#76838F' }}> Collaborators: </strong>{idea.mailCollaborators.toString()}</p> : null}

                        {idea.mailMentors.length > 0 ? <p style={{ marginBottom: '0.5rem' }}><strong style={{ color: '#76838F' }}> Mentors: </strong>{idea.mailMentors.toString()}</p> : null}

                        {idea.mailSponsors.length > 0 ? <p style={{ marginBottom: '0.5rem' }}><strong style={{ color: '#76838F' }}> Sponsors: </strong>{idea.mailSponsors.toString()}</p> : null}

                        <strong style={{ color: '#76838F' }}> Target Industries: </strong>{idea.targetIndustry}
                    </Typography> */}
                    <Feature name='show-feature'>
                        <LocalOfferIcon className={classes.icons} style={{ color: '#76838F' }} />: {idea.tags.toString()}
                    </Feature>
                </CardContent>
                <Grid container style={{ marginTop: 20, marginBottom: 10, marginLeft: 20 }}>
                    <Link to={{ pathname: '/viewIdea/' + idea.ideaId }}>
                        <Chip
                            size="small"
                            icon={<LiveHelpIcon />}
                            onClick={handleView}
                            clickable
                            label="Info"
                            color="secondary"
                            className={classes.chips}
                            style={{ backgroundColor: "#4d7cff", color: "white" }}
                        /></Link>
                    <Feature name='show-feature'>
                        <Comment commentHandle={props.commentHandle} comments={idea.comments} commentPosthandle={props.commentPosthandle} index={props.keyVal - 1} />
                        <Chip
                            size="small"
                            icon={<ThumbUpIcon />}
                            onClick={handleLike}
                            clickable
                            label="Like"
                            color="primary"
                            className={classes.chips}
                        />
                        <Chip
                            size="small"
                            clickable
                            label="Dojo Tools"
                            color="primary"
                            className={classes.chips}
                        />
                        <Chip
                            size="small"
                            clickable
                            label="Git Repo"
                            color="primary"
                            className={classes.chips}
                        />
                        {userData.includes('Ideator') == true &&
                            <>
                                <Chip
                                    size="small"
                                    icon={<DeleteIcon />}
                                    label="Delete"
                                    color="secondary"
                                    className={classes.chips}
                                    style={{ backgroundColor: "#ff5e5e", color: "white" }}
                                />

                                <Link to={{ pathname: '/editIdea/' + idea.ideaId }}>
                                    <Chip
                                        size="small"
                                        icon={<EditIcon />}
                                        clickable
                                        label="Edit"
                                        className={classes.chips}
                                        style={{ backgroundColor: "#f29d56" }}
                                    /></Link></>}
                    </Feature>
                </Grid>

                <Feature name='show-feature'>
                    <Grid style={{ marginLeft: 20 }} container>
                        <MentorAnIdea data={true} open={idea.mentorOpen} ideaId={idea.ideaId} tags={idea.tags} className={classes.button} />
                        <SponsorAnIdea data={true} open={idea.mentorOpen} ideaId={idea.ideaId} />
                        <CollaborateAnIdea data={true} open={idea.mentorOpen} ideaId={idea.ideaId} tags={idea.tags} />
                    </Grid>
                </Feature>
                <hr style={{ marginBottom: 0 }} />
                <Feature name='show-feature'>
                    <CardActions className={classes.actions}>
                        {views.length}<Tooltip title="Views"><IconButton className={classes.iconButtons} disabled style={{ padding: 6 }}><VisibilityIcon style={{ color: "grey" }} /></IconButton></Tooltip>
                        <span />{like.length}<Tooltip title="Likes"><IconButton onClick={handleLike} style={{ padding: 6 }} className={clsx(classes.iconButtons, (like.includes(userId) && classes.likecolor))} ><ThumbUpAltIcon /></IconButton></Tooltip>
                        <span />{comments.length}<Tooltip title="Comment"><IconButton className={classes.iconButtons} style={{ padding: 6 }}><CommentIcon /></IconButton></Tooltip>
                        {/* <Tooltip title="Mentors"><PeopleIcon className={classes.button} /></Tooltip>{idea.mailMentors.length}
                    <Tooltip title="Sponsors"><MonetizationOnSharp className={classes.button} /></Tooltip>{idea.mailSponsors.length}
                    <Tooltip title="Collaborators"><GroupWorkIcon className={classes.button} /></Tooltip>{idea.mailCollaborators.length} */}
                        <text>
                            <h6 style={{ marginTop: 10, padding: 6, float: 'left' }}>Last Updated: 3 hrs ago</h6>
                        </text>
                    </CardActions>
                </Feature>

            </Card>
        </Grid>
    )
}