const LocalStorageService = (function(){
    var _service;
    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function _setToken(property, value) {
        if (typeof value === 'object') {
            localStorage.setItem(property, JSON.stringify(value));
        } else {
            localStorage.setItem(property, value);
        }
        return true;
    }

    function _getToken(property) {
                let value = localStorage.getItem(property);
                value = JSON.parse(value);
        return value;
    }
    
    function _clearToken(property) {
        localStorage.removeItem(property);
        return true;
    }

   return {
      getService : _getService,
      setToken : _setToken,
      getToken : _getToken,
      clearToken : _clearToken
    }
   })();
   export default LocalStorageService;