import React, { useState, useEffect } from 'react'
import { Grid, Card, Button, Paper, CardHeader, CardContent, ListItem, List, ListItemText, IconButton, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Radar } from 'react-chartjs-2'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Feature from '../../Feature';

const data = {
    labels: ['Creativity', 'Productivity', 'Support', 'Versatility', 'Delivery'],
    datasets: [
        {
            label: 'Activity',
            data: [2.5, 2.5, 2.5, 2.5, 2.5],
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: '#7e18d8',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#7e18d8',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
        }
    ]
};

const statsType =
{
    "ideas": 0,
    "solutions": 0,
    "mvpStage": 0,
    "collaborators": 0,
    "mentors": 0,
    "sponsors": 0
};

const options = {
    maintainAspectRatio: false,
    scale: {
        gridLines: {
            color: "#CCCCCC"
        },
        pointLabels: {
            fontSize: 13
        },
        ticks: {
            beginAtZero: true,
            max: 5,
            min: 0,
            stepSize: 1
        }
    },
    legend: {
        labels: {
            fontSize: 15
        }
    }
}


const StyledListItem = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#ededed'
        }
    }
}))(ListItem);


const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: '2px',
        height: '100%'
    },
    card: {
        height: '130px',
        textAlign: 'center',
        maxHeight: '300px',
    },
    header: {
        paddingLeft: '20px',
        fontFamily: 'Trebuchet MS',
        fontSize: '2.2em'
    },
    icon: {
        height: '20px',
        width: '20px',
        margin: '2px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '5px',
        height: '100%',
        maxHeight: '300px'
    },
    ideaStats: {
        height: '130px',
        textAlign: 'center',
        maxHeight: '300px',
        width: '22%',
        margin: '1.5%',
        background: 'linear-gradient(230deg, #7e18d8, #a99ad7)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}));


export default function IdeaCell() {
    let userId = '';
    useSelector(state => {
        userId = state.appReducer.user;
    })
    const classes = useStyles();
    const [myIdeas, setMyIdeas] = useState([]);
    const [stats, setStats] = useState(statsType);

    useEffect(() => {

        axios.all([
            axios.get('/ideaGateway/getMyIdeasByUserId/' + userId)
                .then(response => {
                    setMyIdeas(response.data);
                }),
            axios.get('/ideaGateway/ideaCellStats/' + userId)
                .then(response => {
                    setStats(response.data);
                })
        ])
    }, []);

    return (
        <Grid item xs={12} md={12} className={classes.grid}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                    <Paper className={classes.paper}>
                        <Card className={classes.ideaStats}>
                            <CardHeader title={stats.ideas} style={{ padding: '0' }} />
                            <CardContent style={{ padding: '0' }}>
                                <Typography>Ideas Generated</Typography>
                            </CardContent>
                        </Card>

                        <Card className={classes.ideaStats}>
                            <CardHeader title={stats.mvpStage} style={{ padding: '0' }} />
                            <CardContent style={{ marginTop: '0', padding: '0' }}>
                                <Typography>MVP Stage</Typography>
                            </CardContent>
                        </Card>

                        <Card className={classes.ideaStats}>
                            <CardHeader title={stats.solutions} style={{ padding: '0' }} />
                            <CardContent style={{ padding: '0' }}>
                                <Typography>Solutions</Typography>
                            </CardContent>
                        </Card>
                        <Feature name='show-feature'>
                            <Card className={classes.ideaStats} style={{ backgroundColor: '#13b8a2', color: 'white' }}>
                                <List style={{ margin: '0', padding: '0', fontSize: '1rem' }}>
                                    <ListItem disableGutters style={{ paddingTop: '0' }} />Collaborators: {stats.collaborators}
                                    <ListItem disableGutters style={{ paddingTop: '0' }} />Mentors: {stats.mentors}
                                    <ListItem disableGutters style={{ paddingTop: '0' }} />Sponsors: {stats.sponsors}
                                </List>
                            </Card>
                        </Feature>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Card className={classes.card} style={{ height: '100%', margin: '5px', maxHeight: '300px' }}>
                        <CardHeader style={{ textAlign: 'left', background: 'linear-gradient(230deg, #7e18d8, #a99ad7)', color: 'white' }} title="Your most Recent Ideas" />
                        <CardContent style={{ padding: '0px', minHeight: 240, overflowY: 'scroll' }}>
                            {myIdeas.filter(idea => { return (idea.deleted !== true) }).length > 0 ?
                                <List dense={true} style={{ margin: '0px', padding: '0px' }}>
                                    {myIdeas.filter(idea => { return (idea.deleted !== true) }).slice(0, 4).map(myIdea => (
                                        <StyledListItem>
                                            <ListItemText primary={myIdea.title} />
                                            <p style={{ color: 'grey' }}>{myIdea.status}</p>
                                            <Link to={{ pathname: '/viewIdea/' + myIdea.ideaId }}>
                                                <IconButton title="Open Idea"><OpenInNewIcon /></IconButton>
                                            </Link>
                                        </StyledListItem>
                                    ))}
                                </List> :
                                <Typography style={{ fontSize: '0.875rem', color: 'grey', fontWeight: 'bold', padding: 20 }}>You haven't added any ideas yet, we encourage you to add new ideas <br /> Click on the create idea button on the top to add new idea</Typography>}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={5}>
                    <Card style={{ height: '100%', width: '100%', textAlign: 'center', margin: '5px', maxHeight: '300px', minHeight: '250px' }}>
                        <div style={{ height: '80%', maxHeight: '380px', marginBottom: '24px' }}>
                            <Radar data={data} options={options} />
                        </div>
                        <Link to="/myIdeas" style={{ textDecorationLine: 'none' }}><Button style={{ width: '100%', background: 'linear-gradient(230deg, #7e18d8, #a99ad7)', marginBottom: '0px', color: 'white' }} variant="outlined">View All My Ideas<ArrowForwardIcon /></Button></Link>
                    </Card>
                </Grid>
            </div>
        </Grid>
    )
}