import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import { TagDropdown } from '../../Dropdowns/Dropdowns';
import { IdeaStatus } from '../../Dropdowns/Status';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from 'react-redux';
import Feature from '../../Feature';
import { CampaignIdeaDropdown } from '../../Dropdowns/Dropdowns';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const ideaType = {
  "ideaId": '',
  "title": '',
  "author": {
    "userId": '',
    "username": '',
    "group": '',
    "pictureUrl": '',
    "role": [''],
    "proficiency": [''],
    "areaOfInterest": [''],
    "emailId": '',
    "mobileNumber": '',
    "createdBy": '',
    "timeCreated": "2019-10-16T08:19:37.868+0000",
    "timeEdited": "2019-10-16T08:19:37.868+0000",
    "lastActive": "2019-10-16T08:19:37.868+0000",
    "active": true
  },
  "description": '',
  "problemStatement": '',
  "proposedSolution": '',
  "impact": '',
  "status": '',
  "tags": [''],
  "sponsors": [
    {
      "sponsor": {
        "userId": '',
        "budgetSpent": 0,
        "availableBudget": 0,
        "ideas": [
          {
            "operation": 1,
            "budget": 0,
            "timeLog": [
              {
                "timeOfAllocation": "2019-10-17T04:49:22.851",
                "timeCreated": "2019-10-17T04:49:22.851",
              }
            ],
            "timeEdited": "2019-10-17T04:49:22.851",
            "active": true,
            "ideaId": ''
          }
        ],
        "score": 0,
        "active": true
      },
      "userName": '',
      "tags": ['']
    }
  ],
  "mentors": [
    {
      "mentor": {
        "userId": '',
        "ideas": [
          {
            "ideaId": '',
            "contribution": [''],
            "information": [''],
            "timeLog": [
              {
                "timeOfAllocation": "2019-10-17T04:49:22.851",
                "timeCreated": "2019-10-17T04:49:22.851",
              }
            ],
            "active": true
          }
        ],
        "score": 0,
        "active": true
      },
      "userName": '',
      "tags": ['']
    }
  ],
  "collaborators": [
    {
      "collaborator": {
        "userId": '',
        "ideas": [
          {
            "ideaId": '',
            "contribution": [''],
            "deliverables": [''],
            "progress": 0,
            "timeLog": [
              {
                "timeOfAllocation": "2019-10-17T04:49:22.851",
                "timeCreated": "2019-10-17T04:49:22.851",
              }
            ],
            "active": true
          }
        ],
        "score": 0,
        "active": true
      },
      "userName": '',
      "tags": ['']
    }
  ],
  "resources": [
    {
      "link": '',
      "description": ''
    }
  ],
  "achievements": [
    ''
  ],
  "likes": [
    ''
  ],
  "views": [
    ''
  ],
  "timeCreated": "2019-10-16T13:46:55.581",
  "timeEdited": "2019-10-16T08:15:53.418",
  "estimatedDeadline": "2019-10-16T08:15:53.418",
  "originIndustries": '',
  "targetIndustries": [],
  "mentorOpen": true,
  "sponsorOpen": true,
  "collaboratorOpen": true,
  "isDeleted": false

}


const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '50%',
  },
  input: {
    margin: theme.spacing(1),
  },
  popbutton: {
    color: 'white',
    marginTop: '8px',
    marginRight: '20px',
    borderColor: 'white'

  },
  button2: {
    textAlign: 'center',
  },
  button: {
    textAlign: 'right',
  },
  title: {
    background: 'linear-gradient(230deg, #13b8a2, #00d4ff)',
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '1.7rem'
  },
  content: {
    flex: 'grow',
  },
  textfield: {
    width: '100%',
  },
  switch: {
    width: '5%',
    left: '25%',
  },
  item: {
    overflow: 'hidden',
    marginLeft: 20
  },
  box1: {
    background: '#F5F5F5',
  },
  hei: {
    marginTop: '5%',
    top: '5%',
    marginLeft: '10%',
    maxHeight: '87%',
    maxWidth: '80%',
  },
  cardHead: {
    margin: '0',
    padding: '0',
    marginTop: '4px',
    marginBottom: '4px',
  },
  display: {
    display: 'none',
  },
  bulb: {
    color: 'gold',
    fontSize: '50px'
  },
  coin: {
    color: 'gold'
  }
}));

const industries = ['Consumer Products', 'Energy & Commodities', 'Financial Services', 'Health', 'Public Sector', 'Retail', 'Telecommunications, Media & Technology', 'Transportation & Mobility', 'Travel & Hospitality', 'Internal', 'Others'];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditIdea = (props) => {
  const classes = useStyles();
  let userId = '';
  useSelector(state => {
    userId = state.appReducer.user;
  })


  const [idea, setIdea] = React.useState(ideaType);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [emailValMentor, setEmailValMentor] = useState("");
  const [emailValSponsor, setEmailValSponsor] = useState("");
  const [emailValCollab, setEmailValCollab] = useState("");
  const [sponsors, setSponsors] = React.useState(ideaType.mailSponsors);
  const [mentors, setMentors] = React.useState(ideaType.mailMentors);
  const [collaborators, setCollaborators] = React.useState(ideaType.mailCollaborators);
  const [mssg, setMssg] = React.useState(false);
  const [flag, setFlag] = React.useState(true);

  useEffect(() => {
    axios.get('/ideaGateway/viewIdea/' + props.match.params.ideaId)
      .then(response => {
        setIdea(response.data);
        setSponsors(response.data.sponsors);
        setMentors(response.data.mentors);
        setCollaborators(response.data.collaborators);
        setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.description))))
      });
  }, []);

  const [error, setError] = useState('');

  const handleLinkText = (i, name) => e => {
    let resource = idea.resources
    resource[i].link = e.target.value
    setIdea({ ...idea, [name]: resource });
  }

  const handleDelete = (i, name) => e => {
    e.preventDefault()
    let resource = idea.resources.filter(r => idea.resources.indexOf(r) !== i)
    setIdea({ ...idea, [name]: resource });
  }

  const value = "";

  function handleMentorMailKey(e) {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      if (isValid(emailValMentor)) {
        let ideas = idea;
        ideas.mailMentors.push(emailValMentor);

        setIdea(ideas);
        setEmailValMentor("");
      }
    }
  };

  function handleMentorMail(e) {
    setEmailValMentor(e.target.value);
    setError('');
  }

  function handleSponsorMailKey(e) {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      if (isValid(emailValSponsor)) {
        let ideas = idea;
        ideas.mailSponsors.push(emailValSponsor);

        setIdea(ideas);
        setEmailValSponsor("");
      }
    }
  };

  function handleSponsorMail(e) {
    setEmailValSponsor(e.target.value);
    setError('');
  }

  function handleCollaboratorMailKey(e) {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      if (isValid(emailValCollab)) {
        let ideas = idea;
        ideas.mailCollaborators.push(emailValCollab);

        setIdea(ideas);
        setEmailValCollab("");

      }
    }
  };

  function handleCollaboratorMail(e) {
    setEmailValCollab(e.target.value);
    setError('');
  }

  function isValid(email) {

    if (!isEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return false;
    }
    return true;
  }
  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  function handleMailDelete(name, item) {
    if (name === 'mailMentors') {
      setIdea({ ...idea, ['mailMentors']: idea.mailMentors.filter(i => i !== item) });
    }

    if (name === 'mailSponsors') {
      setIdea({ ...idea, ['mailSponsors']: idea.mailSponsors.filter(i => i !== item) });
    }

    if (name === 'mailCollaborators') {
      setIdea({ ...idea, ['mailCollaborators']: idea.mailCollaborators.filter(i => i !== item) });
    }
  }

  function handleOriginIndustries(e, value) {
    setIdea({ ...idea, ['originIndustries']: value });

  };

  function handleTargetIndustries(e, value) {
    setIdea({ ...idea, ['targetIndustries']: value });

  };

  const handleAddResource = (name) => e => {
    let temp = {
      link: '',
      description: ''
    }
    e.preventDefault()
    let resource = idea.resources.concat(temp)
    setIdea({ ...idea, [name]: resource });
  }

  const handledescriptionText = (i, name) => e => {
    let resource = idea.resources
    resource[i].description = e.target.value
    setIdea({ ...idea, [name]: resource });
  }

  const handleSwitchChange = (name) => event => {
    setIdea({ ...idea, [name]: event.target.checked });
  };

  const handleChange = name => event => {
    setIdea({ ...idea, [name]: event.target.value });

    if (idea.title.length !== 0 && idea.description.length !== 0 && idea.problemStatement.length !== 0 && idea.proposedSolution.length !== 0 && idea.impact.length !== 0) {
      setMssg(false);
    }

  };

  const cancelButtonHandler = () => {
    props.history.push('/');
  };

  const EditIdea = () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    if ((idea.status === 'Draft' || (idea.title.length !== 0 && idea.description.length !== 0 && idea.problemStatement.length !== 0 && idea.proposedSolution.length !== 0 && idea.impact.length !== 0))) {
      axios.put('/ideaGateway/editIdea/' + idea.ideaId, idea, axiosConfig)
        .then(response => {
          props.history.push('/viewIdea/' + idea.ideaId);
        })
    }
    else {
      setMssg(true);
    }
  }

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  function handleDate(date, name) {
    setIdea({ ...idea, [name]: date });
  }
  function handleDateChange(date) {
    setSelectedDate(date);
    handleDate(selectedDate, 'estimatedDeadline');
  }

  function handleTags(e, value) {
    setIdea({ ...idea, ['tags']: value });
    if (mssg === true) {
      if (idea.title.length !== 0 && idea.description.length !== 0 && idea.problemStatement.length !== 0 && idea.proposedSolution.length !== 0 && idea.impact.length !== 0 && value.length !== 0) {
        setMssg(false);
      }
    }
  }

  function handleGoals(e, value) {
    setIdea({ ...idea, ['goals']: value });
  }

  const onEditorChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const val = JSON.stringify(convertToRaw(contentState));
    setIdea({ ...idea, ['description']: val });
  }
  const onfocusfn = () => {
    setFlag(false)
  }
  const onFocusOutfn = () => {
    setFlag(true)
  }
  return (
    <div>
      <Grid container className={classes.box1}>
        <Grid item className={classes.title}>
          <DialogTitle id="form-dialog-title"><span style={{ fontSize: '50px' }}>Edit your Idea <EmojiObjectsSharp className={classes.bulb} /></span></DialogTitle>
        </Grid>

        <Grid xs={12} className={clsx(!mssg && classes.display)}>
          <text style={{ color: 'red' }}><strong>Please fill out the fields with * mark</strong></text>
        </Grid>

        <Grid item xs={12} md={10} className={classes.item} style={{ marginTop: '1rem' }}>
          <Card className={classes.card}>
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>

              <TextField
                variant="outlined"
                margin="normal"
                required
                error={mssg && (idea.title.length === 0)}
                fullWidth
                style={{ marginTop: '10px', marginBottom: '10px' }}
                label="Title"
                name="title"
                value={idea.title}
                helperText="Max length:60 Characters"
                onChange={handleChange('title')}
                inputProps={{ maxLength: 60 }}

              />
              {flag == true &&

                <div style={{ marginTop: "-90px" }} onFocus={onfocusfn}>
                  <Editor
                    name="description"
                    toolbarOnFocus
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorChange}
                    placeholder=" Enter a brief description about your project*"
                  /> </div>
              }
              {flag == false &&

                <div style={{ marginTop: "0px" }} onBlur={onFocusOutfn}>
                  <Editor
                    name="description"
                    toolbarOnFocus
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorChange}
                    placeholder=" Enter a brief description about your project*"
                  /> </div>
              }

              {/*<TextField
                variant="outlined"
                margin="normal"
                required
                error={mssg && (idea.title.length === 0)}
                fullWidth
                style={{ marginTop: '10px', marginBottom: '10px' }}
                label="Description"
                name="description"
                onChange={handleChange('description')}
              />*/}
            </CardContent>
          </Card>
        </Grid>



        <Grid xs={12} md={10} style={{ overflow: 'visible', height: '100%' }} className={classes.item}>
          <Card className={classes.card} style={{ overflow: 'visible' }}>
            <CardContent style={{ paddingTop: '4px', paddingBottom: '4px' }}>
              <CardHeader className={classes.cardHead} title="Industries"></CardHeader>
              <Grid container>
                <Grid xs={12} md={5} className={classes.item}>
                  <Autocomplete
                    options={industries}
                    value={idea.originIndustries}
                    id="combo-box-demo"
                    onChange={handleOriginIndustries}
                    getOptionLabel={(option) => option}
                    style={{ width: 400, marginTop: 10 }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Originating Industry" placeholder="Originating Industry" />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={5} className={classes.item}>
                  <Autocomplete
                    multiple
                    options={industries}
                    value={idea.targetIndustries}
                    id="checkboxes-tags-demo"
                    onChange={handleTargetIndustries}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    style={{ width: 400, marginTop: 10 }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Target Industries" placeholder="Add More" />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={10} className={classes.item}>
          <Card className={classes.card} >
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
              <CardHeader className={classes.cardHead} title="Describe your Idea"></CardHeader>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={idea.problemStatement}
                error={mssg && (idea.problemStatement.length === 0)}
                style={{ marginTop: '10px', marginBottom: '10px' }}
                label="Problem statement"
                name="Problem statement"
                onChange={handleChange('problemStatement')}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                value={idea.proposedSolution}
                required
                error={mssg && (idea.proposedSolution.length === 0)}
                style={{ marginTop: '5px', marginBottom: '10px' }}
                label="Proposed Solution"
                name="Proposed Solution"
                multiline="true"
                rows='2'
                onChange={handleChange('proposedSolution')}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                value={idea.impact}
                required
                error={mssg && (idea.impact.length === 0)}
                style={{ marginTop: '5px', marginBottom: '10px' }}
                label="Proposed Impact"
                name="Impact"
                onChange={handleChange('impact')}
              />
            </CardContent>
          </Card>
        </Grid>

        <Feature name='show-feature'>
          <Grid item xs={12} md={10} className={classes.item}>
            <Card className={classes.card} >
              <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                <CardHeader className={classes.cardHead} title="Teams"></CardHeader>
                <CardContent style={{ padding: '0.5rem 1rem' }}>

                  <p style={{ margin: '0', marginBottom: '0.5rem' }}>
                    <strong>Do you want to invite?</strong>
                    {error && <Alert variant="filled" severity="error">{error}</Alert>}
                  </p>
                  <text>
                    <Grid container>
                      <Grid xs={12} md={1}>
                        <strong>Mentors :</strong>
                      </Grid>
                      <Grid className={classes.item} xs={12} md={2}>
                        <Switch
                          checked={idea.mentorOpen}
                          onChange={handleSwitchChange('mentorOpen')}
                          value="mentor"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          size="large"
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {idea.mentorOpen === true ? <TextField variant="outlined"
                          margin="normal"
                          size="small"
                          required
                          value={emailValMentor}
                          style={{ marginTop: '5px', marginBottom: '10px', width: '100%' }}
                          label="Add E-Mail Address"
                          name="Mentor"
                          onKeyDown={handleMentorMailKey}
                          onChange={handleMentorMail}
                        /> : <p>Enable to add email address</p>}
                      </Grid>
                      {/* <Grid className={classes.item} xs={12} md={4}>
     {idea.mailMentors.map(item => (
          <Chip size="small"
                label={item} style={{margin: "10px"}} onDelete={e => handleMailDelete('mailMentors',item)}>
              &times;
              </Chip>
        ))}
      </Grid> */}
                      <br />
                      <Grid xs={12} md={1}>
                        <strong>Sponsors:</strong>
                      </Grid>
                      <Grid className={classes.item} xs={12} md={2}>
                        <Switch
                          checked={idea.sponsorOpen}
                          onChange={handleSwitchChange('sponsorOpen')}
                          value="sponsor"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          size="large"
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {idea.sponsorOpen === true ? <TextField variant="outlined"
                          margin="normal"
                          size="small"
                          required
                          value={emailValSponsor}
                          style={{ marginTop: '5px', marginBottom: '10px', width: '100%' }}
                          label="Add E-Mail Address"
                          name="Sponsor"
                          onKeyDown={handleSponsorMailKey}
                          onChange={handleSponsorMail} /> : <p>Enable to add E-Mail address</p>}
                      </Grid>
                      {/* <Grid className={classes.item} xs={12} md={4}>
      {idea.mailSponsors.map(item => (
          <Chip size="small"
                label={item} style={{margin: "10px"}} onDelete={e => handleMailDelete('mailSponsors',item)}>
              &times;
              </Chip>
        ))}
        </Grid> */}
                      <br />
                      <Grid xs={12} md={1}>
                        <strong>Collaborators:</strong>
                      </Grid>
                      <Grid className={classes.item} xs={12} md={2}>
                        <Switch
                          checked={idea.collaboratorOpen}
                          onChange={handleSwitchChange('collaboratorOpen')}
                          value="collaborator"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          size="large"
                        />
                      </Grid>
                      <Grid className={classes.item} xs={12} md={4}>
                        {idea.collaboratorOpen === true ? <TextField variant="outlined"
                          margin="normal"
                          size="small"
                          required
                          value={emailValCollab}
                          style={{ marginTop: '5px', marginBottom: '10px', width: '100%' }}
                          label="Add E-Mail Address"
                          name="Collaborator"
                          onKeyDown={handleCollaboratorMailKey}
                          onChange={handleCollaboratorMail} /> : <p>Enable to add E-Mail Adress</p>}
                      </Grid>
                      {/* <Grid className={classes.item} xs={12} md={4}>
      {idea.mailCollaborators.map(item => (
          <Chip size="small"
                label={item} style={{margin: "10px"}} onDelete={e => handleMailDelete('mailCollaborators',item)}>
              &times;
              </Chip>
        ))}
        </Grid> */}
                      <br />
                    </Grid>
                  </text>
                </CardContent>
              </CardContent>
            </Card>
          </Grid>
        </Feature>
        <Grid xs={12} md={10} style={{ overflow: 'visible', height: '100%' }} className={classes.item}>
          <Card className={classes.card} style={{ overflow: 'visible' }}>
            <CardContent style={{ paddingTop: '4px', paddingBottom: '4px' }}>
              <Grid container>
                {/* <Grid xs={12} md={5} style={{marginRight: 10, marginLeft: 10}}>
          <TagDropdown data={'Tags *'} selected={idea.tags} mssg={mssg} tooltip={' Tag your content to make it easier to find'} handle={handleTags} />
          </Grid> */}
                <Grid xs={12} md={5} style={{ marginRight: 10, marginLeft: 10 }}>
                  <IdeaStatus data={idea.status} handleChange={handleChange} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={12} md={5} style={{ marginRight: 10, marginLeft: 10 }}>
                  <CampaignIdeaDropdown data={'Campaign Goals'} selected={idea.campaignGoals} mssg={false} tooltip={' Use ideate button in campaign details to create an idea for a specific goal'} />
                </Grid>
                <Feature name='show-feature'>
                  <Grid xs={12} md={5} style={{ marginRight: 10, marginLeft: 10 }}>
                    <div style={{ marginLeft: 8 }}>
                      <text>
                        <p style={{ marginBottom: '2px' }}>
                          <strong>Your PS coins:<SettingsInputSvideoIcon className={classes.coin}></SettingsInputSvideoIcon></strong>
                        </p>
                      </text>
                      <TextField id="standard-basic" fullWidth defaultValue={0} inputProps={{ readOnly: true }} />
                    </div>
                  </Grid>
                </Feature>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} md={10} className={classes.item}>
          <Card className={classes.card} >
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <CardHeader className={classes.cardHead} title="Resources" action={<IconButton variant="contained" title="Add new Resource" style={{ padding: '8px', marginTop: '1rem', backgroundColor: '#e0e0e0' }} onClick={handleAddResource('resources')} >
                <AddIcon style={{ color: '#3f51b5' }} />
              </IconButton>} />
              {idea.resources.map((res, index) => (
                <Grid container id={index} >
                  <Grid xs={12} md={5} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      label="Description"
                      defaultValue={res.description}
                      onChange={handledescriptionText(index, 'resources')}
                    /></Grid>
                  <Grid xs={12} md={6} style={{ padding: '0.3%' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Link"
                      multiline="false"
                      style={{ marginTop: '5px', marginBottom: '5px' }}
                      defaultValue={idea.resources[index].link}
                      onChange={handleLinkText(index, 'resources')}
                    />
                  </Grid>
                  <Grid xs={1} md={1} className='item' style={{ padding: '0.3%' }}>
                    <Button variant="contained" title="Delete Resource" style={{ marginTop: '12px' }} onClick={handleDelete(index, 'resources')} ><DeleteIcon style={{ color: 'palevioletred' }} /></Button></Grid></Grid>
              ))}

            </CardContent>
          </Card>
        </Grid>

        <Grid container style={{ marginBottom: "15px" }}>
          <Grid item md={5} style={{ padding: '1%', textAlign: 'right' }} className="justify-xs-center">
            <Button variant="contained" className={classes.button} onClick={cancelButtonHandler} >
              Cancel
            </Button>
          </Grid>

          <Grid item md={5} style={{ padding: '1%', textAlign: 'left' }} className="justify-xs-center">
            <Button variant="contained" className={classes.button} onClick={EditIdea} >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>


  );
}

export default EditIdea; 