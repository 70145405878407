import React, { useState, useEffect } from 'react'
import { Grid, Card, CardHeader, CardContent, List, ListItem, ListItemText,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import axios from "axios"
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: '2px',
        height: '100%'
    },
    button: {
        margin: 0,
        padding: '2px',
    },
    card: {
        height: '120px',
        textAlign: 'center'
    },
    header: {
        paddingLeft: '20px',
        fontFamily: 'Trebuchet MS',
        fontSize: '2.2em'
    },
    link: {
        textDecorationLine: 'none'
    }
}));

export default function MentorCell() {
    const classes = useStyles();
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })
    const [ideas, setIdeas] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    useEffect(() => {
        
        axios.all([
            axios.get('/mentorGateway/get/mentorIdeaRecommendations/' + userId)
                .then(response => {
                    setRecommendations(response.data.slice(0,3));
                }),
            axios.get('/mentorGateway/get/mentorIdeas/' + userId)
                .then(response => {
                    setIdeas(response.data.slice(0,3));
                })
        ])
    }, []);

    return (
        <Grid item xs={12} md={6} className={classes.grid}>
            <Card style={{ height: '100%' }}>
                <Link to="/myMentorships" className={classes.link}>
                    <CardHeader
                        title={`Mentored Ideas: ${ideas.length}`}
                        titleTypographyProps={{ variant: 'h5' }}
                        style={{ background: '#ff713b', color: 'white' }} />
                </Link>
                <CardContent style={{ display: 'flex', flexWrap: 'wrap', height: '100%', paddingBottom: '0px' }}>
                    <div style={{ width: '50%',padding: 10 }}>
                        <h4 style={{ margin: 0 }}>Your Most Recent Ideas</h4>
                        <List dense={true}>
                            {ideas.length > 0 ?
                                (
                                    ideas.map(Idea => (
                                        <Link to={{ pathname: '/viewIdea/' + Idea.ideaId }} style={{ textDecorationLine: "none", color: 'black' }}>
                                            <ListItem button key={Idea.ideaId} style={{ borderBottomStyle: "solid", borderColor: '#e0e0e0', borderWidth: 2 }}>
                                                <ListItemText primary={Idea.title} />
                                            </ListItem>
                                        </Link>
                                    ))
                                ) :
                                (
                                    <Typography style={{ marginTop: 10, color: 'grey', fontWeight: 'bold' }}>
                                        You haven't mentored and ideas yet
                                        </Typography>
                                )
                            }
                        </List>
                    </div>
                    <div style={{ width: '50%', height: '150px', paddingLeft: '20px', overflow: 'hidden' }}>
                        <b style={{ color: 'red' }}>Alerts</b>
                        <ul style={{ listStyleType: 'none' }}>
                            <Typography style={{ color: 'grey', fontWeight: 'bold' }}>
                                Feature to be rolled out soon...Stay tuned..!
                            </Typography>
                        </ul>
                    </div>
                    <div style={{ width: '100%', marginBottom: '0px',marginTop:'15px' }}>
                        <CardHeader
                            title="Ideas you might be interested to mentor:"
                            style={{ padding: '5px', margin: '5px', height: '35px' }}
                            titleTypographyProps={{ variant: 'h6' }} />
                        <List dense={true}>
                            {recommendations.map(Idea => (
                                <Link to={{ pathname: '/viewIdea/' + Idea.ideaId }} style={{ textDecorationLine: "none", color: 'black' }}>
                                    <ListItem button key={Idea.ideaId} style={{ borderBottomStyle: "solid", borderColor: '#e0e0e0', borderWidth: 2 }}>
                                        <ListItemText primary={Idea.title} />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    )
}