import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import MonetizationOnSharp from '@material-ui/icons/MonetizationOnSharp';
import People from '@material-ui/icons/People';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Feature from '../../../Feature';
import MentorRegistration from './MentorRegistration';
import './Operations.scss';
import SponsorRegistration from './SponsorRegistration';

function Operations({ appInitData }) {
    let userId = '';
    useSelector(state => {
        console.log({ state });
        userId = state.appReducer.user;
    })

    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
    const [sponsorRegistrationDialogOpen, setSponsorRegistrationDialogOpen] = React.useState(false);
    const [mentorRegistrationDialogOpen, setMentorRegistrationDialogOpen] = React.useState(false);

    return (
        <div className="operations">
            <Tooltip title="Operations">
                <IconButton onClick={(event) => setPopoverAnchorEl(event.currentTarget)}>
                    <AppsRoundedIcon style={{ color: 'white' }} />
                </IconButton>
            </Tooltip>
            <Popover
                id={'operations-popover'}
                open={Boolean(popoverAnchorEl)}
                anchorEl={popoverAnchorEl}
                onClose={() => setPopoverAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="operations__overlay">
                    <Row>
                        <Col md={6} sm={6} xs={12}>
                            <div className={'operations__bulbIcon'} onClick={() => setPopoverAnchorEl(null)}>
                                <Link to="/createIdea">
                                    <Button variant="outlined">
                                        <EmojiObjectsSharp />
                                        Add Idea
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <div className="operations__barChartIcon" onClick={() => setPopoverAnchorEl(null)}>
                                <Link to="/createCampaign">
                                    <Button variant="outlined">
                                        <BarChartIcon />
                                        Start Campaign
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Feature name='show-feature'>
                            <Col md={6} sm={6} xs={12}>
                                {(appInitData.mentorsList || []).filter(r => r.mentor.userId === userId).length === 0 ? (
                                    <div className="operations__peopleIcon" onClick={() => { setMentorRegistrationDialogOpen(true); setPopoverAnchorEl(null) }}>
                                        <Button variant="outlined">
                                            <People />
                                            Register as Mentor
                                        </Button>
                                    </div>)
                                    :
                                    (
                                        <div className="operations__peopleIcon" onClick={() => setPopoverAnchorEl(null)}>
                                            <Link to={{ pathname: '/mentorAnIdea' }}>
                                                <Button variant="outlined" >
                                                    <People />
                                                    Mentor an Idea
                                                </Button>
                                            </Link>
                                        </div>
                                    )
                                }
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                {(appInitData.sponsorsList || []).filter(r => r.sponsor.userId === userId).length === 0 ? (
                                    <div className="operations__monetizationIcon" onClick={() => { setSponsorRegistrationDialogOpen(true); setPopoverAnchorEl(null) }}>
                                        <Button variant="outlined">
                                            <MonetizationOnSharp />
                                            Register as Sponsor
                                        </Button>
                                    </div>) :
                                    (
                                        <div className="operations__monetizationIcon" onClick={() => setPopoverAnchorEl(null)}>
                                            <Link to={{ pathname: '/sponsorAnIdea' }} style={{ textDecorationLine: "none" }}>
                                                <Button variant="outlined">
                                                    <MonetizationOnSharp />
                                                    Sponsor an Idea
                                                </Button>
                                            </Link>
                                        </div>
                                    )}
                            </Col>
                        </Feature>
                    </Row>
                </div>
            </Popover>
            <MentorRegistration userId={userId} open={mentorRegistrationDialogOpen} handleClose={() => setMentorRegistrationDialogOpen(false)} />
            <SponsorRegistration userId={userId} open={sponsorRegistrationDialogOpen} handleClose={() => setSponsorRegistrationDialogOpen(false)} />
        </div>
    )
}

export default Operations;
