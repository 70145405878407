import React, { useEffect } from 'react'; import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import moment from 'moment';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Button, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip, Card } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DialogTitle from '@material-ui/core/DialogTitle';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    background: '#00d4ff',
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
  },
}));

export default function ViewStage(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editorStateGoal, setEditorStateGoal] = React.useState(() => EditorState.createEmpty())

  const handleOpen = () => {

    setOpen(true);
  }

  

  const handleClose = () => {

    setOpen(false);

  };
  const handleGoalDescriptionText = (editorStateGoal) => {
    setEditorStateGoal(editorStateGoal)
    const contentState = editorStateGoal.getCurrentContent();
    const val = JSON.stringify(convertToRaw(contentState));
    props.setGoalData({ ...props.goalData, ['description']: val });
  }

  return (
    <div>
      <Tooltip title="View goal"><IconButton style={{ padding: 8 }}><OpenInNewIcon onClick={handleOpen} style={{ color: 'rebeccapurple' }} className={classes.actions} /></IconButton></Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        style={{
          position: 'absolute',
          top: '8%',
          left: '19%',
          right: '19%',
          height: '100%',
          display: 'block'
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid item className={classes.title}>
              <DialogTitle id="form-dialog-title"><span style={{ fontSize: '35px' }}>View stage </span></DialogTitle>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent style={{ paddingTop: '5px', paddingBottom: '2px' }}>
                  <Grid container md={12}>
                    <Grid xs={12} md={6} style={{ padding: '0.3%' }}>
                       <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Current Stage"
                        multiline="false"
                        value={props.stage.currentStage}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={props.handleCurrentStage}
                      />
                    </Grid>
                    <Grid xs={12} md={6} style={{ padding: '0.3%' }}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Milestone"
                        multiline="false"
                        value={props.stage.stageMilestone}
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        onChange={props.handleStageMilestone}
                      />
                    </Grid>
                    <Grid xs={12} md={12} style={{ padding: '0.3%' }}>
                      <Editor
                        name="Criteria of the Stage"
                        toolbar={{
                          options: []
                        }}
                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(props.stage.stageCriteria)))}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        error={props.mssg1 && (props.stages.stageCriteria.length === 0)}
                        onEditorStateChange={props.handleStageCriteria}
                        placeholder="Enter a brief criteria about this stage"
                      />
                    </Grid>
                    <Grid xs={12} md={12} style={{ padding: '0.3%' }}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Enter your stage summary"
                        multiline="false"
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        value={props.stage.stageSummary}
                        onChange={props.handleStageSummary}
                      />
                    </Grid>
                    <Grid item xs={5} md={6}  >
                      <Card>
                        <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                          <text><strong>Stage Start Date*: </strong>
                            <div style={{ marginTop: "-1px", display: "inline-grid" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: "-7px" }}>
                                <KeyboardDatePicker
                                  margin="normal"
                                  id="date-picker-dialog"
                                  format="MM/dd/yyyy"
                                  error={props.mssg1 && (props.stages.stageStart.length === 0)}
                                  defalutValue={props.stageStartDate}
                                  onChange={props.handleDateChange('stageStart')}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider></div>
                          </text>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={5} md={6}  >
                      <Card>
                        <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                          <text><strong>Stage End Date*: </strong>
                            <div style={{ marginTop: "-1px", display: "inline-grid" }}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                  margin="normal"
                                  id="date-picker-dialog"
                                  format="MM/dd/yyyy"
                                  error={props.mssg1 && (props.stages.stageEnd.length === 0)}
                                  defaultValue={props.stageEndDate}
                                  onChange={props.handleDateChange('stageEnd')}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </text>
                        </CardContent>
                      </Card>

                    </Grid>

                  </Grid>
                  {/* <Button style={{ marginTop: "10px", marginLeft: "300px", marginBottom: "10px" }} variant="contained" onClick={props.handleAddStage('steges')} >Add </Button> */}
                  <Button style={{ marginTop: "10px", marginLeft: "345px", marginBottom: "10px" }} variant="contained" onClick={handleClose} >Close </Button>
                </CardContent>
              </Card>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}