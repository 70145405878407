import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Links from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import * as actions from '../../store/actions/index'
import reducer from '../../store/reducers/reducer'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert';
import AuthContext from '../../store/auth-context';
import Feature from '../Feature';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © Designed & Developed by Publicis Sapient '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles(theme => ({

  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));



export default function Signin(props) {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const userDispatch = useDispatch();
  const { user } = props;
  const classes = useStyles();
  // const { userId } = useParams();
  const [state, setState] = useState({
    userId: "",
    password: ""
  });

  const [mssg, setMssg] = useState(false)



  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
    setMssg(false)
  };
  const setNewUser=()=>{
    userDispatch(actions.setUser(sessionStorage.getItem('id')));
    history.push("/");
  }
  const setData = () => {
    var axios = require('axios');

    var config = {
      method: 'get',
      url: '/userGateway/user/' + sessionStorage.getItem('token'),
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };

    axios(config)
      .then(res => {
        console.log("userID", res)
        sessionStorage.setItem('id', res.data)
        // history.push("/");
        setNewUser()
      })
      .catch(error => {
        console.log("error:", error)
      })
  }

  const authentication = () => {
    var axios = require('axios');
    var data = JSON.stringify({
      "userId": state.userId,
      "password": state.password
    });

    var config = {
      method: 'post',
      url: '/userGateway/authenticate',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        sessionStorage.setItem('token', response.data);
        authCtx.login(response.data);
        setData();
      })


      .catch(function (error) {
        console.log(error);
        setMssg(true)
      });



  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        {mssg == true &&
          <Grid xs={12} className={clsx(!mssg && classes.display)}>
            <text style={{ color: 'red' }}><strong>Please enter valid Username and Password</strong></text>
          </Grid>}
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={mssg}
            id="userId"
            label="User ID"
            name="uerid"
            autoComplete="userid"
            autoFocus
            onChange={handleChange('userId')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            error={mssg}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange('password')}
          />
          <Feature name='show-feature'>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
          </Feature>
          <Link style={{ textDecorationLine: "none", color: '#373737' }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={authentication}
              disabled={!((state.password != '') && (state.emailAddress != ''))}
            >
              Sign In
            </Button></Link>

          <Grid container>
            <Grid item xs>
              <Link variant="body2" to="/reset">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signUp">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}