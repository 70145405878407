import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from '../../../commons/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Button, Avatar as BadgeAvatar, IconButton } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Tooltip from '@material-ui/core/Tooltip';
import { Col, Row, Container } from 'react-bootstrap';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import FileUpload from '../../../commons/FileUpload'
import axios from 'axios';
import './AccountMenu.scss';
import Feature from '../../../Feature';
import profilelogo from '../../../../pictures/profilelogo.png'

const menuId = "account-menu";
const styles = theme => ({
    Button: {
        backgroundColor: theme.palette.primary.main
    }
});






function AccountMenu({ appInitData, classes }) {
    let userId = sessionStorage.getItem('id');
    // useSelector(state => {
    //     userId = (state.appReducer.user);
    // }
    // )
    const [userName, setUserName] = React.useState("")
    const [userEmail, setUserEmail] = React.useState("")


    React.useEffect(() => {

        var axios = require('axios');

        var config = {
            method: 'get',
            url: '/userGateway/get/user/' + userId,
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };

        axios(config)


            .then(response => {

                setUserName(response.data.username)
                console.log("apiResponse:", response.data.username)
                setUserEmail(response.data.emailId)

            })
            .catch(function (error) {
                console.log("error:", error);
            });

    }, []);


    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);
    return (
        <div className="account">
            <Tooltip title="Account">
                <div onClick={(event) => setAccountMenuAnchorEl(event.currentTarget)} style={{ cursor: 'pointer' }}>
                    <Avatar />
                </div>
            </Tooltip>
            <Popover
                id={'account-menu-popover'}
                open={Boolean(accountMenuAnchorEl)}
                anchorEl={accountMenuAnchorEl}
                onClose={() => setAccountMenuAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="account-menu__overlay">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col xs={12}>
                                <div className="user user-avatar">
                                    <BadgeAvatar
                                        alt="Travis Howard"
                                        src={profilelogo}
                                    >
                                        <Badge color="secondary">
                                            <FileUpload />
                                        </Badge>
                                    </BadgeAvatar>
                                </div>
                                <div className="user user-name">
                                    {userName}
                                </div>
                                <div className="user user-email">
                                    {userEmail}
                                </div>
                                <Feature name='show-feature'>
                                    <div className="user user-account-link">
                                        <Link to="/myAccount">
                                            <Button variant="outlined" className={classes.Button}>
                                                Manage your Account
                                            </Button>
                                        </Link>
                                    </div>
                                </Feature>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="separator">
                        <Row className="justify-content-md-center">
                            <Col>
                                <div className="user user-signout">
                                    <Link to="/signout">
                                        <Button variant="outlined" className={classes.Button}>
                                            Signout
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Popover>
        </div>
    )
}

export default withStyles(styles)(AccountMenu);
