import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Collaborator from './Collaborator';
import axios from 'axios';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

export default function CollaboratorLayout() {

    const [initial, setInitial] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    let userId='';
    useSelector(state => {userId=state.appReducer.user;
    })

    const filterList = (event) => {
        let items = initial;
        let searchText = event.target.value;

        items = items.filter(collaborator => {
            const Tags = collaborator.tags;
            return (collaborator.userName.toLowerCase().search(searchText.toLowerCase()) !== -1 || Tags.filter(tag => {return tag.toLowerCase().match(searchText.toLowerCase())}).length > 0);
        });
        setFiltered(items);
    }

    React.useEffect(() => {
        
    axios.get('/collaboratorGateway/get/Collaborators/CollaboratorLayout/'+userId)
      .then(response => {
        setInitial(response.data)
        setFiltered(response.data)
      });
  }, []);

        return (
            <div style={{ padding: '10px' }}>
                <Grid container justify="center">
                    <Grid item xs={12} md={4} style={{ margin: 10, width: '100%' }}>
                    <TextField InputProps={{style:{height:40}}} style={{width:'100%'}} onChange={filterList} variant="outlined" placeholder="Search using Collaborator Name or Area of Expertise" />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    {filtered.filter(idea => { return (idea.collaborator.userId === userId  )}).map(collaborator => (
                        <Collaborator key={collaborator.collaborator.userId} data={collaborator} />
                    ))}
                </Grid>
            </div>
        );
}
