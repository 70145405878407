import React,{useEffect} from 'react';import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Button, TextField, withStyles, Table, TableHead, TableRow, TableCell, LinearProgress, IconStyledButton, TableBody, Tooltip } from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg';
import  {EditorState, convertFromRaw, convertToRaw}  from 'draft-js';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popbutton:{
    fontSize: '20px',
    color:'#7e18d8',
    marginLeft:'10px',
    marginRight:'10px',
    backgroundColor: 'white'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3),
  },
}));

export default function GoalTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    console.log(props.goals)
  }, [])
  

  
  return (
    <div>
      <Button variant="contained" onClick={handleOpen} class={classes.popbutton} >Goals of Campaign</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        style={{position:'absolute',
        top:'10%',
        left:'10%',
        overflow:'scroll',
        height:'100%',
        display:'block'}}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <div className={classes.paper}>
          <Grid container spacing={1} style={{ padding: '10px' }}>
                    <Grid item xs={12} justify="center" style={{overflowX: 'auto'}}>
                        <Table >
                            <TableHead>
                                <TableRow style={{ fontSize: '150%' }}>
                                    <TableCell>Industry</TableCell>
                                    <TableCell>Title of Goal</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                                {props.goals.map((goal,index) => (
                                <TableRow key={index}>
                                    <TableCell>{goal.industry}</TableCell>
                                    <TableCell>{goal.title}</TableCell>
                                    <TableCell><Editor
                                                name="Goal Description"
                                                toolbar={{
                                                  options: []
                                                }}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                readOnly={true}
                                                editorState={goal.description.length > 0 ? EditorState.createWithContent(convertFromRaw(JSON.parse(goal.description))) : EditorState.createEmpty() }
                                                placeholder="Decribe Goal"
                                              /> </TableCell>
                                </TableRow>
                                ))}
                                </TableHead>
                                </Table>
                                </Grid>
                                </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}