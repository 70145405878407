import React from 'react';
import { Table, Grid, TableHead, TableRow, withStyles, TableCell, TableBody} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IdeaTableRow from './IdeaTableRow';
import Feature from '../../components/Feature';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#13b8a2',
        color: theme.palette.common.white,
        fontSize: '19px',
        textAlign: 'left'
    },
    body: {
        maxWidth: '250px',
        fontSize: 15,
        textAlign: 'left',
        padding: 5,
        borderWidth: 2,
    },
    root:{
        padding:'10px'
    }
}))(TableCell);

const IdeaTableStyle = makeStyles(theme => {
    return ({
        table: {
            minWidth: '50%',
            maxWidth: '100%',
            borderStyle: 'block',
            borderWidth: '1px'
        },
        actions: {
            height: '18px',
            width: '18px',
        },
        info: {
            padding: '10px',
        }
    });
})

export default function IdeaTable(props) {
    const classes = IdeaTableStyle();

    return (
        <Grid item xs={12}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Author</StyledTableCell>
                        <StyledTableCell>Info</StyledTableCell>
                        <Feature name='show-feature'>
                        <StyledTableCell>Tags</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                        </Feature>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Features</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.filter(idea => { return (idea.status !== "Draft" && idea.deleted !== true )}).map(idea => (
                        <IdeaTableRow idea={idea} />
                    ))}
                </TableBody>
            </Table>
        </Grid>
    )
}