import React , { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Line } from 'react-chartjs-2'
import { Grid,  makeStyles, Button, withStyles } from '@material-ui/core'
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});



const options = 
    {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: 'Ideas',
            fontSize: 24
        },
        layout: {
            padding: 20,
        },
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 20,
                fontSize: 16,
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false,
                    drawBorder: true
                }
            }],
            yAxes: [{
                gridLines: {
                    display: true,
                    drawBorder: true,
                },
                ticks : {
                    min : 0,
                    max : 10,
                    stepSize: 1,
                }

            }]
        }
    }

const StyledButton = withStyles(theme => ({
    root: {
        margin:'3px'
    }
}))(Button);


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    AppBar:{
        width:'110%',
        height:'40px'
    }
}));



export default function LineChart() {
    const classes = useStyles();
    const [stats, setStats] = useState({});
  useEffect(() => {

    axios.get("/ideaGateway/get/graphData").then
        (response => {  
            setStats(response.data);
        })      
        
}, []);
const obj= Object.entries(stats).map(([key, value]) => ({key, value}))
   
const data = [
    {
        labels: obj.map(x=>x.key),
        datasets: [
            {
                label: "Total Ideas",
                fill: false,
                borderColor: '#13b8a2',
                data: obj.map(x=>x.value),
                lineTension: 0
            },
            // {
            //     label: "Campaigns",
            //     fill: false,
            //     borderColor: '#ff334c',
            //     data: [0, 0, 0, 0, 0, 0],
            //     lineTension: 0
            // }
        ]
    },
    // {
    //     labels: ["July", "August", "September", "October", "November", "December"],
    //     datasets: [
    //         {
    //             label: "Ideas",
    //             fill: false,
    //             borderColor: '#13b8a2',
    //             data: [236, 354, 257, 240, 158, 278],
    //             lineTension: 0
    //         },
    //         {
    //             label: "Campaigns",
    //             fill: false,
    //             borderColor: '#ff334c',
    //             data: [0, 0, 0, 0, 0, 0],
    //             lineTension: 0
    //         }
    //     ]
    // },
    // {
    //     labels: ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"],
    //     datasets: [
    //         {
    //             label: "Ideas",
    //             fill: false,
    //             borderColor: '#13b8a2',
    //             data: [675, 785, 654, 826],
    //             lineTension: 0
    //         },
    //         {
    //             label: "Campaigns",
    //             fill: false,
    //             borderColor: '#ff334c',
    //             data: [0, 0, 0, 0],
    //             lineTension: 0
    //         }
    //     ]
    // }
]

    const customLine=(event) => {
        return(
            ReactDOM.render(<Line redraw={true} data={data[event.currentTarget.value]} options={options} height={370}/>, document.getElementById("chart"))
        )
    }

    return (
        <div className={classes.root}>
            <div id="chart">
                <Line data={data[0]} options={options} height={370}/>
            </div>
            {/* <Grid container justify="center" spacing={1} style={{padding:'5px'}}>
                <Tooltip title="1st Half Year"><StyledButton variant='outlined' onClick={customLine} value={0}>H1</StyledButton></Tooltip>
                <Tooltip title="2nd Half Year"><StyledButton variant='outlined' onClick={customLine} value={1}>H2</StyledButton></Tooltip>
                <Tooltip title="Annual"><StyledButton variant='outlined' onClick={customLine} value={2}>Y1</StyledButton></Tooltip>
            </Grid> */}
        </div>
    );
}
