import React from 'react';
import { Grid, Card, CardContent, Typography,IconButton, CardActions, Avatar, makeStyles } from '@material-ui/core'
import picture from '../../pictures/profileIcon.jpg'
import pictureAlt from '../../pictures/profilePicAlt.png'
import EmailIcon from '@material-ui/icons/Email';
import TodayIcon from '@material-ui/icons/Today';
import skype from '../../pictures/skype.png'
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const useStyle=makeStyles(theme => ({
    avatar:{
        margin: '10px', height: '100px', width: '100px'
    },
    typography:{
        color: '#7e18d8'
    },
    button:{
        color: '#7e18d8'
    },
    skype:{
        height:'25px',width:'25px'
    }
}))

export default function Collaborator(props) {

    const classes=useStyle();
    const collaborator=props.data;

    return (
        <Grid item xs={6} md={3}>
            <Card style={{height:'100%'}}>
                    <Grid container justify="center" alignItems="center">
                        <Avatar className={classes.avatar} alt={pictureAlt} src={picture} />
                    </Grid>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.typography}>
                            {collaborator.userName}
                        </Typography>
                        <Typography variant="body2" >
                            Ideas Collaboratored: {collaborator.collaborator.ideas.length}<br />
                            Area of Expertise: {collaborator.tags.toString()}
                        </Typography>
                    </CardContent>
                <CardActions>
                    <Tooltip title="Send Email"><IconButton style={{ color: '#1E9FC2' }} ><EmailIcon /></IconButton></Tooltip>
                    <Tooltip title="Schedule a meet"><IconButton style={{ color: '#27B288' }}> <TodayIcon /></IconButton></Tooltip>
                    <Tooltip title="Contact on Skype"><IconButton><Avatar className={classes.skype} alt={pictureAlt} src={skype} /></IconButton></Tooltip>
                </CardActions>
            </Card>
        </Grid>
    )
}