import { SET_THEME } from './constants'; 
import themes from '../../../themes/themes';

const initialState = {
  theme: themes[0].theme
}

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME: 
      return Object.assign({},state, {
        theme: themes.filter((th) => th.id === action.theme)[0].theme 
      });
     
    default: return state;
  }
};

export default themeReducer;