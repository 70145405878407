import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import swal from 'sweetalert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import Feature from '../Feature';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © Designed & Developed by Publicis Sapient '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    mobileNumber: '',
    userId: '',
  });
  const [mssg, setMssg] = useState(false)
  const [messageData,setMessageData] = useState("Please fill out the fields with * mark")


  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
    if (mssg === true) {
      if (state.firstName.length !== 0) {
        setMssg(false);
      }
    }
  };

  const register = () => {
    if (state.firstName.length !== 0 && state.emailAddress.length !== 0 && state.password.length !== 0 && state.userId.length !== 0) {
      var axios = require('axios');
      var data = JSON.stringify({
        "userId": state.userId,
        "username": state.firstName + " " + state.lastName,
        "group": "",
        "password": state.password,
        "pictureUrl": "",
        "role": [

        ],
        "proficiency": [

        ],
        "areaOfInterest": [

        ],
        "emailId": state.emailAddress,
        "mobileNumber": state.mobileNumber,
        "createdBy": "",
        "timeCreated": "",
        "timeEdited": "",
        "lastActive": "",
        "isActive": "true"
      });

      var config = {
        method: 'post',
        url: '/userGateway/create/newUser',
        headers: { 'Content-Type': 'application/json' },
        data: data,

      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          switch (response.data) {
            case "Duplicate Record":
              setMessageData("User Already Exists");
              setMssg(true);
              break;
            case "Data Successfully Inserted ":
              history.push("/signIn")
              break;
            default:
              console.log(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      setMessageData("Please fill out the fields with * mark");
      setMssg(true);
    }
  }



  return (
    <Container component="main" style={{ width: "100vw" }} maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {mssg == true &&
          <Grid xs={12}>
            <text style={{ color: 'red' }}><strong>{messageData}</strong></text>
          </Grid>}
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                error={mssg && (state.firstName.length === 0)}
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                onChange={handleChange('firstName')}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={handleChange('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={mssg && (state.userId.length === 0)}
                required
                fullWidth
                id="userid"
                label="User ID"
                name="email"
                autoComplete="userid"
                onChange={handleChange('userId')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                error={mssg && (state.emailAddress.length === 0)}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleChange('emailAddress')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={mssg && (state.password.length === 0)}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange('password')}
              />
            </Grid>


            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={mssg && (state.password.length === 0)}
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange('confirmPassword')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="Mobile Number"
                label="Mobile Number"
                type="Mobile Number"
                id="Mobile Number"
                autoComplete="Mobile Number"
                onChange={handleChange('mobileNumber')}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="Group"
                label="Group"
                type="Group"
                id="Group"
                autoComplete="Group"
                onChange={handleChange('group')}
              />
            </Grid> */}
            <Feature name='show-feature'>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive updates via email."
                />
              </Grid>
            </Feature>
          </Grid>
          <Link style={{ textDecorationLine: "none", color: '#373737' }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={(!(state.password == state.confirmPassword))}
              onClick={register}
            >
              Sign Up
            </Button></Link>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/signIn">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}