import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ThumbUp from '@material-ui/icons/ThumbUpRounded';
import IdeaTable from '../../../containers/Idea/IdeaTable';
import { BrowserRouter as Route, Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import CardHeader from '@material-ui/core/CardHeader';
import { Avatar, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, MuiThemeProvider, Tooltip, createMuiTheme, Icon, Button } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import GoalTable from './GoalTable';
import StageTable from './StageTable';
import AwardTable from './AwardTable';
import RuleTable from './RulesTable';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardContent from '@material-ui/core/CardContent';
import { CampaignStatus } from '../../Dropdowns/Status';
import Radio from '@material-ui/core/Radio';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioGroup from '@material-ui/core/RadioGroup';
import PeopleIcon from '@material-ui/icons/People';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { JustUserDropdown } from '../../Dropdowns/Dropdowns';

const campaignType = {
  "campaignId": "",
  "awards": [
    {
      "title": "",
      "value": ""
    }
  ],
  "campaignCoreTeamMembers": [],
  "comments": [
    {
      "user": "",
      "comments": ""
    }
  ],
  "deadline": "2019-12-10T08:46:33.638Z",
  "deleted": false,
  "description": "",
  "goals": [
    {
      "deleted": false,
      "description": "",
      "industry": "",
      "goalId": "",
      "ideas": [
        {
          "collaboratorOpen": true,
          "deadline": "2019-12-10T08:46:33.638Z",
          "deleted": false,
          "description": "",
          "ideaId": "",
          "likes": [],
          "mentorOpen": true,
          "sponsorOpen": true,
          "status": "",
          "tags": [],
          "timeCreated": "2019-12-10T08:46:33.638Z",
          "title": "",
          "userId": "",
          "username": "",
          "views": []
        }
      ],
      "timeCreated": "2019-12-10T08:46:33.638Z",
      "timeEdited": "2019-12-10T08:46:33.638Z",
      "title": ""
    }
  ],
  "stages": [
    {
      "currentStage": "",
      "stageMilestone": "",
      "stageCriteria": "",
      "stageSummary": "",
      "stageStart": "",
      "stageEnd": ""
    }
  ],
  "likes": [],
  "progress": 0,
  "rewards": [],
  "rules": [],
  "status": "",
  "timeCreated": "2019-12-10T08:46:33.638Z",
  "timeEdited": "2019-12-10T08:46:33.638Z",
  "startCampaignDate": "",
  "endCampaignDate": "",
  "title": "",
  "userId": "",
  "userName": "",
  "visibility": []
}

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 70,
    width: 70,
    padding: 0
  },
  campaignIcon: {
    height: '100%',
    width: '100%',
    padding: 0,
    color: 'grey',
    backgroundColor: 'white'
  },
  cardHeader: {
    padding: 5
  },
  text: {
    padding: 10
  },
  button: {
    display: 'flex',
    flexDirection: 'row'
  },
  goalHeading: {
    flexBasis: '55%',
    flexShrink: 0
  },
  goalSubHeading: {
    flexBasis: '32%',
    flexShrink: 0,
    color: 'grey'
  },
  likecolor: {
    color: '#1b6deb',
  },
  rounded: {
    borderRadius: 0
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '50%',
  },
  popbutton: {
    color: 'white',
    marginTop: '8px',
    marginRight: '20px',
    borderColor: 'white'

  },
  button: {
    textAlign: 'right',
  },
  title: {
    background: '#00d4ff',
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
  },
  content: {
    width: '85%',
    left: '10%',
  },
  textfield: {
    width: '100%',
  },
  hei: {
    marginTop: '5%',
    top: '5%',
    marginLeft: '10%',
    maxHeight: '87%',
    maxWidth: '80%',
  },
  display: {
    display: 'none',
  },
  button2: {
    textAlign: 'center',
  },
  cardHead: {
    padding: '0',
    margin: '0',
    marginTop: '2px',
  },
  people: {
    color: 'gold',
    fontSize: '50px'
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 15,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

export default function ViewCampaign({ match }) {
  const classes = useStyles();
  let startDate = '2019-12-10';
  let userId = '';
  useSelector(state => {
    userId = state.appReducer.user;
  })
  const [campaign, setCampaign] = useState(campaignType);
  const [like, setLike] = useState([]);
  const [flag, setFlag] = useState(true);
  const [editorState, setEditorState] = React.useState(campaign.description > 0 ? EditorState.createWithContent(convertFromRaw(JSON.parse(campaign.description))) : () => EditorState.createEmpty());
  const [editorStateGoal, setEditorStateGoal] = React.useState(() => EditorState.createEmpty());




  useEffect(() => {

    axios.get('/campaignGateway/viewCampaign/' + match.params.campaignId)
      .then(response => {
        setCampaign(response.data)
        setLike(response.data.likes)
      });
  }, []);

  const handleLike = () => {
    let axiosConfig = {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    if (!like.includes(userId)) {
      let temp = like.concat(userId);
      setLike(temp);
      axios.put('/campaignGateway/addLike/' + campaign.campaignId + ',' + userId, axiosConfig);
    }
    else {
      let temp = like.filter(user => user !== userId);
      setLike(temp);
      axios.put('/campaignGateway/deleteLike/' + campaign.campaignId + ',' + userId, axiosConfig);
    }
  }
  const onfocusfn = () => {
    setFlag(false)
  }
  const onFocusOutfn = () => {
    setFlag(true)
  }

  return (
    <div>
      <Grid item className={classes.title}>
        <DialogTitle id="form-dialog-title"><span style={{ fontSize: '50px' }}>{campaign.title}<PeopleIcon className={classes.people} /></span></DialogTitle>
      </Grid>
      <Grid container className={classes.container} xs={12} md={11} style={{ marginLeft: 10, paddingBottom: 30 }}>


        <Grid item xs={12} md={12}  >
          <Card style={{ marginTop: '1rem' }}>
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
              {flag == true &&

                <div style={{ marginTop: "-90px" }} onFocus={onfocusfn}>
                  <Editor
                    name="description"
                    toolbarOnFocus
                    readOnly={true}
                    editorState={campaign.description.length > 0 ? EditorState.createWithContent(convertFromRaw(JSON.parse(campaign.description))) : EditorState.createEmpty()}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    placeholder=" Enter a brief description about your Campaign*"
                  /> </div>}
              {flag == false &&

                <div style={{ marginTop: "-90px" }} onBlur={onFocusOutfn}>
                  <Editor
                    name="description"
                    toolbarOnFocus
                    readOnly={true}
                    editorState={campaign.description.length > 0 ? EditorState.createWithContent(convertFromRaw(JSON.parse(campaign.description))) : EditorState.createEmpty()}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    placeholder=" Enter a brief description about your Campaign*"
                  /> </div>}
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} md={12}  >
          <Card>
            <CardHeader className={classes.cardHead} title="Timelines and Status" style={{ margin: 20 }} />
            <CardContent style={{ paddingTop: '2px', paddingBottom: '2px' }}>
              <text>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div>
                      <text>
                        <p style={{ marginBottom: '2px' }}>
                          <strong>Campaign Start Date:</strong>
                        </p>
                      </text>
                      <TextField id="standard-basic" style={{ width: '70%' }} value={moment(campaign.startCampaignDate).utc().format('MM/DD/YYYY').toString()} inputProps={{ readOnly: true }} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <text>
                        <p style={{ marginBottom: '2px' }}>
                          <strong>Campaign End Date:</strong>
                        </p>
                      </text>
                      <TextField id="standard-basic" style={{ width: '70%' }} value={moment(campaign.endCampaignDate).utc().format('MM/DD/YYYY').toString()} inputProps={{ readOnly: true }} />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div>
                      <text>
                        <p style={{ marginBottom: '2px' }}>
                          <strong>Campaign Status:</strong>
                        </p>
                      </text>
                      <TextField id="standard-basic" style={{ width: '70%' }} value={campaign.status} inputProps={{ readOnly: true }} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <text>
                        <p style={{ marginBottom: '2px' }}>
                          <strong>PS coins:</strong>
                        </p>
                      </text>
                      <TextField id="standard-basic" style={{ width: '70%' }} defaultValue={0} inputProps={{ readOnly: true }} />
                    </div>
                  </Grid>
                </Grid>
              </text>
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12} md={12}>
          <Card className={classes.text}>
            <CardHeader
              title="Details"
              action={
                userId === campaign.owner ? <IconButton><Edit /><Link to={{ pathname: '/editCampaign/' + campaign.campaignId }} /></IconButton> : <span />
              }
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <Grid container justify={"center"}>
              <GoalTable goals={campaign.goals} />
              <StageTable stages={campaign.stages} />
              <AwardTable awards={campaign.awards} />
              <RuleTable rules={campaign.rules} />
            </Grid>
          </Card>
        </Grid>


        <Grid item xs={12} md={12}>
          <Card className={classes.text}>
            <CardHeader
              title="Team and Visibility"
              action={
                userId === campaign.owner ? <IconButton><Edit /><Link to={{ pathname: '/editCampaign/' + campaign.campaignId }} /></IconButton> : <span />
              }
              titleTypographyProps={{ variant: 'h5' }}
              className={classes.cardHeader}
            />
            <Grid container>
              <b style={{ margin: 5 }}>Owner of the campaign: </b><p style={{ margin: 5 }}>{campaign.userName}</p>
              {/* <b  style={{margin: 5}}>Visibility of Campaign: </b><p style={{margin: 5}}>{campaign.visibility.length===0?"Global":campaign.visibility.toString()}</p> */}
              {/* <b style={{margin: 5}}>Campaign Core Members: </b><p style={{margin: 5}}>{campaign.campaignCoreTeamMembers.toString()}</p> */}
            </Grid>
          </Card>
        </Grid>
        {campaign.status === "Launched" ?
          <Grid item xs={12}>
            <Card>
              <CardHeader className={classes.cardHeader} title="Goals and Ideas" style={{ margin: 10 }} />
              <Typography style={{ padding: 10, fontSize: '1.2em' }}><b>Ideas associated with goals:</b>
                <Tooltip title="You can see the list of ideas submitted for each goal and also contribute by adding more ideas using the link provided">
                  <InfoOutlinedIcon style={{ height: 15, width: 15 }} />
                </Tooltip>
              </Typography>
              {campaign.goals.map(goal => (
                <ExpansionPanel key={goal.goalId}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.goalHeading}>{goal.title}</Typography>
                    <Typography className={classes.goalSubHeading}>Ideas:{goal.ideas.length}, Completed: 0</Typography>
                    <Tooltip title="Add idea to this Goal">
                      <Link to={{ pathname: '/createIdea/' + goal.goalId }}>
                        <Button variant="outlined" style={{ margin: -10, marginLeft: 0, borderColor: '#4d4d4d' }}>
                          <EmojiObjectsSharp style={{ color: '#4d4d4d' }} />
                          <AddIcon style={{ color: '#4d4d4d' }} />
                        </Button>
                      </Link>
                    </Tooltip>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <IdeaTable data={goal.ideas} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </Card>
          </Grid>
          : <span />
        }
        <Grid item xs={12}>
          {campaign.userId === (userId) ? <Link to={{ pathname: '/editCampaign/' + campaign.campaignId }}><IconButton className={classes.editIcon}><Edit /></IconButton></Link> : <span />}
        </Grid>


      </Grid>
    </div>
  );
}

