import React from 'react';
import clsx from 'clsx';
import PropTypes from "prop-types";
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from "@material-ui/core/styles";
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';
import { AppBar, Tabs, Tab } from '@material-ui/core'
import MentorNotification from './MentorNotification';
import SponsorNotification from './SponsorNotification';
import CollaboratorNotification from './CollaboratorNotification';
import { createMuiTheme,MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        color: "black",
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  display:{
    display:'none',
  },
  mentorButton:{
    background:'#ff713b',
    fontSize: '80%',
    color:'#ffffff',
  },
  sponsorButton:{
    background:'#123456',
    fontSize: '80%',
    color:'#ffffff',
  },
  collaboratorButton:{
    background:'#7e18d8',
    fontSize: '80%',
    color:'#ffffff',
  },
  reject:{
    background:'red',
    fontSize: '80%',
    color:'#ffffff',
  },
}));

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

export default function Notifications(props){
    const classes=useStyles();
    let userId=sessionStorage.getItem('id');
    // useSelector(state => {userId=state.appReducer.user;
    // })
    let [anchorEl, setAnchorEl] = React.useState(null);
    let mentorDetails=props.mentorDetails;
    let sponsorDetails=props.sponsorDetails
    let collaboratorDetails=props.collaboratorDetails;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue(0);
  }


  const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  const open = Boolean(anchorEl);
    return( 
      <div style={{ display: 'flex' }}>
          <Tooltip title="Requests">
      <IconButton  color="inherit" onClick={handleClick}>
        <Badge  color="secondary" badgeContent={sponsorDetails.length+mentorDetails.length+collaboratorDetails.length}>
            <NotificationsIcon />
        </Badge>
      </IconButton></Tooltip>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{maxWidth:'50%'}}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div>
                  <AppBar position="static" style={{ backgroundColor: '#f0f0f0', color: 'black' }}>
                      <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="fullWidth"
                          aria-label="nav tabs"
                      >
                          <Tab style={{backgroundColor:'#123456',color:'white'}} label="Sponsor Requests" {...a11yProps(0)}></Tab>
                          <Tab style={{backgroundColor:'#ff713b',color:'white'}} label="Mentor Requests" {...a11yProps(1)}></Tab>
                          <Tab style={{backgroundColor:'#7e18d8',color:'white'}} label="Collaborator Requests" {...a11yProps(2)}></Tab>
                      </Tabs>
                  </AppBar>

                  <TabPanel value={value} index={0} style={{ paddingTop: '10px',paddingBottom:'10px' }}>
                      <text className={clsx(sponsorDetails.length && classes.display)}>No sponsor requests</text>
                      {sponsorDetails.map(idea=>
                        idea.sponsorDetails.map(sponsor=>
                           <SponsorNotification sponsor={sponsor} title={idea.title} ideaId={idea.ideaId} />
                        )
                        )
                      }
                  </TabPanel>

                  <TabPanel value={value} index={1} style={{ paddingTop: '10px',paddingBottom:'10px' }}>
                      <text className={clsx( mentorDetails.length && classes.display)}>No Mentor requests</text>
                      {mentorDetails.map(idea=>
                        idea.mentorDetails.map(mentor=>
                          <MentorNotification mentor={mentor} title={idea.title} ideaId={idea.ideaId} />
                        ))}
                  </TabPanel>


                  <TabPanel value={value} index={2} style={{ paddingTop: '10px',paddingBottom:'10px' }}>
                      <text className={clsx(collaboratorDetails.length && classes.display)}>No Collaborator requests</text>
                      {collaboratorDetails.map(idea=>
                        idea.collaboratorDetails.map(collaborator=>
                          <CollaboratorNotification collaborator={collaborator} title={idea.title} ideaId={idea.ideaId} />
                        )
                        )
                      }
                  </TabPanel>
              </div>
              </Popover>
      </div>
    )
}
