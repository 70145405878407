const campaigns = [
    {
        "_id": "7896",
        "title": "Cluster",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7898",
        "title": "AI tool community",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "19/08/2020"
            }
        },
        "status": "Launched",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7897",
        "title": "Kubernetes Engine",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics",
            "AI monitor"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"400",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "12/09/2021"
            }
        },
        "status": "Draft",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7894",
        "title": "Web Portal designers",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"10",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": '04/12/2022'
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7891",
        "title": "Hackathon",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7890",
        "title": "AI tool community",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "19/08/2020"
            }
        },
        "status": "Launched",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7871",
        "title": "Kubernetes Engine",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics",
            "AI monitor"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"400",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "12/09/2021"
            }
        },
        "status": "Draft",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7884",
        "title": "Web Portal designers",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"10",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": '04/12/2022'
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7446",
        "title": "Hackathon",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7698",
        "title": "AI tool community",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "19/08/2020"
            }
        },
        "status": "Launched",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7797",
        "title": "Kubernetes Engine",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics",
            "AI monitor"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"400",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "12/09/2021"
            }
        },
        "status": "Draft",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7112",
        "title": "Web Portal designers",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"10",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": '04/12/2022'
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7001",
        "title": "Hackathon",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7880",
        "title": "AI tool community",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "19/08/2020"
            }
        },
        "status": "Launched",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "789721",
        "title": "Kubernetes Engine",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics",
            "AI monitor"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"400",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "12/09/2021"
            }
        },
        "status": "Draft",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7812",
        "title": "Web Portal designers",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"10",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": '04/12/2022'
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "78002",
        "title": "Hackathon",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "9898",
        "title": "AI tool community",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "19/08/2020"
            }
        },
        "status": "Launched",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7127",
        "title": "Chrome extension",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics",
            "AI monitor"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"400",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "12/09/2021"
            }
        },
        "status": "Draft",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "7091",
        "title": "Web Portal designers",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"10",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": '04/12/2022'
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "78776",
        "title": "Hackathon",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "78918",
        "title": "AI tool community",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "19/08/2020"
            }
        },
        "status": "Launched",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "78972",
        "title": "Kubernetes Engine",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics",
            "AI monitor"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"400",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "12/09/2021"
            }
        },
        "status": "Draft",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "76894",
        "title": "Web Portal designers",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"10",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": '04/12/2022'
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    },
    {
        "_id": "78961",
        "title": "Hackathon",
        "owner": "Lakshay Joshi",
        "comments":[

        ],
        "description": "Our client has defined guidelines for teams to create and manage their own databases. If any application has to use database table owned by some other team, then it has to depend on calling its data service instead of directly going to the database.Therefore, teams are now creating data services or time series services to expose data for applications owned by other teams. Service response has to be in a standard IDS format.Now, this is good when applications have to interact with each other. But it becomes a challenge when analyst teams or business users have to use this data. Earlier, analyst teams used to directly access database tables for doing any analysis. Now, when services have been introduced, they have to rely on different service clients ? Postman or SoapUI, to call these services and get the data. Calling data services requires modifying cryptic JSON request objects and then copying service response into Excel so that they can easily understand it and do analysis around it. This does take quite a lot of time and inputs from the Dev team.Solution.We are proposing a solution to tackle this particular pain area.Idea is to create a Workbench that analysts or any user can use to configure data services.",
        "goals": [
            "The Kubernetes Cluster",
            "Hackathon on AI"
        ],
        "ideas":[
            "Technical Shaft",
            "Web based attendance",
            "Python Metrics"
        ],
        "collaborators":[
            "Puneet Mishra",
            "Prashant Poddar",
            "Sunil Dixit"
        ],
        "pscoins":"20",
        "rewards": [
            "string"
        ],
        "rules": [
            "Anyone in the organization can submit the ideas",
            "Every idea should have the spons and infrastructure available"
        ],
        "visibility": "wellington",
        "progress": {
            "$numberInt": "30"
        },
        "timeCreated": {
            "$date": {
                "$numberLong": "1570101344615"
            }
        },
        "timeEdited": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "startDate": {
            "$date": {
                "$numberLong": "1570081121570"
            }
        },
        "endDate": {
            "$date": {
                "$numberLong": "09/02/2020"
            }
        },
        "status": "Completed",
        "likes": [
            "Naveen ",
            "Sreekara Reddy"
        ],
        "isDeleted": false,
        "_class": "com.sapient.innovation_hub.model.entity.Campaign"
    }
]


export default campaigns;